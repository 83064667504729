import { Chip } from "@mui/material";
import React from "react";
import {
  StatusPageOptionsViewModel,
  TestViewModel,
} from "src/types/auto/types";

interface Props {
  test: TestViewModel;
  settings?: StatusPageOptionsViewModel;
}

const ImpactsChip = (props: Props): JSX.Element => {
  return (
    <Chip
      label={props.test.name}
      variant="outlined"
      size="small"
      style={{
        marginLeft: 10,
        color: props.settings?.siteChipFontColour,
        borderColor: props.settings?.siteChipBackgroundColour,
      }}
    />
  );
};

export default ImpactsChip;
