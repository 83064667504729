import { IsResultUp } from "../UptimeHelpers/IsUp";

const PingTestHelper = (
  url: string,
  regex: string | undefined,
  pong: (
    statusCode: number,
    statusText: string,
    regexPass: boolean,
    response: string
  ) => void
) => {
  const http = new XMLHttpRequest();

  http.open("GET", url, true);
  http.onerror = function () {
    pong(http.status || -1, "Invalid Domain Name", true, "");
    return;
  };
  http.onreadystatechange = function () {
    if (http.readyState == 4) {
      // Do the regex check if applicable
      if (IsResultUp(http.status) && regex) {
        try {
          const regexp = new RegExp(regex);
          if (regexp.test(http.responseText)) {
            pong(http.status, "Regex Test Pass", true, http.responseText);
            return;
          } else {
            pong(
              http.status,
              "Regex Test Did Not Match",
              false,
              http.responseText
            );
            return;
          }
        } catch {
          pong(
            http.status,
            "Invalid Regular Expression",
            false,
            http.responseText
          );
          return;
        }
      }

      if (pong != null) {
        pong(http.status, http.statusText, true, http.responseText);
        return;
      }
    }
  };
  try {
    http.send(null);
  } catch (exception) {
    console.log("EXCEPTION");
  }
};

export default PingTestHelper;
