import React from "react";
import { Helmet } from "react-helmet-async";
import { Configuration } from "src/Constants";
import GenericSettings from "src/components/OrganisationSettings/GenericSettings";

const OrganisationSettings = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>Organisation Settings | {Configuration.name}</title>
      </Helmet>
      <GenericSettings />
    </>
  );
};

export default OrganisationSettings;
