import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Card,
  CardHeader,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  StatusPageSiteClient,
  StatusPageSiteViewModel,
} from "src/types/auto/types";
import { IsEmailValid } from "src/view/login/SignUpHelper";

interface Props {
  site: StatusPageSiteViewModel;
  setSite: React.Dispatch<React.SetStateAction<StatusPageSiteViewModel>>;
}

const Notifications = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);
  const [, setRerender] = useState(false);
  const [areEmailsValid, setAreEmailsValid] = useState(true);

  return (
    <>
      <Card style={{ width: "100%", padding: "10px", marginTop: 20 }}>
        <CardHeader
          title={
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                marginBottom: 15,
                paddingBottom: 0,
              }}
              variant="h3"
            >
              Test Notification Settings
            </Typography>
          }
          subheader={
            <Typography style={{ marginLeft: 10 }}>
              Add the email addresses of the accounts to be notified when the
              state of one of the tests changes. (Press Enter Between Email
              Addresses)
            </Typography>
          }
        />
        <Container>
          <Autocomplete
            id="email-addresses"
            freeSolo
            multiple
            options={[]}
            value={props.site.notificationAddresses || []}
            onChange={(_, value) => {
              props.setSite((x) => {
                x.notificationAddresses = value as string[];
                setAreEmailsValid(
                  x.notificationAddresses.every((x) => IsEmailValid(x))
                );
                setRerender((x) => !x);
                return x;
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Notification Email Addresses"
                placeholder="Notification Addresses"
                error={!areEmailsValid}
                helperText={
                  !areEmailsValid &&
                  "Please ensure all email addresses are valid"
                }
              />
            )}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            onClick={() => {
              // Update the site on the backend
              new StatusPageSiteClient(Configuration.ServerRoot, FetchOverride)
                .updateSite(props.site)
                .then((x) => {
                  if (x) {
                    setSnackbar({
                      message:
                        "Email notification options updated successfully",
                      status: "success",
                    });
                  } else {
                    setSnackbar({
                      message:
                        "Email notifications could not be updated. Please try again or contact support",
                      status: "error",
                    });
                  }
                })
                .catch(() => {
                  setSnackbar({
                    message:
                      "Email notifications could not be updated. Please try again or contact support",
                    status: "error",
                  });
                });
            }}
          >
            Update Notification Emails
          </LoadingButton>
        </Container>
      </Card>
    </>
  );
};

export default Notifications;
