import React, { useEffect, useState } from "react";
import { Button, Card, Box, Typography, CardHeader } from "@mui/material";
import StatusIcon from "src/components/UptimeHelpers/StatusIcon";
import {
  StatusPageSiteViewModel,
  StatusPageClient,
} from "src/types/auto/types";
import { SiteDashboardTabs } from "./IndividualSiteDashboard";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import StatusPageCustomiseNavBar, {
  StatusPageNavOptions,
} from "./StatusPageCustomiseNavBar";

interface Props {
  site: StatusPageSiteViewModel;
  currentTab: SiteDashboardTabs;
  setCurrentTab: React.Dispatch<React.SetStateAction<SiteDashboardTabs>>;
  statusPageTab: StatusPageNavOptions;
  setStatusPageTab: React.Dispatch<React.SetStateAction<StatusPageNavOptions>>;
}

const SiteStatusHeader = (props: Props) => {
  const [statusPageUrl, setStatusPageUrl] = useState<string>();

  useEffect(() => {
    if (props.site?.id) {
      new StatusPageClient(Configuration.ServerRoot, FetchOverride)
        .getStatusPageForSiteId(props.site.id)
        .then((x) => {
          if (x) {
            setStatusPageUrl(x.url);
          }
        });
    }
  }, [props.site, setStatusPageUrl]);

  return (
    <>
      <Card style={{ width: "100%" }}>
        <CardHeader
          title={
            <Box p={2}>
              <Box style={{ display: "inline-block" }}>
                <StatusIcon status={props.site.siteStatus} isSiteStatus />
              </Box>
              <Typography
                sx={{
                  pb: 3,
                }}
                style={{
                  display: "inline-block",
                  marginLeft: 10,
                  paddingBottom: 0,
                }}
                variant="h2"
              >
                {props.site.name}
              </Typography>
            </Box>
          }
        />
        <Box
          mb={2}
          ml={4}
          mr={4}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
            justifyContent: "space-between",
            gap: {
              xs: 2,
              sm: 2,
              md: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
              gap: 2,
            }}
          >
            <Button
              onClick={() => props.setCurrentTab(SiteDashboardTabs.Events)}
              variant={
                props.currentTab === SiteDashboardTabs.Events
                  ? "contained"
                  : "text"
              }
              style={{
                color: props.currentTab === SiteDashboardTabs.Events && "white",
              }}
            >
              Events
            </Button>
            <Button
              onClick={() => props.setCurrentTab(SiteDashboardTabs.Tests)}
              variant={
                props.currentTab === SiteDashboardTabs.Tests
                  ? "contained"
                  : "text"
              }
              style={{
                color: props.currentTab === SiteDashboardTabs.Tests && "white",
              }}
            >
              Uptime Monitors
            </Button>
            <Button
              onClick={() =>
                props.setCurrentTab(SiteDashboardTabs.Notifications)
              }
              variant={
                props.currentTab === SiteDashboardTabs.Notifications
                  ? "contained"
                  : "text"
              }
              style={{
                color:
                  props.currentTab === SiteDashboardTabs.Notifications &&
                  "white",
              }}
            >
              Notifications
            </Button>
            <Button
              onClick={() => {
                props.setCurrentTab(SiteDashboardTabs.StatusPage);
                props.setStatusPageTab(StatusPageNavOptions.Design);
              }}
              variant={
                props.currentTab === SiteDashboardTabs.StatusPage
                  ? "contained"
                  : "text"
              }
              style={{
                color:
                  props.currentTab === SiteDashboardTabs.StatusPage && "white",
              }}
            >
              Status Page
            </Button>
            <Button
              onClick={() => props.setCurrentTab(SiteDashboardTabs.Settings)}
              variant={
                props.currentTab === SiteDashboardTabs.Settings
                  ? "contained"
                  : "text"
              }
              style={{
                color:
                  props.currentTab === SiteDashboardTabs.Settings && "white",
              }}
            >
              Settings
            </Button>
          </Box>
          <Button
            style={{ right: 0, color: "white" }}
            href={
              statusPageUrl?.includes(".")
                ? "https://" + statusPageUrl
                : "https://" + statusPageUrl + ".status.statushive.net"
            }
            target="_blank"
            variant="contained"
          >
            View Status Page
          </Button>
        </Box>
        <StatusPageCustomiseNavBar
          active={props.currentTab === SiteDashboardTabs.StatusPage}
          currentTab={props.statusPageTab}
          setCurrentTab={props.setStatusPageTab}
        />
      </Card>
    </>
  );
};

export default SiteStatusHeader;
