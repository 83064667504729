import React from "react";
import { Typography } from "@mui/material";
import moment from "moment";

interface Props {
  format: string;
}

export default function DateDisplaysAs(props: Props) {
  return (
    <Typography style={{ marginBottom: "20px", fontStyle: "italic" }}>
      Displays as: {moment(Date.now()).format(props.format)}
    </Typography>
  );
}
