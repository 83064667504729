import React from "react";
import {
  FormControl,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { IsEmailValid, IsOrgNameValid } from "src/view/login/SignUpHelper";
import { Role } from "src/types/auto/types";

interface Props {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  nameError: boolean;
  setNameError: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  emailError: boolean;
  setEmailError: React.Dispatch<React.SetStateAction<boolean>>;
  role: Role;
  setRole: React.Dispatch<React.SetStateAction<Role>>;
  disableRoleSelector: boolean;
}

const UserForm = (props: Props): JSX.Element => (
  <>
    <TextField
      autoFocus
      margin="normal"
      id="name"
      label="Name"
      fullWidth
      placeholder="Name"
      value={props.name}
      onChange={(e) => props.setName(e.target.value)}
      onBlur={() => props.setNameError(!IsOrgNameValid(props.name))}
      error={props.nameError}
      helperText={props.nameError && "Please enter a valid name"}
    />
    <TextField
      margin="normal"
      required
      fullWidth
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      value={props.email}
      onChange={(e) => props.setEmail(e.target.value)}
      error={props.emailError}
      helperText={props.emailError && "Please enter a valid email address"}
      onBlur={() => props.setEmailError(!IsEmailValid(props.email))}
    />
    <FormControl
      fullWidth
      style={{
        marginTop: 10,
        marginBottom: 10,
        display: props.disableRoleSelector ? "none" : undefined,
      }}
    >
      <Typography
        id="userRoleLabel"
        style={{ marginBottom: 3, textAlign: "left" }}
      >
        User Role
      </Typography>
      <ToggleButtonGroup
        value={props.role}
        exclusive
        onChange={(e, value) => props.setRole(value)}
        aria-label="userRole"
      >
        <ToggleButton value={Role.User}>User</ToggleButton>
        <ToggleButton value={Role.OrganisationAdmin}>
          Organisation Admin
        </ToggleButton>
      </ToggleButtonGroup>
    </FormControl>
  </>
);

export default UserForm;
