import { Helmet } from "react-helmet-async";
import { Container, Grid } from "@mui/material";
import { Configuration } from "src/Constants";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import PingTasks from "./PingTasks";
import {
  StatusPageSiteClient,
  StatusPageSiteViewModel,
} from "src/types/auto/types";
import { FetchOverride } from "src/contexts/Requests";
import LoadingSpinner from "src/components/LoadingSpinner/LoadingSpinner";
import { RefreshSitesContext } from "src/contexts/SitesContext";
import SiteStatusHeader from "./SiteStatusHeader";
import UpdateStatusPage from "./UpdateStatusPage";
import EditSite from "./EditSite";
import EventsManager from "./EventsManager";
import Notifications from "./Notifications";
import { StatusPageNavOptions } from "./StatusPageCustomiseNavBar";
import UpdateStatusPageHTML from "./UpdateStatusPageHTML";
import UpdateStatusPageAnalyticsSearch from "./UpdateStatusPageAnalyticsSearch";
import UpdateStatusPageLocalisation from "./UpdateStatusPageLocalisation";

export enum SiteDashboardTabs {
  Events,
  Tests,
  Notifications,
  StatusPage,
  Settings,
}

// I'll leave this here in case we ever want URLs to determine the tabs
interface Props {
  dashboardTab?: SiteDashboardTabs;
  statusPageTab?: StatusPageNavOptions;
}

function IndividualSiteDashboard(props: Props) {
  const windowLocation = useLocation();
  const [site, setSite] = useState<StatusPageSiteViewModel>();
  const [siteLoading, setSiteLoading] = useState(false);
  const [reloadSite, setReloadSite] = useState(false);
  const refreshSites = useContext(RefreshSitesContext);
  const [currentTab, setCurrentTab] = useState(
    props.dashboardTab === undefined
      ? SiteDashboardTabs.Events
      : props.dashboardTab
  );
  const [statusPageTab, setStatusPageTab] = useState(
    props.statusPageTab === undefined
      ? StatusPageNavOptions.Design
      : props.statusPageTab
  );

  useEffect(() => {
    const siteId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    setSiteLoading(true);
    // Refresh all sites whenever we refresh a single site
    refreshSites();
    new StatusPageSiteClient(Configuration.ServerRoot, FetchOverride)
      .getSiteById(siteId)
      .then((x) => {
        setSiteLoading(false);
        if (x) {
          setSite(x);
        } else {
          location.replace("/");
        }
      })
      .catch(() => {
        setSiteLoading(false);
        location.replace("/");
      });
  }, [windowLocation, reloadSite]);

  return (
    <>
      <Helmet>
        <title>
          {site?.name || ""} | {Configuration.name}
        </title>
      </Helmet>
      <Container
        maxWidth="lg"
        style={{ width: "90%", marginLeft: "5%", marginTop: 20 }}
      >
        <LoadingSpinner loading={siteLoading} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          mt="0px"
        >
          {site && (
            <SiteStatusHeader
              site={site}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              statusPageTab={statusPageTab}
              setStatusPageTab={setStatusPageTab}
            />
          )}
          {currentTab === SiteDashboardTabs.Events ? (
            <Grid item xs={12}>
              <EventsManager site={site} />
            </Grid>
          ) : currentTab === SiteDashboardTabs.Tests ? (
            <Grid item xs={12}>
              <PingTasks
                site={site}
                refreshSite={() => setReloadSite((x) => !x)}
              />
            </Grid>
          ) : currentTab === SiteDashboardTabs.Notifications ? (
            <Notifications site={site} setSite={setSite} />
          ) : currentTab === SiteDashboardTabs.StatusPage ? (
            <>
              {statusPageTab === StatusPageNavOptions.Design ? (
                <UpdateStatusPage site={site} />
              ) : statusPageTab === StatusPageNavOptions.HTML ? (
                <UpdateStatusPageHTML site={site} />
              ) : statusPageTab === StatusPageNavOptions.Localisation ? (
                <UpdateStatusPageLocalisation site={site} />
              ) : (
                <UpdateStatusPageAnalyticsSearch site={site} />
              )}
            </>
          ) : currentTab === SiteDashboardTabs.Settings ? (
            <EditSite
              site={site}
              setSite={setSite}
              onChange={() => {
                setTimeout(() => setReloadSite((x) => !x), 1000);
              }}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default IndividualSiteDashboard;
