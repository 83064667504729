import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const momentData = [
  {
    col1: "Second",
    col2: "s",
    col3: "0 1 ... 58 59",
    col1Span: 2,
  },
  {
    col2: "ss",
    col3: "00 01 ... 58 59",
  },
  {
    col1: "Minute",
    col2: "m",
    col3: "0 1 ... 58 59",
    col1Span: 2,
  },
  {
    col2: "mm",
    col3: "00 01 ... 58 59",
  },
  {
    col1: "Hour",
    col2: "H",
    col3: "0 1 ... 22 23",
    col1Span: 4,
  },
  {
    col2: "HH",
    col3: "00 01 ... 22 23",
  },
  {
    col2: "h",
    col3: "1 2 ... 11 12",
  },
  {
    col2: "hh",
    col3: "01 02 ... 11 12",
  },
  {
    col1: "AM/PM",
    col2: "A",
    col3: "AM PM",
    col1Span: 2,
  },
  {
    col2: "a",
    col3: "am pm",
  },
  {
    col1: "Timezone",
    col2: "z or zz",
    col3: "EST CST ... MST PST",
    col1Span: 3,
  },
  {
    col2: "Z",
    col3: "-07:00 -06:00 ... +06:00 +07:00",
  },
  {
    col2: "ZZ",
    col3: "-0700 -0600 ... +0600 +0700",
  },
  {
    col1: "Day Of Week",
    col2: "d",
    col3: "0 1 ... 5 6",
    col1Span: 5,
  },
  {
    col2: "do",
    col3: "0th 1st ... 5th 6th",
  },
  {
    col2: "dd",
    col3: "Su Mo ... Fr Sa",
  },
  {
    col2: "ddd",
    col3: "Sun Mon ... Fri Sat",
  },
  {
    col2: "dddd",
    col3: "Sunday Monday ... Friday Saturday",
  },
  {
    col1: "Day Of Month",
    col2: "D",
    col3: "1 2 ... 30 31",
    col1Span: 3,
  },
  {
    col2: "Do",
    col3: "1st 2nd ... 30th 31st",
  },
  {
    col2: "DD",
    col3: "01 02 ... 30 31",
  },
  {
    col1: "Month",
    col2: "M",
    col3: "1 2 ... 11 12",
    col1Span: 5,
  },
  {
    col2: "Mo",
    col3: "1st 2nd ... 11th 12th",
  },
  {
    col2: "MM",
    col3: "01 02 ... 11 12",
  },
  {
    col2: "MMM",
    col3: "Jan Feb ... Nov Dec",
  },
  {
    col2: "MMMM",
    col3: "January February ... November December",
  },
  {
    col1: "Year",
    col2: "YY",
    col3: "70 71 ... 29 30",
    col1Span: 4,
  },
  {
    col2: "YYYY",
    col3: "1970 1971 ... 2029 2030",
  },
  {
    col2: "YYYYYY",
    col3: "-001970 -001971 ... +001907 +001971",
  },
  {
    col2: "Y",
    col3: "1970 1971 ... 9999 +10000 +10001",
  },
  {
    col1: "Quater",
    col2: "Q",
    col3: "1 2 3 4",
    col1Span: 2,
  },
  {
    col2: "Qo",
    col3: "1st 2nd 3rd 4th",
  },

  {
    col1: "Day Of Year",
    col2: "DDDD",
    col3: "1 2 ... 364 365",
    col1Span: 3,
  },
  {
    col2: "DDDo",
    col3: "1st 2nd ... 364th 365th",
  },
  {
    col2: "DDDD",
    col3: "001 002 ... 364 365",
  },

  {
    col1: "Day Of Week (Locale)",
    col2: "e",
    col3: "0 1 ... 5 6",
  },
  {
    col1: "Day Of Week (ISO)",
    col2: "e",
    col3: "1 2 ... 6 7",
  },
  {
    col1: "Week of Year",
    col2: "w",
    col3: "1 2 ... 52 53",
    col1Span: 3,
  },
  {
    col2: "wo",
    col3: "1st 2nd ... 52nd 53rd",
  },
  {
    col2: "ww",
    col3: "01 02 ... 52 53",
  },
  {
    col1: "Week of Year (ISO)",
    col2: "W",
    col3: "1 2 ... 52 53",
    col1Span: 3,
  },
  {
    col2: "Wo",
    col3: "1st 2nd ... 52nd 53rd",
  },
  {
    col2: "WW",
    col3: "01 02 ... 52 53",
  },
];

const MomentLocalisationTable = (): JSX.Element => (
  <Accordion
    style={{ marginTop: "10px", backgroundColor: "#f0f0f0" }}
    disableGutters
  >
    <AccordionSummary expandIcon={<ExpandMore />} id="requestHeadersSummary">
      Localisation Help
    </AccordionSummary>
    <AccordionDetails style={{ paddingTop: 0 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Token</TableCell>
            <TableCell>Example</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {momentData.map((x) => (
            <TableRow
              key={x.col1 + x.col2 + x.col3}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {x.col1 !== undefined ? (
                <TableCell component="th" scope="row" rowSpan={x.col1Span}>
                  {x.col1}
                </TableCell>
              ) : (
                <></>
              )}
              <TableCell>{x.col2}</TableCell>
              <TableCell>{x.col3}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </AccordionDetails>
  </Accordion>
);

export default MomentLocalisationTable;
