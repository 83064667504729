import { LoadingButton } from "@mui/lab";
import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { PaymentClient } from "src/types/auto/types";
import { CheckCircleOutlineTwoTone } from "@mui/icons-material";

export interface SubscriptionOptions {
  name: string;
  monthId: string;
  monthPriceInPence: number;
  yearId: string;
  yearPriceInPence: number;
  yearSavingString: string;
  showMonth: boolean;
  features: string[];
}

function numberToPrice(value) {
  return (
    "£" +
    (Number(value / 100) || 0)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
}

const SubscriptionOption = (props: SubscriptionOptions): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const setSnackbar = useContext(SetSnackbar);

  return (
    <Card>
      <Typography
        component="h2"
        style={{ fontSize: 17, fontWeight: "bold", marginTop: 15 }}
        color="primary"
      >
        {props.name}
      </Typography>

      <Typography component="h2" style={{ fontSize: 20, marginTop: 15 }}>
        {props.showMonth
          ? numberToPrice(props.monthPriceInPence)
          : numberToPrice(props.yearPriceInPence)}
        <b>{props.showMonth ? " / month" : " / year"}</b>
      </Typography>
      <Typography
        component="h5"
        style={{ fontSize: 13, color: "grey", minHeight: 20 }}
      >
        {!props.showMonth && props.yearSavingString}
      </Typography>
      <LoadingButton
        loading={loading}
        variant="contained"
        style={{ marginTop: 20, width: "90%" }}
        onClick={() => {
          setLoading(true);

          const priceId = props.showMonth ? props.monthId : props.yearId;

          new PaymentClient(Configuration.ServerRoot, FetchOverride)
            .purchase(priceId)
            .then((x) => (location.href = x))
            .catch(() =>
              setSnackbar({
                message:
                  "Could not load price data. Please try again later or contact support",
                status: "error",
              })
            )
            .finally(() => setLoading(false));
        }}
      >
        Purchase
      </LoadingButton>

      <List>
        {props.features.map((x) => (
          <ListItem key={x}>
            <ListItemIcon style={{ minWidth: 40 }}>
              <CheckCircleOutlineTwoTone color="success" />
            </ListItemIcon>
            <ListItemText primary={x} />
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default SubscriptionOption;
