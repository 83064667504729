import React, { useContext } from "react";
import { UserClient, UserViewModel } from "src/types/auto/types";
import DeleteConfirmModal from "../ConfirmModal/ConfirmModal";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";
import { SetSnackbar } from "src/contexts/SnackbarContext";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  name: string;
  description: string;
  user: UserViewModel;
}

const DeleteUserModal = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);

  return (
    <DeleteConfirmModal
      open={props.open}
      onClose={async (cancelled: boolean): Promise<undefined> => {
        if (cancelled) {
          props.onClose(true);
          return;
        }
        props.onClose(false);
        return await new UserClient(Configuration.ServerRoot, FetchOverride)
          .deleteUser(props.user.id)
          .then(() => {
            // The user was deleted
            setSnackbar({
              message: "The user was deleted",
              status: "success",
            });

            return undefined;
          })
          .catch(() => {
            setSnackbar({
              message: "The user could not be deleted. Please try again",
              status: "error",
            });
          });
      }}
      actionName="Delete"
      name={props.name}
      description={props.description}
      buttonColor="error"
    />
  );
};

export default DeleteUserModal;
