import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Configuration } from "src/Constants";
import SiteStatusCard from "./SiteStatusCard";
import { Add } from "@mui/icons-material";
import SiteModal from "src/components/SiteModal/SiteModal";
import { RefreshSitesContext, SitesContext } from "src/contexts/SitesContext";

function SitesOverviewDashboard() {
  const theme = useTheme();
  const sites = useContext(SitesContext);
  const refreshSites = useContext(RefreshSitesContext);
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <>
      <Helmet>
        <title>Status Page Overview | {Configuration.name}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 35,
          }}
        >
          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              lineHeight: 0.3,
              marginLeft: 20,
            }}
            variant="h2"
          >
            Overview
          </Typography>
          <Tooltip
            title="Create New Status Page"
            arrow
            style={{ marginTop: -17, paddingTop: 0 }}
          >
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
              onClick={() => {
                location.href = "/createStatusPage";
              }}
            >
              <Add fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          mt="0px"
        >
          {sites.map((x) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={"global site overview for " + x.name}
            >
              <SiteStatusCard
                site={x}
                onChange={() => setTimeout(refreshSites, 1000)}
              />
            </Grid>
          ))}
          {(sites === undefined || sites.length === 0) && (
            <Grid item xs={12} key="no status pages">
              <Typography style={{ width: "100%", textAlign: "center" }}>
                No Status Pages. Click the plus button at the top right to
                create a page
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
      <SiteModal
        open={showCreateModal}
        onClose={(cancelled) => {
          setShowCreateModal(false);
          if (!cancelled) {
            setTimeout(refreshSites, 1000);
          }
        }}
      />
    </>
  );
}

export default SitesOverviewDashboard;
