import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import {
  StatusPageSiteViewModel,
  StatusPageClient,
  StatusPageDataViewModel,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { CircularProgress } from "@mui/material";
import { FetchOverride } from "src/contexts/Requests";
import StatusPageCreateUpdate from "src/components/StatusPageCreate/StatusPageCreateUpdate";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { DefaultStatusPage } from "src/view/login/signup/DefaultStatusPageData";

interface Props {
  site: StatusPageSiteViewModel;
}

export default function UpdateStatusPage(props: Props) {
  const setSnackbar = useContext(SetSnackbar);
  const [data, setData] = useState<StatusPageDataViewModel>();
  const [showCreateStatusPage, setShowCreateStatusPage] = useState(false);

  useEffect(() => {
    new StatusPageClient(Configuration.ServerRoot, FetchOverride)
      .getStatusPageForSiteId(props.site.id)
      .then((x) => {
        // Set mode to update if a status page exists
        if (x) {
          // Add the default data to the settings
          const defaults = DefaultStatusPage(props.site);
          defaults.settings = x;
          setData(defaults);
        } else {
          // Set mode to create if a status page does not exist
          setData(DefaultStatusPage(props.site));
          setShowCreateStatusPage(true);
        }
      })
      .catch(() => {
        setSnackbar({
          message:
            "Could not retrieve current status page. Please try again or contact support",
          status: "error",
        });
      });
  }, []);

  if (data === undefined) {
    return <CircularProgress />;
  }

  return (
    <Box style={{ width: "100%" }}>
      <StatusPageCreateUpdate
        data={data}
        setData={setData}
        update={!showCreateStatusPage}
        smallerView
      />
    </Box>
  );
}
