import { Add, Reorder } from "@mui/icons-material";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoadingSpinner from "src/components/LoadingSpinner/LoadingSpinner";
import DeletePingTestModal from "src/components/PingTestModal/DeletePingTestModal";
import PauseResumePingTestModal from "src/components/PingTestModal/PauseResumePingTestModal";
import PingTestModal from "src/components/PingTestModal/PingTestModal";
import ReOrderUptimeMonitorsModal from "src/components/PingTestModal/ReOrderUptimeMonitorsModal";
import TestOverrideModal from "src/components/PingTestModal/TestOverrideModal";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  StatusPageSiteViewModel,
  TestClient,
  TestViewModel,
} from "src/types/auto/types";
import TestStatusCard from "./TestStatusCard";

interface Props {
  site?: StatusPageSiteViewModel;
  refreshSite: () => void;
  hideHeader?: boolean;
}

function PingTasks(props: Props) {
  const theme = useTheme();
  const setSnackbar = useContext(SetSnackbar);

  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [showReOrderModal, setShowReOrderModal] = useState(false);
  const [loadingTests, setLoadingTests] = useState(false);
  const [tests, setTests] = useState<TestViewModel[]>([]);
  const [testToEdit, setTestToEdit] = useState<TestViewModel>();
  const [testToDelete, setTestToDelete] = useState<TestViewModel>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [testToPauseResume, setTestToPauseResume] = useState<TestViewModel>();
  const [showPauseResumeModal, setShowPauseResumeModal] = useState(false);
  const [testForOverride, setTestForOverride] = useState<TestViewModel>();
  const [showOverrideModal, setShowOverrideModal] = useState(false);

  const [numberOfTestsUsed, setNumberOfTestsUsed] = useState<number>();
  const [maxNumberOfTests, setMaxNumberOfTests] = useState<number>();
  const [hasReachedMaxTests, setHasReachedMaxTests] = useState(false);
  const [reRender, setReRender] = useState(false);

  useEffect(() => {
    if (props.site) {
      setLoadingTests(true);
      setTests([]);
      new TestClient(Configuration.ServerRoot, FetchOverride)
        .getTestsForSite(props.site.id)
        .then(setTests)
        .catch(() =>
          setSnackbar({
            message: "Could not get tests for the site. Please try again later",
            status: "error",
          })
        )
        .finally(() => setLoadingTests(false));

      new TestClient(Configuration.ServerRoot, FetchOverride)
        .getTestsCreatedForOrganisation()
        .then((x) => {
          setNumberOfTestsUsed(x[0]);
          setMaxNumberOfTests(x[1]);
          setHasReachedMaxTests(
            x[0] !== undefined && x[1] !== undefined && x[0] >= x[1]
          );
        });
    }
  }, [props.site, reRender]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            pb: 3,
          }}
          style={{
            lineHeight: 0.3,
            marginLeft: 20,
          }}
          variant="h2"
        >
          {props.hideHeader !== true && "Uptime Monitors"}
        </Typography>
        <Box>
          <Tooltip
            title="Reorder Monitors"
            arrow
            style={{ marginTop: -17, paddingTop: 0 }}
          >
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color={"inherit"}
              size="small"
              onClick={() => setShowReOrderModal(true)}
            >
              <Reorder style={{ fontSize: "28px" }} />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={
              hasReachedMaxTests
                ? "Maximum Number of Tests Reached. Please Upgrade Your Package or Delete A Test To Add A New One"
                : "Add Test (" +
                  numberOfTestsUsed +
                  "/" +
                  maxNumberOfTests +
                  " used)"
            }
            arrow
            style={{ marginTop: -17, paddingTop: 0 }}
          >
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: hasReachedMaxTests
                  ? theme.palette.text.disabled
                  : theme.palette.primary.main,
              }}
              color={"inherit"}
              size="small"
              onClick={() =>
                !hasReachedMaxTests && setShowCreateEditModal(true)
              }
            >
              <Add fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <LoadingSpinner loading={loadingTests} />
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
        }}
        container
      >
        {tests.map((x) => (
          <Grid item md={3.8} xs={12} key={x.name + x.url}>
            <TestStatusCard
              test={x}
              onEditPressed={() => {
                setTestToEdit(x);
                setShowCreateEditModal(true);
              }}
              onPauseResumePressed={() => {
                setTestToPauseResume(x);
                setShowPauseResumeModal(true);
              }}
              onDeletePressed={() => {
                setTestToDelete(x);
                setShowDeleteModal(true);
              }}
              onOverridePressed={() => {
                setTestForOverride(x);
                setShowOverrideModal(true);
              }}
            />
          </Grid>
        ))}
        {!loadingTests && (tests === undefined || tests.length === 0) && (
          <Grid item xs={12} key="No Tests">
            <Typography style={{ width: "100%", textAlign: "center" }}>
              No tests created. Click the plus symbol in the top right to add
              one.
            </Typography>
          </Grid>
        )}
      </Grid>
      {props.site && (
        <>
          <PingTestModal
            open={showCreateEditModal}
            onClose={(cancelled) => {
              setShowCreateEditModal(false);

              if (!cancelled) {
                props.refreshSite();
                setReRender((x) => !x);
              }

              setTimeout(() => {
                setTestToEdit(undefined);
              }, 1000);
            }}
            site={props.site}
            testToEdit={testToEdit}
          />
          <PauseResumePingTestModal
            open={showPauseResumeModal}
            onClose={(cancelled) => {
              setShowPauseResumeModal(false);

              setTimeout(() => {
                setTestToPauseResume(undefined);
                if (!cancelled) {
                  props.refreshSite();
                  setReRender((x) => !x);
                }
              }, 1000);
            }}
            name={testToPauseResume?.name}
            description={
              "Are you sure that you want to " +
              (testToPauseResume?.isInMaintenanceMode ? "resume " : "pause ") +
              testToPauseResume?.name +
              "?. This will immediately " +
              (testToPauseResume?.isInMaintenanceMode ? "resume " : "pause ") +
              " the running of this test."
            }
            test={testToPauseResume}
          />{" "}
          <TestOverrideModal
            open={showOverrideModal}
            onClose={(cancelled) => {
              setShowOverrideModal(false);

              setTimeout(() => {
                setTestForOverride(undefined);
                if (!cancelled) {
                  props.refreshSite();
                  setReRender((x) => !x);
                }
              }, 1000);
            }}
            test={testForOverride}
          />
          <DeletePingTestModal
            open={showDeleteModal}
            onClose={(cancelled) => {
              setShowDeleteModal(false);

              setTimeout(() => {
                setTestToDelete(undefined);
                if (!cancelled) {
                  props.refreshSite();
                  setReRender((x) => !x);
                }
              }, 1000);
            }}
            name={testToDelete?.name}
            description={
              "Are you sure that you want to delete " +
              testToDelete?.name +
              " from your tests? This will immediately stop this test from running for this site and cannot be undone."
            }
            test={testToDelete}
          />
          <ReOrderUptimeMonitorsModal
            open={showReOrderModal}
            onClose={(cancelled) => {
              setShowReOrderModal(false);

              setTimeout(() => {
                if (!cancelled) {
                  props.refreshSite();
                  setReRender((x) => !x);
                }
              }, 1000);
            }}
            monitors={tests}
          />
        </>
      )}
    </>
  );
}

export default PingTasks;
