import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "src/components/Footer/Footer";
import { useEffect, useState } from "react";
import {
  StatusPageSiteClient,
  StatusPageSiteViewModel,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/material";
import { FetchOverride } from "src/contexts/Requests";
import AccountSetupPagination from "src/components/AccountSetupPagination/AccountSetupPagination";
import Logo from "src/components/LogoSign";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "src/components/LoadingSpinner/LoadingSpinner";
import PingTasks from "src/view/dashboards/IndividualSiteView/PingTasks";

export default function CreateTestsSignUp() {
  const [, setRerender] = useState(false);

  const [searchParams] = useSearchParams();
  const [site, setSite] = useState<StatusPageSiteViewModel>();

  useEffect(() => {
    const siteId = searchParams.get("id");

    if (siteId) {
      new StatusPageSiteClient(Configuration.ServerRoot, FetchOverride)
        .getSiteById(siteId)
        .then((x) => setSite(x))
        .catch(() => (location.href = "/"));
    } else {
      location.href = "/";
    }
  }, []);

  if (site === undefined) {
    return (
      <Container style={{ marginTop: "20px", width: "100%" }}>
        <LoadingSpinner loading />
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Logo height={150} hideNavigation />
        <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
          Create the uptime monitors to be shown on your status page by clicking
          the + button on the right of the page
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Box style={{ width: "60vw" }}>
            <PingTasks
              site={site}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              refreshSite={() => {
                setRerender((x) => !x);
              }}
              hideHeader
            />
          </Box>

          <LoadingButton
            fullWidth
            variant="contained"
            style={{ color: "white" }}
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              location.href = "/statuspage/" + site.id;
            }}
          >
            Continue To Dashboard
          </LoadingButton>
        </Box>
      </Box>
      <AccountSetupPagination
        pageNumber={4}
        isFullAccountSetup={document.URL.includes("signup")}
      />
      <Footer />
    </Container>
  );
}
