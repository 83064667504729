import React from "react";
import { Box } from "@mui/material";

function HeaderButtons() {
  return (
    <Box sx={{ mr: 1 }}>
      <Box sx={{ mx: 0.5 }} component="span">
        {/*Notifications currently disabled as not in this scheme of work<HeaderNotifications />*/}
      </Box>
    </Box>
  );
}

export default HeaderButtons;
