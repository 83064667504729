import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Footer from "src/components/Footer/Footer";
import { useContext, useState } from "react";
import PasswordChecklist from "react-password-checklist";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { UserClient } from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { useSearchParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import AnimatedWavingHand from "src/components/AnimatedWavingHand/AnimatedWavingHand";

export default function AcceptInvitation() {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const setSnackbar = useContext(SetSnackbar);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!passwordValid) {
      return;
    }

    const token = searchParams.get("token");
    setLoading(true);

    // Sign up
    new UserClient(Configuration.ServerRoot, FetchOverride)
      .resetPassword(password, parseInt(token))
      .then(() => {
        setSnackbar({
          message:
            "Password setup has been successful. Please login to your account",
          status: "success",
        });

        location.replace("/login");
      })
      .catch(() =>
        setSnackbar({
          message:
            "Password could not be set. Please check that your access token is correct",
          status: "error",
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <AnimatedWavingHand />
        <Typography component="h1" variant="h2">
          Welcome to {Configuration.name}!
        </Typography>
        <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
          Please enter a password to create your account.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmpassword"
            label="Confirm Password"
            type="password"
            id="confirmpassword"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={8}
            value={password}
            valueAgain={confirmPassword}
            onChange={setPasswordValid}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            style={{ color: "white" }}
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            Set Password
          </LoadingButton>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
}
