import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  TableRow,
  Typography,
  Box,
  Tooltip,
  TextField,
} from "@mui/material";
import { Add, Delete, ExpandMore } from "@mui/icons-material";

interface Props {
  headers: string;
  setHeaders: React.Dispatch<React.SetStateAction<string>>;
}

const HeaderTableAccordion = (props: Props): JSX.Element => {
  return (
    <Accordion
      style={{ marginTop: "10px", backgroundColor: "#f0f0f0" }}
      disableGutters
    >
      <AccordionSummary expandIcon={<ExpandMore />} id="requestHeadersSummary">
        Request Headers
      </AccordionSummary>
      <AccordionDetails style={{ paddingTop: 0 }}>
        <Box style={{ float: "right" }}>
          <Tooltip title={"Add Header"}>
            <IconButton
              onClick={() => {
                let values = {};
                if (props.headers) {
                  values = JSON.parse(props.headers);
                }
                values[""] = "";
                props.setHeaders(JSON.stringify(values));
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </Box>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="headertable">
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.headers.length > 2 ? (
                Object.entries(JSON.parse(props.headers)).map((row) => (
                  <TableRow
                    key={row[0]}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <TextField
                        variant="outlined"
                        defaultValue={row[0]}
                        onBlur={(e) => {
                          const values = JSON.parse(props.headers);
                          delete values[row[0]];
                          values[e.target.value] = row[1];
                          props.setHeaders(JSON.stringify(values));
                        }}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        defaultValue={row[1]}
                        onBlur={(e) => {
                          const values = JSON.parse(props.headers);
                          values[row[0]] = e.target.value;
                          props.setHeaders(JSON.stringify(values));
                        }}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title={"Delete Header"}>
                        <IconButton
                          onClick={() => {
                            const values = JSON.parse(props.headers);
                            delete values[row[0]];
                            props.setHeaders(JSON.stringify(values));
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  key="No Data Row"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={3}>
                    <Typography style={{ width: "100%", textAlign: "center" }}>
                      No headers for request. Press the + button to add one
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default HeaderTableAccordion;
