import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Configuration } from "src/Constants";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { OrganisationClient, UserViewModel } from "src/types/auto/types";
import { FetchOverride } from "src/contexts/Requests";
import LoadingSpinner from "src/components/LoadingSpinner/LoadingSpinner";
import { Add } from "@mui/icons-material";
import UserStatusCard from "./UserStatusCard";
import UserModal from "src/components/UserModal/UserModal";

function AccountManagementSettings() {
  const theme = useTheme();
  const [users, setUsers] = useState<UserViewModel[]>([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const setSnackbar = useContext(SetSnackbar);

  useEffect(() => {
    setLoading(true);
    new OrganisationClient(Configuration.ServerRoot, FetchOverride)
      .getUsersForOrganisation()
      .then(setUsers)
      .catch(() =>
        setSnackbar({
          message:
            "Could not get users for your organisation. Please try again later",
          status: "error",
        })
      )
      .finally(() => setLoading(false));
  }, [reloadUsers]);

  return (
    <>
      <Helmet>
        <title>Accounts | {Configuration.name}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 35,
          }}
        >
          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              marginLeft: 20,
            }}
            variant="h2"
          >
            User Accounts
          </Typography>
          <Tooltip
            title="Add Account"
            arrow
            style={{ marginTop: -17, paddingTop: 0 }}
          >
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
              onClick={() => setShowCreateModal(true)}
            >
              <Add fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>
        <LoadingSpinner loading={loading} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          mt="0px"
        >
          {users.map((x) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={"user account management for " + x.name}
            >
              <UserStatusCard
                user={x}
                onChange={() =>
                  setTimeout(() => setReloadUsers((x) => !x), 1000)
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <UserModal
        open={showCreateModal}
        onClose={(cancelled) => {
          setShowCreateModal(false);
          if (!cancelled) {
            setTimeout(() => setReloadUsers((x) => !x), 1000);
          }
        }}
      />
    </>
  );
}

export default AccountManagementSettings;
