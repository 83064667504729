import { TextField } from "@mui/material";
import React from "react";
import { IsSiteNameValid } from "./SiteModalHelper";

interface Props {
  siteName: string;
  setSiteName: React.Dispatch<React.SetStateAction<string>>;
  siteNameError: boolean;
  setSiteNameError: React.Dispatch<React.SetStateAction<boolean>>;
}

const SiteForm = (props: Props): JSX.Element => (
  <TextField
    autoFocus
    margin="dense"
    id="name"
    label="Status Page Name"
    fullWidth
    variant="standard"
    placeholder="My Status Page Name"
    value={props.siteName}
    onChange={(e) => props.setSiteName(e.target.value)}
    onBlur={() => props.setSiteNameError(!IsSiteNameValid(props.siteName))}
    error={props.siteNameError}
    helperText={props.siteNameError && "Please enter a valid status page name"}
  />
);

export default SiteForm;
