import React, { useContext, useEffect, useState } from "react";
import {
  StatusPageSiteViewModel,
  StatusPageClient,
  StatusPageOptionsViewModel,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import {
  Card,
  CardHeader,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { LoadingButton } from "@mui/lab";
import ImageUpload from "src/components/ImageUpload/ImageUpload";

interface Props {
  site: StatusPageSiteViewModel;
}

export default function UpdateStatusPageAnalyticsSearch(props: Props) {
  const setSnackbar = useContext(SetSnackbar);
  const [data, setData] = useState<StatusPageOptionsViewModel>();
  const [analytics, setAnalytics] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    new StatusPageClient(Configuration.ServerRoot, FetchOverride)
      .getStatusPageForSiteId(props.site.id)
      .then((x) => {
        setData(x);
        if (x.analyticsScript) {
          setAnalytics(x.analyticsScript);
        }
        if (x.socialTitle) {
          setTitle(x.socialTitle);
        }
        if (x.socialDescription) {
          setDescription(x.socialDescription);
        }
        if (x.socialImageData) {
          setFile(x.socialImageData);
        }
        if (x.socialImageFileName) {
          setFileName(x.socialImageFileName);
        }
      })
      .catch(() => {
        setSnackbar({
          message:
            "Could not retrieve current status page. Please try again or contact support",
          status: "error",
        });
      });
  }, []);

  if (data === undefined) {
    return <CircularProgress />;
  }

  return (
    <>
      <Card style={{ width: "100%", padding: "10px", marginTop: 20 }}>
        <CardHeader
          title={
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                marginBottom: 15,
                paddingBottom: 0,
              }}
              variant="h3"
            >
              Analytics & Search
            </Typography>
          }
          subheader={
            <Typography style={{ marginLeft: 10 }}>
              Add status page analytics and customise how your status page looks
              on search engines and social media
            </Typography>
          }
        />
        <Container
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "20px",
          }}
        >
          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            Analytics
          </Typography>
          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              marginBottom: 15,
              paddingBottom: 0,
            }}
          >
            Add your analytics script here. We support all of the major
            analytics tools
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={5}
            value={analytics}
            onChange={(e) => setAnalytics(e.target.value)}
            placeholder={`<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=<analyticsId>"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-XXXXXXX');
</script>
`}
            label="Analytics"
            InputLabelProps={{ shrink: true }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              setLoading(true);

              const d = data;
              d.analyticsScript = analytics;

              // Send the update
              new StatusPageClient(Configuration.ServerRoot, FetchOverride)
                .updateStatusPage(d)
                .then(setData)
                .catch(() =>
                  setSnackbar({
                    message:
                      "Could not update the status page analytics. Please try again or contact support",
                    status: "error",
                  })
                )
                .finally(() => setLoading(false));
            }}
          >
            Save
          </LoadingButton>
        </Container>

        <Container
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "20px",
          }}
        >
          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            Search and Social
          </Typography>

          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              marginBottom: 15,
              paddingBottom: 0,
            }}
          >
            Add a page title, description and banner image to display on search
            and social pages.
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={"Status Page"}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            variant="standard"
            fullWidth
            label="Description"
            multiline
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={"A beautiful status page, created by Status Hive"}
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: "30px", marginBottom: "30px" }}
          />
          <ImageUpload
            label="Banner Image (1200px x 630px recommended) (PNG under 2MB)"
            fileName={fileName}
            accept="image/png"
            onChange={(file) => {
              if (file === undefined) {
                setFile("");
                setFileName("");
              }

              const reader = new FileReader();
              reader.onload = function (e) {
                const contents = e.target.result;

                setFile(contents as string);
                setFileName(file.name);
              };
              reader.readAsDataURL(file);
            }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            loading={loading}
            variant="contained"
            onClick={() => {
              setLoading(true);

              const d = data;
              d.socialTitle = title;
              d.socialDescription = description;
              d.socialImageFileName = fileName;
              d.socialImageData = file;

              // Send the update
              new StatusPageClient(Configuration.ServerRoot, FetchOverride)
                .updateStatusPage(d)
                .then(setData)
                .catch(() =>
                  setSnackbar({
                    message:
                      "Could not update the social settings. Please try again or contact support",
                    status: "error",
                  })
                )
                .finally(() => setLoading(false));
            }}
          >
            Save
          </LoadingButton>
        </Container>
      </Card>
    </>
  );
}
