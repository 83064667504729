import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { SiteStatus } from "src/types/auto/types";
import StatusIcon from "../UptimeHelpers/StatusIcon";

interface Props {
  overrideValue: SiteStatus | undefined;
  setOverrideValue: React.Dispatch<
    React.SetStateAction<SiteStatus | undefined>
  >;
  pauseTest: boolean;
  setPauseTest: React.Dispatch<React.SetStateAction<boolean>>;
  isStatusOverride: boolean;
  width?: number | string;
}

const statusOption = (status: SiteStatus | "-1", name: string): JSX.Element => (
  <Box
    style={{
      display: "inline-flex",
      marginLeft: "10px",
      gap: "10px",
      color: "black",
    }}
  >
    {status !== "-1" ? <StatusIcon status={status} hideTooltip /> : <></>}
    {name}
  </Box>
);

const StatusOverrideDropdown = (props: Props): JSX.Element => {
  return (
    <>
      <FormControl sx={{ m: 1, width: props.width ? props.width : 300 }}>
        <InputLabel id="statusOverrideLabel">
          {props.isStatusOverride ? "Status Override" : "Test Status"}
        </InputLabel>
        <Select
          labelId="statusOverrideLabel"
          id="statusOverride"
          value={props.overrideValue === undefined ? "-1" : props.overrideValue}
          onChange={(e) => {
            if (e.target.value === "-1") {
              props.setOverrideValue(undefined);
            } else {
              props.setOverrideValue(e.target.value as SiteStatus | undefined);
            }
          }}
          input={<Input />}
          renderValue={(value) => {
            switch (value) {
              case "-1":
                return "";
              case SiteStatus.Online:
                return statusOption(value, "Online");
              case SiteStatus.Offline:
                return statusOption(value, "Offline");
              case SiteStatus.TestsPaused:
                return statusOption(value, "Maintenance");
            }
          }}
        >
          {props.isStatusOverride && (
            <MenuItem value="-1">
              <ListItemText style={{ color: "grey", marginLeft: "40px" }}>
                No Override
              </ListItemText>
            </MenuItem>
          )}
          <MenuItem value={SiteStatus.Online}>
            {statusOption(SiteStatus.Online, "Online")}
          </MenuItem>
          <MenuItem value={SiteStatus.TestsPaused}>
            {statusOption(SiteStatus.TestsPaused, "Maintenance")}
          </MenuItem>
          <MenuItem value={SiteStatus.Offline}>
            {statusOption(SiteStatus.Offline, "Offline")}
          </MenuItem>
        </Select>
      </FormControl>
      {props.isStatusOverride && (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.pauseTest}
                onChange={(_, checked) => props.setPauseTest(checked)}
              />
            }
            label="Pause Uptime Test"
          />
        </Box>
      )}
    </>
  );
};

export default StatusOverrideDropdown;
