import React, { useEffect, useState } from "react";
import { Box, Slide, Typography } from "@mui/material";
import {
  EventViewModel,
  StatusPageOptionsViewModel,
  TestViewModel,
} from "src/types/auto/types";
import EventCard from "src/components/StatusPageComponents/EventCard/EventCard";
import moment from "moment";

interface Props {
  events: EventViewModel[];
  onEditPressed?: (event: EventViewModel) => void;
  onDeletePressed?: (event: EventViewModel) => void;
  tests?: TestViewModel[];
  settings?: StatusPageOptionsViewModel;
}

const EventsList = (props: Props): JSX.Element => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.events && props.events.length > 0) {
      setShow(true);
    }
  }, [props.events]);

  return (
    <>
      {(props.events === undefined || props.events.length === 0) && (
        <Typography
          style={{
            width: "100%",
            textAlign: "center",
            color: props.settings?.pageBodySecondaryFontColour,
          }}
        >
          {props.onEditPressed ? (
            <>
              You currently don&lsquo;t have any events for this status page
              <br /> Events allow you to update your users about your
              site&lsquo;s status
              <br /> <br /> Click the plus symbol in the top right to add one
            </>
          ) : (
            "There are no events to show"
          )}
        </Typography>
      )}
      <Box style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {[...new Map(props.events.map((x) => [x.id, x])).values()].map(
          (event, index) => (
            <Box key={"Event" + event.id}>
              {(index === 0 ||
                props.events[index - 1].created.toDateString() !==
                  event.created.toDateString()) && (
                <Typography
                  variant="h4"
                  style={{
                    marginTop: "20px",
                    color: props.settings?.pageBodyPrimaryFontColour,
                  }}
                >
                  {moment(event.created).format(
                    props.settings?.eventGroupDateLocalisation || "Do MMMM YYYY"
                  )}
                </Typography>
              )}
              <Slide in={show} direction="right">
                <Box style={{ width: "100%" }}>
                  <EventCard
                    settings={props.settings}
                    event={event}
                    onEditPressed={props.onEditPressed}
                    onDeletePressed={props.onDeletePressed}
                    tests={props.tests}
                  />
                </Box>
              </Slide>
            </Box>
          )
        )}
      </Box>
    </>
  );
};

export default EventsList;
