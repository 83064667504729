import React from "react";
import { useRoutes } from "react-router-dom";
import router from "src/router";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import UserStore from "./contexts/UserStorage";
import SnackbarContext from "./contexts/SnackbarContext";
import SitesContextWrapper from "./contexts/SitesContext";

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <SnackbarContext>
        <UserStore>
          <SitesContextWrapper>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              {content}
            </LocalizationProvider>
          </SitesContextWrapper>
        </UserStore>
      </SnackbarContext>
    </ThemeProvider>
  );
}
export default App;
