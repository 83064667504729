import React from "react";
import { Box, Container, Typography, styled } from "@mui/material";
import { Configuration } from "src/Constants";

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display={{ xs: "block", md: "block" }}
        alignItems="center"
        textAlign={{ xs: "center", md: "center" }}
        justifyContent="space-between"
      >
        <Typography variant="subtitle1">
          &copy; {new Date().getFullYear()} - {Configuration.name} -{" "}
          {Configuration.VERSION === undefined
            ? "Development"
            : Configuration.VERSION}
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ cursor: "pointer" }}
            onClick={() => (location.href = "https://statushive.net/blog")}
          >
            Blog
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ cursor: "pointer" }}
            onClick={() => (location.href = "https://status.statushive.net")}
          >
            Status
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ cursor: "pointer" }}
            onClick={() => (location.href = "https://statushive.net/legal")}
          >
            Legal
          </Typography>
        </Box>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
