import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import { Configuration } from "src/Constants";
import {
  OrganisationClient,
  OrganisationViewModel,
} from "src/types/auto/types";
import { FetchOverride } from "./Requests";
import { SetSnackbar } from "./SnackbarContext";
import {
  OrganisationContext,
  OrganisationDispatchContext,
  UserContext,
} from "./UserStorage";

const AuthedRoute = () => {
  const userContext = useContext(UserContext);
  const organisationContext = useContext(OrganisationContext);
  const setOrganisationContext = useContext(OrganisationDispatchContext);
  const setSnackbar = useContext(SetSnackbar);

  useEffect(() => {
    // Navigate to logged out if the user is null
    if (
      userContext?.email === undefined ||
      userContext?.email === null ||
      userContext.email.replace(" ", "") === ""
    ) {
      location.replace("/login");

      // Remove the organisation context
      if (
        organisationContext?.id !== undefined &&
        organisationContext?.id !== null &&
        organisationContext?.id !== ""
      ) {
        setOrganisationContext({} as OrganisationViewModel);
      }
    }
    // If the user is logged in, but there is no organisation, get the organisation
    else if (
      organisationContext?.id === undefined ||
      organisationContext?.id === null ||
      organisationContext?.id === ""
    ) {
      new OrganisationClient(Configuration.ServerRoot, FetchOverride)
        .getOrganisation()
        .then((x) => setOrganisationContext(x))
        .catch(() => {
          setSnackbar({
            message: "Could not find organisation for the user",
            status: "error",
          });
        });
    }
  }, [userContext, organisationContext, setOrganisationContext]);

  return <Outlet />;
};

export default AuthedRoute;
