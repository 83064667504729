import {
  EventStatus,
  StatusPageSiteViewModel,
  StatusGraphType,
  StatusPageDataViewModel,
} from "src/types/auto/types";

export const DefaultStatusPage = (site: StatusPageSiteViewModel) => {
  const fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
  const fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
  const fifteenDaysAgo1 = new Date();
  fifteenDaysAgo1.setDate(fifteenDaysAgo1.getDate() - 15);
  fifteenDaysAgo1.setHours(fifteenDaysAgo1.getHours() - 1);
  const fifteenDaysAgo2 = new Date();
  fifteenDaysAgo2.setDate(fifteenDaysAgo2.getDate() - 15);
  fifteenDaysAgo2.setMinutes(fifteenDaysAgo2.getMinutes() - 13);
  const fifteenDaysAgo3 = new Date();
  fifteenDaysAgo3.setDate(fifteenDaysAgo3.getDate() - 15);
  fifteenDaysAgo3.setMinutes(fifteenDaysAgo3.getMinutes() - 3);
  const fifteenDaysAgo4 = new Date();
  fifteenDaysAgo4.setDate(fifteenDaysAgo4.getDate() - 15);
  const twentyDaysAgo = new Date();
  twentyDaysAgo.setDate(twentyDaysAgo.getDate() - 20);
  const twentyFiveDaysAgo = new Date();
  twentyFiveDaysAgo.setDate(twentyFiveDaysAgo.getDate() - 25);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  return {
    settings: {
      statusPageSiteId: site.id,
      name: site.name,
      title: site.name,
      headerColour1: "rgba(245, 142, 21, 1)",
      headerColour2: "rgba(245, 142, 21, 1)",
      headerColourGradientDegrees: "10",
      headerFontColour: "rgba(255, 255, 255, 1)",
      subscribeText: "Subscribe For Updates",
      buttonFontColour: "rgba(255, 255, 255, 1)",
      buttonBackgroundColour: "rgba(245, 142, 21, 0)",
      showTopStatusPane: true,
      topStatusPaneOnlineText: "All Sites Online",
      topStatusPaneMaintenanceText: "Expected Maintenance Is Being Carried Out",
      topStatusPaneOfflineText: "Site Partially Down",
      onlineColour: "rgba(87, 202, 34, 1)",
      maintenanceColour: "rgba(79, 182, 34, 1)",
      downColour: "rgba(239, 91, 91, 1)",
      statusGraphType: StatusGraphType.Blocks,
      pageBodyColour: "rgba(255, 255, 255, 1)",
      pageBodyPrimaryFontColour: "rgba(0, 0, 0, 1)",
      pageBodySecondaryFontColour: "rgba(102, 102, 102, 1)",
      modalColour: "rgba(255, 255, 255, 1)",
      modalFontColour: "rgba(102, 102, 102, 1)",
      eventCardBackgroundColour: "rgba(255, 255, 255, 1)",
      eventCardFontColour: "rgba(0, 0, 0, 1)",
      statusChipBackgroundColour: "rgba(232, 234, 237, 1)",
      statusChipFontColour: "rgba(34, 51, 84, 1)",
      siteChipBackgroundColour: "rgba(232, 234, 237, 1)",
      siteChipFontColour: "rgba(0, 0, 0, 1)",
    },
    siteData: [
      {
        id: "1",
        siteName: "Example Website",
        currentStatus: EventStatus.Online,
        events: [
          {
            created: fiveDaysAgo,
            status: EventStatus.Online,
            title: "Website Online",
            description: "The website is fully functional",
          },
          {
            created: fifteenDaysAgo1,
            status: EventStatus.Offline,
            title: "Website Offline",
            description: "An issue has occurred with the website",
          },
          {
            id: "12345",
            created: fifteenDaysAgo2,
            status: EventStatus.Investigating,
            title: "We're looking into the issue",
            description:
              "We've detected an issue with the website and we're looking into a fix",
            testIds: ["1", "2"],
          },
          {
            id: "12346",
            created: fifteenDaysAgo3,
            status: EventStatus.Fixing,
            title: "We're fixing the issue",
            description: "We've found the problem and are working on a fix",
            testIds: ["1", "2"],
          },
          {
            id: "12347",
            created: fifteenDaysAgo4,
            status: EventStatus.Resolved,
            title: "We've fixed the issue",
            description:
              "We've fixed the issue, and the website is back online",
            testIds: ["1", "2"],
          },
          {
            created: fifteenDaysAgo4,
            status: EventStatus.Online,
            title: "Website Online",
            description: "The website is fully functional",
          },
          {
            created: twentyDaysAgo,
            status: EventStatus.Maintenance,
            title: "Website In Maintenance",
            description: "The website is being maintained",
          },
        ],
      },
      {
        id: "2",
        siteName: "Example API",
        currentStatus: EventStatus.Online,
        events: [
          {
            created: fiveDaysAgo,
            status: EventStatus.Online,
            title: "Website Online",
            description: "The website is fully functional",
          },
          {
            created: fifteenDaysAgo1,
            status: EventStatus.Offline,
            title: "Website Offline",
            description: "An issue has occurred with the website",
          },
          {
            id: "12345",
            created: fifteenDaysAgo2,
            status: EventStatus.Investigating,
            title: "We're looking into the issue",
            description:
              "We've detected an issue with the website and we're looking into a fix",
            testIds: ["1", "2"],
          },
          {
            id: "12346",
            created: fifteenDaysAgo3,
            status: EventStatus.Fixing,
            title: "We're fixing the issue",
            description: "We've found the problem and are working on a fix",
            testIds: ["1", "2"],
          },
          {
            id: "12347",
            created: fifteenDaysAgo4,
            status: EventStatus.Resolved,
            title: "We've fixed the issue",
            description:
              "We've fixed the issue, and the website is back online",
            testIds: ["1", "2"],
          },
          {
            created: fifteenDaysAgo4,
            status: EventStatus.Online,
            title: "Website Online",
            description: "The website is fully functional",
          },
          {
            created: thirtyDaysAgo,
            status: EventStatus.Maintenance,
            title: "Website In Maintenance",
            description: "The website is being maintained",
          },
          {
            id: "12348",
            created: twentyFiveDaysAgo,
            status: EventStatus.Resolved,
            title: "We've completed our maintenance",
            description:
              "We've completed the essential maintenance on the backend",
            testIds: ["2"],
          },
          {
            created: twentyFiveDaysAgo,
            status: EventStatus.Online,
            title: "Website Online",
            description: "The website is fully functional",
          },
        ],
      },
      {
        id: "3",
        siteName: "Example Database",
        currentStatus: EventStatus.Online,
        events: [
          {
            created: tenDaysAgo,
            status: EventStatus.Online,
            title: "Website Online",
            description: "The website is fully functional",
          },
          {
            created: twentyDaysAgo,
            status: EventStatus.Maintenance,
            title: "Large Maintenance Block",
            description: "This is a large block of maintenance data",
          },
          {
            created: thirtyDaysAgo,
            status: EventStatus.Offline,
            title: "Large Offline Block",
            description: "This is a large block of offline data",
          },
        ],
      },
    ],
  } as StatusPageDataViewModel;
};
