import { useContext, useEffect, useState } from "react";
import { UserContext } from "src/contexts/UserStorage";
import { StatusPageDataViewModel } from "src/types/auto/types";

interface Props {
  LoginComponent: JSX.Element;
  StatusPageComponent: (
    data: StatusPageDataViewModel | undefined
  ) => JSX.Element;
  DashboardComponent: JSX.Element;
}

const AuthNavigationSwitch = (props: Props): JSX.Element => {
  const user = useContext(UserContext);
  const [LiveStatusPageData, setLiveStatusPageData] =
    useState<StatusPageDataViewModel>();

  // A bit hacky, but check if there's an element called StatusPageData, and get the data from that
  useEffect(() => {
    const element = document.getElementById("StatusPageData");
    if (element && element.innerText.includes("{")) {
      const d = new StatusPageDataViewModel();
      d.init(JSON.parse(element.innerText));
      setLiveStatusPageData(d);
      //element.remove();
    }
  }, []);

  if (LiveStatusPageData) {
    return props.StatusPageComponent(LiveStatusPageData);
  } else if (user?.id) {
    return props.DashboardComponent;
  }

  return props.LoginComponent;
};

export default AuthNavigationSwitch;
