import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { IsSiteNameValid } from "./SiteModalHelper";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  StatusPageSiteClient,
  StatusPageSiteViewModel,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { UserContext } from "src/contexts/UserStorage";
import SiteForm from "./SiteForm";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  siteToEdit?: StatusPageSiteViewModel;
}

const SiteModal = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);
  const user = useContext(UserContext);

  const [siteName, setSiteName] = useState("");
  const [siteNameError, setSiteNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.siteToEdit) {
      setSiteName(props.siteToEdit.name);
    }
  }, [props.open, props.siteToEdit]);

  const CloseModal = (cancelled: boolean) => {
    props.onClose(cancelled);

    // Reset the state after the modal has closed
    setTimeout(() => {
      setSiteName("");

      setSiteNameError(false);
      setLoading(false);
    }, 1000);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => CloseModal(true)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {props.siteToEdit ? "Edit" : "Create"} Status Page
      </DialogTitle>
      <DialogContent>
        <SiteForm
          siteName={siteName}
          setSiteName={setSiteName}
          siteNameError={siteNameError}
          setSiteNameError={setSiteNameError}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => CloseModal(true)}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            const siteNameValid = IsSiteNameValid(siteName);
            setSiteNameError(!siteNameValid);
            if (siteNameValid) {
              setLoading(true);

              if (props.siteToEdit) {
                new StatusPageSiteClient(
                  Configuration.ServerRoot,
                  FetchOverride
                )
                  .updateSite({
                    id: props.siteToEdit.id,
                    organisationId: user.organisationId,
                    name: siteName,
                  } as StatusPageSiteViewModel)
                  .then((x) => {
                    if (x) {
                      setSnackbar({
                        message: "Status Page updated",
                        status: "success",
                      });
                      CloseModal(false);
                      return;
                    }
                    setSnackbar({
                      message:
                        "Status Page could not be updated. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                    setLoading(false);
                  })
                  .catch(() => {
                    setSnackbar({
                      message:
                        "Status Page could not be updated. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                  })
                  .finally(() => setLoading(false));
              } else {
                new StatusPageSiteClient(
                  Configuration.ServerRoot,
                  FetchOverride
                )
                  .createSite({
                    organisationId: user.organisationId,
                    name: siteName,
                  } as StatusPageSiteViewModel)
                  .then((x) => {
                    if (x) {
                      setSnackbar({
                        message: "Status Page was created",
                        status: "success",
                      });
                      CloseModal(false);
                      return;
                    }
                    setSnackbar({
                      message:
                        "Status Page could not be created. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                    setLoading(false);
                  })
                  .catch(() => {
                    setSnackbar({
                      message:
                        "Status Page could not be created. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                    setLoading(false);
                  })
                  .finally(() => setLoading(false));
              }
            }
          }}
        >
          {props.siteToEdit ? "Edit" : "Create"} Status Page
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SiteModal;
