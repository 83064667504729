import React, { useContext, useEffect, useState } from "react";
import { SiteStatus, TestClient, TestViewModel } from "src/types/auto/types";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import StatusOverrideDropdown from "../StatusOverrideDropdown/StatusOverrideDropdown";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  test: TestViewModel;
}

const TestOverrideModal = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);
  const [loading, setLoading] = useState(false);
  const [overrideValue, setOverrideValue] = useState<SiteStatus>();
  const [pauseTest, setPauseTest] = useState(false);

  useEffect(() => {
    if (props.test) {
      setOverrideValue(props.test.overriddenStatus);
      setPauseTest(props.test.isInMaintenanceMode);
    }
  }, [props.test]);

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose(true)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Set Monitor Override Status</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Set the status to override the uptime monitor to. If an uptime monitor
          is overridden and the test is not paused, notifications will only be
          sent to members of the organisation and the status page will not be
          updated if the test status changes
        </DialogContentText>
        <StatusOverrideDropdown
          isStatusOverride
          overrideValue={overrideValue}
          setOverrideValue={setOverrideValue}
          pauseTest={pauseTest}
          setPauseTest={setPauseTest}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={() => props.onClose(true)}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            setLoading(true);

            new TestClient(Configuration.ServerRoot, FetchOverride)
              .updateTest({
                ...props.test,
                isInMaintenanceMode: pauseTest,
                overriddenStatus: overrideValue,
              } as TestViewModel)
              .then((x) => {
                if (x) {
                  setSnackbar({
                    message: "Test override status updated for site",
                    status: "success",
                  });
                  props.onClose(false);
                  setLoading(false);
                  return;
                }
                setSnackbar({
                  message:
                    "Test override status could not be updated. Please ensure that your values are correct and try again",
                  status: "error",
                });
                setLoading(false);
              })
              .catch(() => {
                setSnackbar({
                  message:
                    "Test override status could not be updated. Please ensure that your values are correct and try again",
                  status: "error",
                });
                setLoading(false);
              });
          }}
        >
          Set Override Status
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TestOverrideModal;
