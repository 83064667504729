import React, { useState } from "react";
import reactCSS from "reactcss";
import { ChromePicker } from "react-color";
import { Typography } from "@mui/material";

interface Props {
  colour: string;
  setColour: React.Dispatch<React.SetStateAction<string>>;
  label: string;
}

const rgbaToHex = (rgba: string): string => {
  const rgbaMatched = rgba.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );

  return rgbaMatched && rgbaMatched.length === 4
    ? "#" +
        ("0" + parseInt(rgbaMatched[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgbaMatched[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgbaMatched[3], 10).toString(16)).slice(-2)
    : "";
};

const ColourPicker = (props: Props): JSX.Element => {
  const [showPicker, setShowPicker] = useState(false);
  const [, setReRender] = useState(false);
  const [colour, setColour] = useState(props.colour);

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "36px",
        borderRadius: "2px",
        background: colour,
      },
      swatch: {
        padding: "5px",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        gap: "10",
        alignItems: "center",
        width: "auto",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div style={{ width: "100%" }}>
      <Typography
        style={{
          width: "100%",
          textAlign: "left",
          marginLeft: "10px",
          color: "grey",
        }}
      >
        {props.label}
      </Typography>
      <div
        style={styles.swatch}
        onClick={() => {
          setShowPicker((x) => !x);
        }}
      >
        <div style={styles.color} />
        <Typography style={{ marginLeft: 15 }}>{rgbaToHex(colour)}</Typography>
      </div>
      {showPicker ? (
        <div style={styles.popover}>
          <div
            style={styles.cover}
            onClick={() => {
              setShowPicker(false);
            }}
          />
          <ChromePicker
            color={colour}
            onChange={(e) => {
              setColour(`rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`);
              setReRender((x) => !x);
            }}
            onChangeComplete={(e) => {
              props.setColour(
                `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`
              );
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColourPicker;
