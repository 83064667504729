import React from "react";
import {
  styled,
  Container,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { EventStatus, SiteStatus } from "src/types/auto/types";

interface Props extends Colours {
  status?: SiteStatus;
  eventStatus?: EventStatus;
  pixelSize?: number;
  isSiteStatus?: boolean;
  hideTooltip?: boolean;
}

interface Colours {
  onlineColour?: string;
  maintenanceColour?: string;
  offlineColour?: string;
  modalColour?: string;
  modalFontColour?: string;
}

const IconWrapper = styled(Container, {
  shouldForwardProp: (prop) =>
    prop !== "onlineColour" &&
    prop !== "maintenanceColour" &&
    prop !== "offlineColour",
})(
  (colours: Colours) => `
  .blob {
	background: black;
	border-radius: 50%;
	box-shadow: 0 0 0 0 ${colours.onlineColour || "rgba(87, 201, 34, 1)"};
	margin: 5px;
	height: 10px;
	width: 10px;
    padding: 0;
	transform: scale(1);
}
  .blob.up {
	background: ${colours.onlineColour || "rgba(87, 201, 34, 1)"};
	box-shadow: 0 0 0 0 ${colours.onlineColour || "rgba(87, 201, 34, 1)"};
	animation: pulse-up 4s infinite;
}

@keyframes pulse-up {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 ${colours.onlineColour || "rgba(87, 201, 34, 1)"};
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(87, 201, 34, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(87, 201, 34, 0);
	}
}

.blob.down {
	background: ${colours.offlineColour || "rgba(255, 24, 66, 1)"};
	box-shadow: 0 0 0 0 ${colours.offlineColour || "rgba(255, 24, 66, 1)"};
	animation: pulse-down 4s infinite;
}

@keyframes pulse-down {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 ${colours.offlineColour || "rgba(255, 24, 66, 1)"};
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 24, 66, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 24, 66, 0);
	}
}
.blob.paused {
	background: ${colours.maintenanceColour || "#FFA319"};
	box-shadow: 0 0 0 0 ${colours.maintenanceColour || "#FFA319"};
}
`
);

const StatusIcon = (props: Props): JSX.Element => {
  const StyledTooltip = styled(({ className, ...p }: TooltipProps) => (
    <Tooltip {...p} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: props.modalColour,
      color: props.modalFontColour,
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
      fontSize: 11,
    },
  }));

  return (
    <StyledTooltip
      title={
        <Typography style={{ color: props.modalFontColour }}>
          {(props.isSiteStatus ? "Site " : "Test ") +
            (props.status === SiteStatus.TestsPaused ||
            props.eventStatus === EventStatus.Maintenance
              ? (props.isSiteStatus ? "Tests " : "") + "Paused"
              : props.status === SiteStatus.Online ||
                props.eventStatus === EventStatus.Online
              ? "Online"
              : "Offline")}
        </Typography>
      }
      hidden={props.hideTooltip}
    >
      <IconWrapper
        style={{ margin: 0, padding: 0 }}
        onlineColour={props.onlineColour}
        maintenanceColour={props.maintenanceColour}
        offlineColour={props.offlineColour}
      >
        <div
          className={
            "blob " +
            (props.status === SiteStatus.TestsPaused ||
            props.eventStatus === EventStatus.Maintenance
              ? "paused"
              : props.status === SiteStatus.Online ||
                props.eventStatus === EventStatus.Online
              ? "up"
              : "down")
          }
          style={
            props.pixelSize && {
              width: props.pixelSize + "px",
              height: props.pixelSize + "px",
            }
          }
        ></div>
      </IconWrapper>
    </StyledTooltip>
  );
};

export default StatusIcon;
