import React, { useEffect } from "react";
import { StatusPageDataViewModel } from "src/types/auto/types";
import StatusPageDisplay from "./StatusPageDisplay";
import changeFavicon from "./SetFavicon";

interface Props {
  statusPageData: StatusPageDataViewModel;
}

const StatusPage = (props: Props): JSX.Element => {
  useEffect(() => {
    if (props.statusPageData?.settings?.icon) {
      changeFavicon(props.statusPageData.settings.icon);
      document.title = props.statusPageData.settings.title;
    }
  }, [props.statusPageData]);

  useEffect(() => {
    document.body.style.backgroundColor =
      props.statusPageData?.settings?.pageBodyColour || "#ffffff";
    document.title = props.statusPageData?.settings?.title || "";
  }, [props.statusPageData]);

  return (
    <>
      <StatusPageDisplay data={props.statusPageData} />
    </>
  );
};

export default StatusPage;
