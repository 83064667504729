import { LoadingButton } from "@mui/lab";
import { Card, CardHeader, Container, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import DeleteSiteModal from "src/components/SiteModal/DeleteSiteModal";
import SiteForm from "src/components/SiteModal/SiteForm";
import { IsSiteNameValid } from "src/components/SiteModal/SiteModalHelper";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  StatusPageSiteClient,
  StatusPageSiteViewModel,
} from "src/types/auto/types";

interface Props {
  site: StatusPageSiteViewModel;
  setSite: React.Dispatch<React.SetStateAction<StatusPageSiteViewModel>>;
  onChange: () => void;
}

const EditSite = (props: Props): JSX.Element => {
  const [siteNameError, setSiteNameError] = useState(false);
  const [deleteSite, setDeleteSite] = useState(false);
  const [, reRender] = useState(false);

  const setSnackbar = useContext(SetSnackbar);

  return (
    <>
      <Card style={{ width: "100%", padding: "10px", marginTop: 20 }}>
        <CardHeader
          title={
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                marginBottom: 15,
                paddingBottom: 0,
              }}
              variant="h3"
            >
              Status Page Settings
            </Typography>
          }
          subheader={
            <Typography style={{ marginLeft: 10 }}>
              Update the settings for your status page here
            </Typography>
          }
        />
        <Container>
          <SiteForm
            siteName={props.site.name}
            setSiteName={(siteName) => {
              props.setSite((x) => {
                x.name = siteName as string;
                reRender((x) => !x);
                return x;
              });
            }}
            siteNameError={siteNameError}
            setSiteNameError={setSiteNameError}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            onClick={() => {
              // Ensure that the name is valid
              if (!IsSiteNameValid(props.site.name)) {
                setSiteNameError(true);
                return;
              }

              // Update the site on the backend
              new StatusPageSiteClient(Configuration.ServerRoot, FetchOverride)
                .updateSite(props.site)
                .then((x) => {
                  if (x) {
                    props.setSite(x);
                    setSnackbar({
                      message: "Status Page was updated successfully",
                      status: "success",
                    });
                    window.location.reload();
                  } else {
                    setSnackbar({
                      message:
                        "Status Page could not be updated. Please try again or contact support",
                      status: "error",
                    });
                  }
                })
                .catch(() => {
                  setSnackbar({
                    message:
                      "Status Page could not be updated. Please try again or contact support",
                    status: "error",
                  });
                });
            }}
          >
            Update Status Page
          </LoadingButton>
          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h3"
          >
            Delete Status Page
          </Typography>
          <Typography>
            Warning this will delete all of the data for your status page and
            stop all of your tests from running. This action cannot be undone
          </Typography>
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              display: "block",
              width: "100%",
            }}
            variant="contained"
            onClick={() => {
              setDeleteSite(true);
            }}
            color="error"
          >
            Delete Status Page
          </LoadingButton>
        </Container>
      </Card>
      {deleteSite && (
        <DeleteSiteModal
          open={deleteSite}
          onClose={(cancelled) => {
            if (!cancelled) {
              props.onChange();
            }

            setDeleteSite(false);
          }}
          name={props.site.name}
          description={
            "Are you sure that you want to delete the status page " +
            props.site.name +
            "? This action cannot be undone"
          }
          site={props.site}
        />
      )}
    </>
  );
};

export default EditSite;
