import React, { useState } from "react";
import {
  Button,
  Card,
  Box,
  Grid,
  Typography,
  IconButton,
  CardHeader,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { MoreVert, Create, Delete } from "@mui/icons-material";
import StatusIcon from "src/components/UptimeHelpers/StatusIcon";
import { NavLink as RouterLink } from "react-router-dom";
import { SiteStatus, StatusPageSiteViewModel } from "src/types/auto/types";
import SiteModal from "src/components/SiteModal/SiteModal";
import DeleteSiteModal from "src/components/SiteModal/DeleteSiteModal";

interface Props {
  site: StatusPageSiteViewModel;
  onChange: () => void;
}

const SiteStatusCard = (props: Props) => {
  const [editSite, setEditSite] = useState(false);
  const [deleteSite, setDeleteSite] = useState(false);
  const [anchorActionList, setAnchorActionList] = useState<null | HTMLElement>(
    null
  );

  return (
    <>
      <Card style={{ height: "100%" }}>
        <CardHeader
          sx={{
            "& .MuiCardHeader-content": {
              maxWidth: "95%",
              wordWrap: "break-word",
            },
          }}
          action={
            <>
              <IconButton
                aria-label="options"
                onClick={(e) => setAnchorActionList(e.currentTarget)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                open={anchorActionList !== null}
                anchorEl={anchorActionList}
                onClose={() => setAnchorActionList(null)}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorActionList(null);
                    setEditSite(true);
                  }}
                >
                  <ListItemIcon>
                    <Create fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Site</ListItemText>
                </MenuItem>
                <MenuItem
                  style={{ color: "red" }}
                  onClick={() => {
                    setAnchorActionList(null);
                    setDeleteSite(true);
                  }}
                >
                  <ListItemIcon>
                    <Delete fontSize="small" color="error" />
                  </ListItemIcon>
                  <ListItemText>Delete Status Page</ListItemText>
                </MenuItem>
              </Menu>
            </>
          }
          title={
            <Box p={4}>
              <Typography
                sx={{
                  pb: 3,
                }}
                style={{}}
                variant="h2"
              >
                {props.site.name}
              </Typography>
              <Box>
                <Box style={{ display: "inline-block" }}>
                  <StatusIcon status={props.site.siteStatus} isSiteStatus />
                </Box>
                <Typography
                  variant="h3"
                  gutterBottom
                  style={{
                    display: "inline-block",
                    marginLeft: 10,
                  }}
                >
                  {props.site.siteStatus === SiteStatus.TestsPaused
                    ? "No Tests Running On Status Page"
                    : props.site.siteStatus === SiteStatus.Online
                    ? "Online"
                    : "Offline"}
                </Typography>
              </Box>
              <Grid
                container
                spacing={3}
                style={{ marginTop: "1px", justifySelf: "center" }}
              >
                <Grid item style={{ justifySelf: "center", width: "100%" }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    component={RouterLink}
                    to={"/statuspage/" + props.site.id}
                  >
                    Manage Page
                  </Button>
                </Grid>
              </Grid>
            </Box>
          }
        />
      </Card>
      {editSite && (
        <SiteModal
          open={editSite}
          onClose={(cancelled) => {
            if (!cancelled) {
              props.onChange();
            }

            setEditSite(false);
          }}
          siteToEdit={props.site}
        />
      )}
      {deleteSite && (
        <DeleteSiteModal
          open={deleteSite}
          onClose={(cancelled) => {
            if (!cancelled) {
              props.onChange();
            }

            setDeleteSite(false);
          }}
          name={props.site.name}
          description={
            "Are you sure that you want to delete the status page " +
            props.site.name +
            "? This action cannot be undone"
          }
          site={props.site}
        />
      )}
    </>
  );
};

export default SiteStatusCard;
