import React, { useContext, useEffect, useState } from "react";
import {
  StatusPageSiteViewModel,
  StatusPageClient,
  StatusPageOptionsViewModel,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import {
  Card,
  CardHeader,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { LoadingButton } from "@mui/lab";

interface Props {
  site: StatusPageSiteViewModel;
}

export default function UpdateStatusPageHTML(props: Props) {
  const setSnackbar = useContext(SetSnackbar);
  const [data, setData] = useState<StatusPageOptionsViewModel>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    new StatusPageClient(Configuration.ServerRoot, FetchOverride)
      .getStatusPageForSiteId(props.site.id)
      .then(setData)
      .catch(() => {
        setSnackbar({
          message:
            "Could not retrieve current status page. Please try again or contact support",
          status: "error",
        });
      });
  }, []);

  const updateData = (
    updateFunction: (
      o: StatusPageOptionsViewModel
    ) => StatusPageOptionsViewModel
  ) => {
    setLoading(true);

    // Update the current data
    const newData = updateFunction(data);

    // Send the update
    new StatusPageClient(Configuration.ServerRoot, FetchOverride)
      .updateStatusPage(newData)
      .then(setData)
      .catch(() =>
        setSnackbar({
          message:
            "Could not update this value. Please try again or contact support",
          status: "error",
        })
      )
      .finally(() => setLoading(false));
  };

  if (data === undefined) {
    return <CircularProgress />;
  }

  return (
    <>
      <Card style={{ width: "100%", padding: "10px", marginTop: 20 }}>
        <CardHeader
          title={
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                marginBottom: 15,
                paddingBottom: 0,
              }}
              variant="h3"
            >
              Custom HTML
            </Typography>
          }
          subheader={
            <Typography style={{ marginLeft: 10 }}>
              Add custom HTML, CSS and JavaScript components to your status page
            </Typography>
          }
        />
        <Container
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "20px",
          }}
        >
          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            Custom CSS
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={5}
            id="customCSS"
            defaultValue={data.customCSS}
            placeholder={`body{
  color: red;
}`}
            InputLabelProps={{ shrink: true }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              updateData((d) => {
                d.customCSS = (
                  document.getElementById("customCSS") as HTMLInputElement
                ).value;
                return d;
              });
            }}
          >
            Save
          </LoadingButton>

          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            In HTML Head Tag
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={5}
            id="htmlHead"
            defaultValue={data.htmlHeadTag}
            placeholder={`Add your CSS imports here
<link id="dynamic-favicon" rel="shortcut icon" href="<CSS-Location>">`}
            InputLabelProps={{ shrink: true }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              updateData((d) => {
                d.htmlHeadTag = (
                  document.getElementById("htmlHead") as HTMLInputElement
                ).value;
                return d;
              });
            }}
          >
            Save
          </LoadingButton>

          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            Above Header
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={5}
            id="aboveHeader"
            defaultValue={data.htmlAboveHeader}
            placeholder={`Add your custom HTML here
<div>
  <p>Hello World</p>
</div>`}
            InputLabelProps={{ shrink: true }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              updateData((d) => {
                d.htmlAboveHeader = (
                  document.getElementById("aboveHeader") as HTMLInputElement
                ).value;
                return d;
              });
            }}
          >
            Save
          </LoadingButton>

          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            Below Header
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={5}
            id="belowHeader"
            defaultValue={data.htmlBelowHeader}
            placeholder={`Add your custom HTML here
<div>
  <p>Hello World</p>
</div>`}
            InputLabelProps={{ shrink: true }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              updateData((d) => {
                d.htmlBelowHeader = (
                  document.getElementById("belowHeader") as HTMLInputElement
                ).value;
                return d;
              });
            }}
          >
            Save
          </LoadingButton>

          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            Above Body
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={5}
            id="aboveBody"
            defaultValue={data.htmlAboveBody}
            placeholder={`Add your custom HTML here
<div>
  <p>Hello World</p>
</div>`}
            InputLabelProps={{ shrink: true }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              updateData((d) => {
                d.htmlAboveBody = (
                  document.getElementById("aboveBody") as HTMLInputElement
                ).value;
                return d;
              });
            }}
          >
            Save
          </LoadingButton>

          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            Below Body
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={5}
            id="belowBody"
            defaultValue={data.htmlBelowBody}
            placeholder={`Add your custom HTML here
<div>
  <p>Hello World</p>
</div>`}
            InputLabelProps={{ shrink: true }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              updateData((d) => {
                d.htmlBelowBody = (
                  document.getElementById("belowBody") as HTMLInputElement
                ).value;
                return d;
              });
            }}
          >
            Save
          </LoadingButton>

          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            Below Events
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={5}
            id="belowEvents"
            defaultValue={data.htmlBelowEvents}
            placeholder={`Add your custom HTML here
<div>
  <p>Hello World</p>
</div>`}
            InputLabelProps={{ shrink: true }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              updateData((d) => {
                d.htmlBelowEvents = (
                  document.getElementById("belowEvents") as HTMLInputElement
                ).value;
                return d;
              });
            }}
          >
            Save
          </LoadingButton>

          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h4"
          >
            Below Footer
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={5}
            id="belowFooter"
            defaultValue={data.htmlBelowFooter}
            placeholder={`Add your custom HTML here
<div>
  <p>Hello World</p>
</div>`}
            InputLabelProps={{ shrink: true }}
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              updateData((d) => {
                d.htmlBelowFooter = (
                  document.getElementById("belowFooter") as HTMLInputElement
                ).value;
                return d;
              });
            }}
          >
            Save
          </LoadingButton>
        </Container>
      </Card>
    </>
  );
}
