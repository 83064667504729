import React from "react";
import Markdown from "markdown-to-jsx";
import { Card, Container } from "@mui/material";
import { termsOfService } from "./TermsOfService";
import { cancellationPolicy } from "./CancellationPolicy";
import { copyright } from "./Copyright";
import { privacy } from "./PrivacyPolicy";
import { refunds } from "./Refunds";
import { gdpr } from "./GDPR";
import CookiePolicy from "./CookiePolicy";

export enum TermsType {
  TermsOfService,
  CancellationPolicy,
  Copyright,
  PrivacyPolicy,
  Refunds,
  GDPR,
  CookiePolicy,
}

interface Props {
  termsType: TermsType;
}

const TermsContainer = (props: Props): JSX.Element => {
  if (props.termsType === TermsType.CookiePolicy) {
    return (
      <Card style={{ width: "90%", marginLeft: "5%", marginTop: 20 }}>
        <Container style={{ padding: 0, margin: 40 }}>
          <CookiePolicy />
        </Container>
      </Card>
    );
  }

  return (
    <Card style={{ width: "90%", marginLeft: "5%", marginTop: 20 }}>
      <Container style={{ padding: 0, margin: 40 }}>
        <Markdown>
          {props.termsType === TermsType.TermsOfService
            ? termsOfService
            : props.termsType === TermsType.CancellationPolicy
            ? cancellationPolicy
            : props.termsType === TermsType.Copyright
            ? copyright
            : props.termsType === TermsType.PrivacyPolicy
            ? privacy
            : props.termsType === TermsType.Refunds
            ? refunds
            : props.termsType === TermsType.GDPR
            ? gdpr
            : ""}
        </Markdown>
      </Container>
    </Card>
  );
};

export default TermsContainer;
