import React, { FC, ReactNode, useContext, useEffect } from "react";
import { Box, alpha, lighten, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import { OrganisationDispatchContext } from "src/contexts/UserStorage";
import { OrganisationClient } from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import Footer from "src/components/Footer/Footer";

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = (props: SidebarLayoutProps) => {
  const theme = useTheme();
  const setOrganisation = useContext(OrganisationDispatchContext);

  useEffect(() => {
    new OrganisationClient(Configuration.ServerRoot, FetchOverride)
      .getOrganisation()
      .then((x) => setOrganisation(x));
  }, []);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: "100%",

          ".MuiPageTitle-wrapper": {
            background:
              theme.palette.mode === "dark"
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === "dark"
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`,
          },
        }}
      >
        <Sidebar />
        <Header />
        <Box
          sx={{
            position: "relative",
            zIndex: 5,
            display: "block",
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up("lg")]: {
              ml: `${theme.sidebar.width}`,
            },
            minHeight: "93vh",
          }}
        >
          <Box style={{ paddingBottom: "2.5rem" }}>
            {props.children ? props.children : <Outlet />}
          </Box>
          <Box
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "2.5rem",
            }}
          >
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
