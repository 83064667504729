import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Footer from "src/components/Footer/Footer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useContext, useState } from "react";
import { IsEmailValid } from "./SignUpHelper";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { UserClient } from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { LoadingButton } from "@mui/lab";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const setSnackbar = useContext(SetSnackbar);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Check for validation errors
    const emailVal = IsEmailValid(email);

    if (!emailVal) {
      setEmailValid(emailVal);
      return;
    }

    setLoading(true);

    // Sign up
    new UserClient(Configuration.ServerRoot, FetchOverride)
      .generatePasswordResetEmail(email)
      .then(() => {
        setSnackbar({
          message:
            "An email has been sent to the provided email address if it exists. Please follow the attached link to reset the password",
          status: "success",
        });

        location.replace("/login");
      })
      .catch(() =>
        setSnackbar({
          message:
            "An unknown error occurred. Please try again or contact support",
          status: "error",
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password Reset
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!emailValid}
            helperText={!emailValid && "Please enter a valid email address"}
            onBlur={() => setEmailValid(IsEmailValid(email))}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            style={{ color: "white" }}
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            Send Reset Password
          </LoadingButton>
          <Grid container>
            <Grid item>
              <Link href="login" variant="body2">
                Return to Login
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
}
