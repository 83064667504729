import * as React from "react";
import {
  StatusPageClient,
  StatusPageDataViewModel,
  StatusPageSiteClient,
  StatusPageSiteViewModel,
} from "src/types/auto/types";
import { LoadingButton } from "@mui/lab";
import { useContext, useState } from "react";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { Typography } from "@mui/material";

interface Props {
  data: StatusPageDataViewModel;
  update: boolean;
}

export const UrlRegex = "^[A-Za-z0-9]*$";

const CreateStatusPageButton = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const setSnackbar = useContext(SetSnackbar);

  return (
    <>
      <LoadingButton
        fullWidth
        style={{ color: "white" }}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={loading}
        onClick={() => {
          setLoading(true);

          if (props.update) {
            new StatusPageClient(Configuration.ServerRoot, FetchOverride)
              .updateStatusPage(props.data.settings)
              .then((x) => {
                if (x) {
                  setSnackbar({
                    message: "Status Page Updated",
                    status: "success",
                  });
                  setLoading(false);

                  location.reload();

                  return;
                }
                setSnackbar({
                  message:
                    "Could not update the status page. Make sure your page name is unique and try again",
                  status: "error",
                });
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
                setSnackbar({
                  message:
                    "Could not update the status page. Make sure your page name is unique and try again",
                  status: "error",
                });
              });
          } else {
            const createStatusPage = (data, redirectUrl) =>
              new StatusPageClient(Configuration.ServerRoot, FetchOverride)
                .createStatusPage(data.settings)
                .then((x) => {
                  if (x) {
                    setSnackbar({
                      message: "Status Page Created",
                      status: "success",
                    });
                    setLoading(false);

                    location.href = redirectUrl;

                    return;
                  }
                  setSnackbar({
                    message:
                      "Could not create the status page. Make sure your page name is unique and try again",
                    status: "error",
                  });
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                  setSnackbar({
                    message:
                      "Could not create the status page. Make sure your page name is unique and try again",
                    status: "error",
                  });
                });

            if (props.data.settings.statusPageSiteId === undefined) {
              new StatusPageSiteClient(Configuration.ServerRoot, FetchOverride)
                .createSite({
                  name: props.data.settings.name,
                } as StatusPageSiteViewModel)
                .then((x) => {
                  const data = props.data;
                  data.settings.statusPageSiteId = x.id;
                  createStatusPage(data, "/signup/createTests?id=" + x.id);
                })
                .catch(() => {
                  setLoading(false);
                  setSnackbar({
                    message:
                      "Could not create the status page. Make sure your page name is unique and try again",
                    status: "error",
                  });
                });
            } else {
              // If the site already exists, create the status page data
              createStatusPage(
                props.data,
                "/statuspage/" + props.data.settings.statusPageSiteId
              );
            }
          }
        }}
      >
        {props.update ? "Update" : "Create"} Status Page
      </LoadingButton>
      <Typography>
        Please allow a few minutes for{" "}
        {props.update ? "the changes" : "the status page"} that you&apos;ve made
        to become available after pressing the{" "}
        {props.update ? "Update" : "Create"} button
      </Typography>
    </>
  );
};

export default CreateStatusPageButton;
