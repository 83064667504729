import React, { useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import reactCSS from "reactcss";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  label: string;
  fileName: string | undefined;
  accept?: string;
  onChange: (file: File | undefined) => void;
}

const ImageUpload = (props: Props): JSX.Element => {
  const hiddenFileInput = React.useRef(null);
  const [uploadedFile, setUploadedFile] = useState<File>();

  const styles = reactCSS({
    default: {
      swatch: {
        padding: "5px",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        gap: "10",
        alignItems: "center",
        width: "auto",
        height: "50px",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
      clearButton: {
        marginLeft: "auto",
        color: "red",
      },
    },
  });

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length < 0) {
      return;
    }
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size > 2097152) {
      setUploadedFile(undefined);
      props.onChange(undefined);
      return;
    }
    setUploadedFile(fileUploaded);
    props.onChange(fileUploaded);
  };
  return (
    <div style={{ width: "100%" }}>
      <Typography
        style={{
          width: "100%",
          textAlign: "left",
          marginLeft: "10px",
          color: "grey",
        }}
      >
        {props.label}
      </Typography>
      <div style={styles.swatch} onClick={handleClick}>
        <UploadIcon style={{ marginLeft: 5 }} />
        <Typography style={{ marginLeft: 15 }}>
          {uploadedFile
            ? uploadedFile.name
            : props.fileName
            ? props.fileName
            : "Upload a File"}
        </Typography>
        {(props.fileName || uploadedFile) && (
          <Tooltip title="Remove Image">
            <CloseIcon
              style={styles.clearButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setUploadedFile(undefined);
                props.onChange(undefined);
              }}
            />
          </Tooltip>
        )}
        <input
          type="file"
          accept={props.accept}
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};
export default ImageUpload;
