import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => Promise<undefined>;
  actionName: string;
  name: string;
  description: string;
  buttonColor:
    | "inherit"
    | "error"
    | "success"
    | "info"
    | "warning"
    | "primary"
    | "secondary";
}

const ConfirmModal = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={() => props.onClose(true)}>
      <DialogTitle>
        {props.actionName} {props.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => props.onClose(true)}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          color={props.buttonColor}
          onClick={() => {
            setLoading(true);
            props.onClose(false).then(() => setLoading(false));
          }}
        >
          {props.actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
