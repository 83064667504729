import { MoreVert, Create, Delete } from "@mui/icons-material";
import {
  Card,
  Box,
  Typography,
  useTheme,
  IconButton,
  CardHeader,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Container,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import {
  EventStatus,
  EventViewModel,
  StatusPageOptionsViewModel,
  TestViewModel,
} from "src/types/auto/types";
import ImpactsChip from "./ImpactsChip";

interface Props {
  event: EventViewModel;
  onEditPressed?: (event: EventViewModel) => void;
  onDeletePressed?: (event: EventViewModel) => void;
  tests?: TestViewModel[];
  settings?: StatusPageOptionsViewModel;
}

const EventCard = (props: Props) => {
  const theme = useTheme();

  const [anchorActionList, setAnchorActionList] = useState<null | HTMLElement>(
    null
  );

  return (
    <Card
      style={{ backgroundColor: props.settings?.eventCardBackgroundColour }}
    >
      <CardHeader
        style={{ paddingBottom: 0, marginBottom: 0 }}
        sx={{
          "& .MuiCardHeader-content": {
            maxWidth: "95%",
            wordWrap: "break-word",
          },
        }}
        action={
          props.onEditPressed &&
          props.onDeletePressed && (
            <>
              <IconButton
                aria-label="options"
                onClick={(e) => setAnchorActionList(e.currentTarget)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                open={anchorActionList !== null}
                anchorEl={anchorActionList}
                onClose={() => setAnchorActionList(null)}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorActionList(null);
                    props.onEditPressed(props.event);
                  }}
                >
                  <ListItemIcon>
                    <Create fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem
                  style={{ color: "red" }}
                  onClick={() => {
                    setAnchorActionList(null);
                    props.onDeletePressed(props.event);
                  }}
                >
                  <ListItemIcon>
                    <Delete fontSize="small" color="error" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )
        }
        title={
          <Box
            style={{
              display: "grid",
              columnGap: 10,
              rowGap: 0,
              minHeight: 10,
              gridTemplateColumns: "min-content auto",
              gridTemplateAreas: `
          'chip title'
          'chip created'
         `,
            }}
          >
            <Chip
              label={EventStatus[props.event.status]}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                gridArea: "chip",
                color: props.settings?.statusChipFontColour,
                backgroundColor: props.settings?.statusChipBackgroundColour,
              }}
            />
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                paddingBottom: 0,
                gridArea: "title",
                wordWrap: "break-word",
                color: props.settings?.eventCardFontColour,
              }}
              variant="h3"
            >
              {props.event?.title}
              {props.tests
                ?.filter((x) => props.event?.testIds?.includes(x.id))
                ?.map((x) => (
                  <ImpactsChip key={x.id} test={x} settings={props.settings} />
                ))}
            </Typography>
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                color: theme.palette.text.secondary,
                marginLeft: 2,
                gridArea: "created",
                paddingBottom: 0,
                wordWrap: "break-word",
              }}
              variant="h6"
              fontSize={13}
            >
              at{" "}
              {moment(props.event.created).format(
                props.settings?.eventCardDateLocalisation || "HH:mm DD/MM/YYYY"
              )}
            </Typography>
          </Box>
        }
        disableTypography
      />
      <Container style={{ marginTop: 3 }}>
        <Typography
          sx={{
            pb: 3,
          }}
          style={{
            whiteSpace: "pre-line",
            color:
              props.settings?.eventCardFontColour || theme.palette.text.primary,
          }}
          variant="h5"
        >
          {props.event.description}
        </Typography>
      </Container>
    </Card>
  );
};

export default EventCard;
