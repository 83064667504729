import React, { useContext } from "react";
import {
  AuthenticationClient,
  OrganisationClient,
  UserViewModel,
} from "src/types/auto/types";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import { UserDispatchContext } from "src/contexts/UserStorage";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
}

const DeleteOrganisationModal = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);
  const setUserContext = useContext(UserDispatchContext);

  return (
    <ConfirmModal
      open={props.open}
      onClose={async (cancelled: boolean): Promise<undefined> => {
        if (cancelled) {
          props.onClose(true);
          return;
        }
        props.onClose(false);
        return await new OrganisationClient(
          Configuration.ServerRoot,
          FetchOverride
        )
          .deleteOrganisation()
          .then((x) => {
            if (x) {
              // The site was deleted
              setSnackbar({
                message: "The organisation was deleted",
                status: "success",
              });
              new AuthenticationClient(Configuration.ServerRoot, FetchOverride)
                .logout()
                .then(() =>
                  setUserContext({ email: undefined } as UserViewModel)
                )
                .catch(() =>
                  setSnackbar({ message: "Could not log out", status: "error" })
                );
            } else {
              setSnackbar({
                message:
                  "The organisation could not be deleted. Please try again",
                status: "error",
              });
            }

            return undefined;
          })
          .catch(() => {
            setSnackbar({
              message:
                "The organisation could not be deleted. Please try again",
              status: "error",
            });
          });
      }}
      actionName="Delete"
      name=" Your Organisation"
      description="Are you sure that you want to delete your organisation? All of your tests, user accounts and status pages (if relevant) will be deleted. This action cannot be undone"
      buttonColor="error"
    />
  );
};

export default DeleteOrganisationModal;
