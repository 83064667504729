import React from "react";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

interface Props {
  height?: number;
  hideNavigation?: boolean;
}

function Logo(props: Props) {
  if (props.hideNavigation) {
    return (
      <img
        alt="logo"
        height={props.height || 80}
        src="/static/images/logo/Logo.png"
      />
    );
  }

  return (
    <LogoWrapper to="/" style={{ display: "flex", justifyContent: "center" }}>
      <img
        alt="logo"
        height={props.height || 80}
        src="/static/images/logo/Logo.png"
      />
    </LogoWrapper>
  );
}

export default Logo;
