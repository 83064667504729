import React from "react";
import { Box, Typography } from "@mui/material";
import { StatusPageDataViewModel, TestViewModel } from "src/types/auto/types";
import EventsList from "../../../components/StatusPageComponents/EventCard/EventsList";
import { ContentContainer } from "./StatusPageDisplay";

interface Props {
  data: StatusPageDataViewModel;
}

const EventsDisplay = (props: Props): JSX.Element => {
  return (
    <>
      <Box
        style={{
          backgroundColor: props.data.settings.pageBodyColour,
          margin: 0,
          padding: 0,
          width: "100%",
        }}
      >
        <ContentContainer
          style={{
            marginBottom: "50px",
          }}
        >
          <Typography
            variant="h2"
            style={{ color: props.data.settings.pageBodyPrimaryFontColour }}
          >
            Events
          </Typography>
          <EventsList
            settings={props.data.settings}
            events={props.data.siteData
              .map((x) => x.events)
              .flat()
              .filter((x) => x.id)
              .sort((a, b) => b.created.getTime() - a.created.getTime())}
            tests={props.data.siteData.map(
              (x) => ({ id: x.testId, name: x.siteName } as TestViewModel)
            )}
          />
        </ContentContainer>
      </Box>
    </>
  );
};

export default EventsDisplay;
