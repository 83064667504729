import * as React from "react";
import {
  OrganisationViewModel,
  StatusPageDataViewModel,
} from "src/types/auto/types";
import { TextField } from "@mui/material";
import ColourPicker from "src/components/ColourPicker/ColourPicker";
import { useEffect, useState } from "react";
import { ConfigBox, ConfigTypography } from "./AdvancedStatusPageConfig";
import CreateStatusPageButton from "./CreateStatusPageButton";
import ImageUpload from "../ImageUpload/ImageUpload";
import StatusPageUrlInput from "./StatusPageUrlInput";

interface Props {
  data: StatusPageDataViewModel;
  setData: React.Dispatch<React.SetStateAction<StatusPageDataViewModel>>;
  reRender: React.Dispatch<React.SetStateAction<number>>;
  update?: boolean;
  organisation?: OrganisationViewModel;
}

const BasicStatusPageConfig = (props: Props): JSX.Element => {
  const [siteNameValue, setSiteNameValue] = useState("");
  const [landingPageUrl, setLandingPageUrl] = useState("");

  useEffect(() => {
    if (props.data?.settings) {
      setSiteNameValue(props.data.settings.name);
      setLandingPageUrl(props.data.settings.landingPageUrl);
    }
  }, [props.data]);

  if (props.data?.settings === undefined) {
    return <></>;
  }

  return (
    <>
      <ConfigTypography variant="h3">Header</ConfigTypography>
      <ConfigBox style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          label="Page Name"
          style={{ marginTop: 10, width: "100%" }}
          value={siteNameValue}
          onChange={(e) => {
            setSiteNameValue(e.target.value);
          }}
          onBlur={(e) =>
            props.setData((y) => {
              y.settings.name = e.target.value;
              y.settings.title = e.target.value;
              props.reRender((x) => x + 1);
              return y;
            })
          }
        />
        <TextField
          label="Landing Page URL"
          style={{ marginTop: 10, width: "100%" }}
          value={landingPageUrl}
          onChange={(e) => {
            setLandingPageUrl(e.target.value);
          }}
          onBlur={(e) =>
            props.setData((y) => {
              y.settings.landingPageUrl = e.target.value;
              props.reRender((x) => x + 1);
              return y;
            })
          }
        />
        <StatusPageUrlInput
          data={props.data}
          setData={props.setData}
          reRender={props.reRender}
          organisation={props.organisation}
        />
      </ConfigBox>
      <ConfigBox>
        <ImageUpload
          label="Favicon (.ico file)"
          fileName={props.data.settings.iconFileName}
          accept="image/x-icon, image/vnd.microsoft.icon"
          onChange={(file) => {
            if (file === undefined) {
              props.setData((y) => {
                y.settings.icon = undefined;
                y.settings.iconFileName = undefined;
                props.reRender((x) => x + 1);
                return y;
              });
            }

            const reader = new FileReader();
            reader.onload = function (e) {
              const contents = e.target.result;

              props.setData((y) => {
                y.settings.icon = contents as string;
                y.settings.iconFileName = file.name;
                props.reRender((x) => x + 1);
                return y;
              });
              return;
            };
            reader.readAsDataURL(file);
          }}
        />
        <ImageUpload
          label="Logo"
          fileName={props.data.settings.logoFileName}
          accept="image/png, image/jpeg"
          onChange={(file) => {
            if (file === undefined) {
              props.setData((y) => {
                y.settings.logo = undefined;
                y.settings.logoFileName = undefined;
                props.reRender((x) => x + 1);
                return y;
              });
              return;
            }

            const reader = new FileReader();
            reader.onload = function (e) {
              const contents = e.target.result;

              props.setData((y) => {
                y.settings.logo = contents as string;
                y.settings.logoFileName = file.name;
                props.reRender((x) => x + 1);
                return y;
              });
            };
            reader.readAsDataURL(file);
          }}
        />
      </ConfigBox>
      <ConfigBox>
        <ColourPicker
          label="Font Colour"
          colour={props.data.settings.headerFontColour}
          setColour={(x) =>
            props.setData((y) => {
              y.settings.headerFontColour = x as string;
              y.settings.buttonFontColour = x as string;
              props.reRender((x) => x + 1);
              return y;
            })
          }
        />
        <ColourPicker
          label="Background Colour"
          colour={props.data.settings.headerColour1}
          setColour={(x) =>
            props.setData((y) => {
              y.settings.headerColour1 = x as string;
              y.settings.headerColour2 = x as string;
              y.settings.buttonBackgroundColour = "rgba(0, 0, 0, 0)";
              props.reRender((x) => x + 1);
              return y;
            })
          }
        />
      </ConfigBox>
      <ConfigTypography variant="h3">Body</ConfigTypography>
      <ConfigBox>
        <ColourPicker
          label="Primary Font Colour"
          colour={props.data.settings.pageBodyPrimaryFontColour}
          setColour={(x) =>
            props.setData((y) => {
              y.settings.pageBodyPrimaryFontColour = x as string;
              y.settings.eventCardFontColour = x as string;
              props.reRender((x) => x + 1);
              return y;
            })
          }
        />
        <ColourPicker
          label="Secondary Font Colour"
          colour={props.data.settings.pageBodySecondaryFontColour}
          setColour={(x) =>
            props.setData((y) => {
              y.settings.pageBodySecondaryFontColour = x as string;
              y.settings.modalFontColour = x as string;
              props.reRender((x) => x + 1);
              return y;
            })
          }
        />
      </ConfigBox>
      <ConfigBox>
        <ColourPicker
          label="Background Colour"
          colour={props.data.settings.pageBodyColour}
          setColour={(x) =>
            props.setData((y) => {
              y.settings.pageBodyColour = x as string;
              y.settings.modalColour = x as string;
              y.settings.eventCardBackgroundColour = x as string;
              props.reRender((x) => x + 1);
              return y;
            })
          }
        />
      </ConfigBox>
      <CreateStatusPageButton data={props.data} update={props.update} />
    </>
  );
};

export default BasicStatusPageConfig;
