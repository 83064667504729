import React from "react";
import { Box, styled, Typography, Divider } from "@mui/material";

interface Props {
  active: boolean;
  currentTab: StatusPageNavOptions;
  setCurrentTab: React.Dispatch<React.SetStateAction<StatusPageNavOptions>>;
}

const NavBarOption = styled(Typography)(
  () => `
    display: inline-block;
    position: relative;
    font-weight: bold;
    text-decoration-line: underline;
    text-decoration: text-decoration-line;
    text-underline-offset: 3px;
    text-decoration-thickness: 3px;
    cursor: pointer;
  
  ::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f8a418;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  `
);

export enum StatusPageNavOptions {
  Design,
  HTML,
  Localisation,
  Embed,
}

const StatusPageCustomiseNavBar = (props: Props) => {
  return (
    <>
      <Divider
        light
        variant="middle"
        style={{ display: props.active ? undefined : "none" }}
      />
      <Box
        ml={7}
        mt={2}
        mb={props.active ? 2 : undefined}
        style={{
          maxHeight: props.active ? "100px" : "0px",
          // transition: "max-height 1s ease-in", This is really laggy, so we'll leave the transition for now
          display: "flex",
          flexDirection: "row",
          gap: 50,
        }}
      >
        <NavBarOption
          style={{
            textDecorationColor:
              props.currentTab === StatusPageNavOptions.Design
                ? "#f8a418"
                : "rgba(0, 0, 0, 0)",
          }}
          onClick={() => props.setCurrentTab(StatusPageNavOptions.Design)}
        >
          Design
        </NavBarOption>
        <NavBarOption
          style={{
            textDecorationColor:
              props.currentTab === StatusPageNavOptions.HTML
                ? "#f8a418"
                : "rgba(0, 0, 0, 0)",
          }}
          onClick={() => props.setCurrentTab(StatusPageNavOptions.HTML)}
        >
          HTML
        </NavBarOption>
        <NavBarOption
          style={{
            textDecorationColor:
              props.currentTab === StatusPageNavOptions.Localisation
                ? "#f8a418"
                : "rgba(0, 0, 0, 0)",
          }}
          onClick={() => props.setCurrentTab(StatusPageNavOptions.Localisation)}
        >
          Localisation
        </NavBarOption>
        <NavBarOption
          style={{
            textDecorationColor:
              props.currentTab === StatusPageNavOptions.Embed
                ? "#f8a418"
                : "rgba(0, 0, 0, 0)",
          }}
          onClick={() => props.setCurrentTab(StatusPageNavOptions.Embed)}
        >
          Analytics & Search
        </NavBarOption>
      </Box>
    </>
  );
};

export default StatusPageCustomiseNavBar;
