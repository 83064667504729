import React, { useContext, useState } from "react";
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";
import BrightnessLowTwoToneIcon from "@mui/icons-material/BrightnessLowTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import CreditCardTwoToneIcon from "@mui/icons-material/CreditCardTwoTone";
import StatusIcon from "src/components/UptimeHelpers/StatusIcon";
import {
  PaymentClient,
  Role,
  SiteStatus,
  Subscription,
} from "src/types/auto/types";
import { Add } from "@mui/icons-material";
import SiteModal from "src/components/SiteModal/SiteModal";
import { OrganisationContext, UserContext } from "src/contexts/UserStorage";
import { RefreshSitesContext, SitesContext } from "src/contexts/SitesContext";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const user = useContext(UserContext);
  const organisation = useContext(OrganisationContext);
  const { closeSidebar } = useContext(SidebarContext);
  const refreshSites = useContext(RefreshSitesContext);
  const sites = useContext(SitesContext);
  const setSnackbar = useContext(SetSnackbar);

  const [openCreateSite, setOpenCreateSite] = useState(false);

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/"
                  startIcon={
                    <StatusIcon
                      status={
                        sites.every(
                          (x) => x.siteStatus === SiteStatus.TestsPaused
                        )
                          ? SiteStatus.TestsPaused
                          : sites.every(
                              (x) =>
                                x.siteStatus === SiteStatus.Online ||
                                x.siteStatus === SiteStatus.TestsPaused
                            )
                          ? SiteStatus.Online
                          : SiteStatus.Offline
                      }
                      isSiteStatus
                    />
                  }
                >
                  Status Pages Overview
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Status Pages
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {sites.map((x) => (
                <ListItem component="div" key={"menubar-site" + x.name}>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={"/statuspage/" + x.id}
                    startIcon={
                      <StatusIcon status={x.siteStatus} isSiteStatus />
                    }
                  >
                    {x.name}
                  </Button>
                </ListItem>
              ))}
              <ListItem component="div" key={"menubar-site-add-new-site"}>
                <Button
                  disableRipple
                  component={RouterLink}
                  to="/createStatusPage"
                  startIcon={<Add />}
                >
                  Create New Status Page
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        {user.role === Role.OrganisationAdmin && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Settings
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/settings/accounts"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Accounts
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/settings/organisationSettings"
                    startIcon={<BrightnessLowTwoToneIcon />}
                  >
                    Organisation Settings
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    onClick={() => {
                      closeSidebar();

                      // If we are paying for a plan, use stripe dashboard
                      if (
                        organisation.subscriptionType ===
                          Subscription.Advanced ||
                        organisation.subscriptionType === Subscription.Ultimate
                      ) {
                        new PaymentClient(
                          Configuration.ServerRoot,
                          FetchOverride
                        )
                          .createPortal()
                          .then((url) => {
                            if (url) {
                              location.href = url;
                            } else {
                              setSnackbar({
                                message:
                                  "Could not load payment management portal. Please re-log and try again or contact support",
                                status: "error",
                              });
                            }
                          })
                          .catch(() => {
                            setSnackbar({
                              message:
                                "Could not load payment management portal. Please re-log and try again or contact support",
                              status: "error",
                            });
                          });
                      } else {
                        // Else redirect to the sign up payment page
                        location.href = "signup/payment";
                      }
                    }}
                    startIcon={<CreditCardTwoToneIcon />}
                  >
                    {organisation.subscriptionType === Subscription.Free
                      ? "Create Subscription"
                      : "Manage Subscription"}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        )}
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Contact
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/contact"
                  startIcon={<ChatOutlinedIcon />}
                >
                  Get In Contact
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
      <SiteModal
        open={openCreateSite}
        onClose={(cancelled) => {
          setOpenCreateSite(false);

          if (!cancelled) {
            setTimeout(refreshSites, 1000);
          }
        }}
      />
    </>
  );
}

export default SidebarMenu;
