import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import StatusChip from "./StatusChip";
import StatusIcon from "src/components/UptimeHelpers/StatusIcon";
import {
  StatusGraphType,
  StatusPageSiteDataViewModel,
  StatusPageOptionsViewModel,
} from "src/types/auto/types";
import {
  calculateUptimePercentage,
  createStatusIconValues,
  DataByDate,
} from "../StatusDataCalculations";

interface Props {
  data: StatusPageSiteDataViewModel;
  settings: StatusPageOptionsViewModel;
}

const StatusChipsContainer = (props: Props): JSX.Element => {
  const [maxDataPoints, setMaxDataPoints] = useState(90);
  const [dataPoints, setDataPoints] = useState<DataByDate[]>([]);

  useEffect(() => {
    const onResize = () => {
      let mDP = 90;
      if (window.innerWidth < 650) {
        mDP = 30;
      } else if (window.innerWidth < 1200) {
        mDP = 60;
      }

      setMaxDataPoints(mDP);
    };
    onResize();

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    setDataPoints(createStatusIconValues(props.data.events, maxDataPoints));
  }, [props.data, maxDataPoints]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "normal",
          }}
        >
          <Box style={{ display: "inline-block", paddingTop: "3px" }}>
            <StatusIcon
              eventStatus={props.data.currentStatus}
              pixelSize={15}
              onlineColour={props.settings.onlineColour}
              offlineColour={props.settings.downColour}
              maintenanceColour={props.settings.maintenanceColour}
              modalColour={props.settings.modalColour}
              modalFontColour={props.settings.modalFontColour}
            />
          </Box>
          <Typography
            component="h3"
            style={{
              color: props.settings.pageBodyPrimaryFontColour,
              fontSize: "20px",
            }}
          >
            {props.data.siteName}
          </Typography>
        </Box>
        <Typography
          style={{ color: props.settings.pageBodySecondaryFontColour }}
        >
          Uptime: {Math.round(calculateUptimePercentage(dataPoints) * 10) / 10}%
        </Typography>
      </Box>
      {props.settings.statusGraphType === StatusGraphType.Blocks && (
        <Box style={{ display: "flex", flexDirection: "row", gap: 1 }}>
          {dataPoints.map((x) => (
            <StatusChip
              key={x.date.toString()}
              settings={props.settings}
              data={x}
              events={x.events?.filter(
                (e) =>
                  e.testIds?.includes(props.data.testId) &&
                  e.created?.toDateString() === x.date?.toDateString()
              )}
            />
          ))}
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Typography
          style={{ color: props.settings.pageBodySecondaryFontColour }}
        >
          {maxDataPoints} days ago
        </Typography>
        <Typography
          style={{ color: props.settings.pageBodySecondaryFontColour }}
        >
          Today
        </Typography>
      </Box>
    </>
  );
};

export default StatusChipsContainer;
