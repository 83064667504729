export const gdpr = `
# GDPR POLICY
The EU General Data Protection Regulation (GDPR) went into effect on May 25, 2018. Status Hive is ready.

## Does GDPR affect me?
If you're based in the EU or do business in the EU, yeah! GDPR has a long reach. If you have any EU personal data in your Status Hive account, such as names, email addresses, ID numbers, or… anything personally identifiable, then GDPR applies. You are a Controller of personal data under GDPR, so you need to enter into GDPR-compliant data processing agreements with any online services and third party vendors you rely on, including Status Hive. These agreements are commonly called a Data Processing Addendum, or DPA.

## Data Processing Addendum
Contracts required! Processing EU personal data must be governed by a GDPR-compliant contract. We provide a standard Data Processing Addendum (DPA) to extend GDPR privacy principles, rights, and obligations everywhere personal data is processed. 

## Subprocessors
Status Hive uses third party subprocessors, such as cloud computing providers and customer support software, to provide our services. We enter into GDPR-compliant data processing agreements with each subprocessor, and require the same of them.

Adapted from the [Basecamp open-source policies / CC BY 4.0](https://github.com/basecamp/policies)`;
