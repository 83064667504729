export const IsTextValid = (name: string) => name.replace(" ", "").length !== 0;

export const IsValidImpactedTests = (impactedTests: string[]) => {
  return impactedTests.length > 0;
};

export const ValidateAllResults = (
  eventTitle: string,
  eventDescription: string,
  impactedTests: string[],
  setEventTitleError: React.Dispatch<React.SetStateAction<boolean>>,
  setEventDescriptionError: React.Dispatch<React.SetStateAction<boolean>>,
  setTestsImpactedError: React.Dispatch<React.SetStateAction<boolean>>
): boolean => {
  const titleError = !IsTextValid(eventTitle);
  const descriptionError = !IsTextValid(eventDescription);
  const impactedTestsError = !IsValidImpactedTests(impactedTests);

  setEventTitleError(titleError);
  setEventDescriptionError(descriptionError);
  setTestsImpactedError(impactedTestsError);

  return !(titleError || descriptionError || impactedTestsError);
};
