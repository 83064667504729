import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PingTestHelper from "./PingTestHelper";
import { IsResultUp } from "../UptimeHelpers/IsUp";
import StatusIcon from "../UptimeHelpers/StatusIcon";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  IsRegexValid,
  IsTestNameValid,
  IsValidUrl,
} from "./PingTestModalHelper";
import { RequestType, SiteStatus, TestType } from "src/types/auto/types";
import HeaderTableAccordion from "./HeaderTableAccordion";
import BodyAccordion from "./BodyAccordion";
import StatusOverrideDropdown from "../StatusOverrideDropdown/StatusOverrideDropdown";

interface Props {
  focusName?: boolean;
  testName: string;
  setTestName: React.Dispatch<React.SetStateAction<string>>;
  showOnPublicStatusPage: boolean;
  setShowOnPublicStatusPage: React.Dispatch<React.SetStateAction<boolean>>;
  testType: TestType;
  setTestType: React.Dispatch<React.SetStateAction<TestType>>;
  overrideValue: SiteStatus | undefined;
  setOverrideValue: React.Dispatch<
    React.SetStateAction<SiteStatus | undefined>
  >;
  pauseTest: boolean;
  setPauseTest: React.Dispatch<React.SetStateAction<boolean>>;

  requestType: RequestType;
  setRequestType: React.Dispatch<React.SetStateAction<RequestType>>;
  requestHeader: string;
  setRequestHeader: React.Dispatch<React.SetStateAction<string>>;
  requestBody: string;
  setRequestBody: React.Dispatch<React.SetStateAction<string>>;

  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  regex: string;
  setRegex: React.Dispatch<React.SetStateAction<string>>;
  testNameError: boolean;
  setTestNameError: React.Dispatch<React.SetStateAction<boolean>>;
  urlError: boolean;
  setUrlError: React.Dispatch<React.SetStateAction<boolean>>;
  regexError: boolean;
  setRegexError: React.Dispatch<React.SetStateAction<boolean>>;
  resetTests: boolean;
}

const PingTestModalForm = (props: Props): JSX.Element => {
  const [testStatusCode, setTestStatusCode] = useState<number>();
  const [testStatusText, setTestStatusText] = useState("");
  const [regexPass, setRegexPass] = useState(false);
  const [testResponse, setTestResponse] = useState("");
  const [loadingTest, setLoadingTest] = useState(false);

  useEffect(() => {
    setTestStatusCode(undefined);
    setTestStatusText("");
    setRegexPass(false);
    setTestResponse("");
    setLoadingTest(false);
  }, [props.resetTests]);

  return (
    <>
      <TextField
        autoFocus={props.focusName}
        margin="dense"
        id="name"
        label="Test Name"
        fullWidth
        variant="standard"
        placeholder="My Test Name"
        value={props.testName}
        onChange={(e) => props.setTestName(e.target.value)}
        onBlur={() => props.setTestNameError(!IsTestNameValid(props.testName))}
        error={props.testNameError}
        helperText={props.testNameError && "Please enter a valid test name"}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={props.showOnPublicStatusPage}
            onChange={(_, checked) => props.setShowOnPublicStatusPage(checked)}
          />
        }
        label="Show On Public Status Page"
      />

      <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
        <Typography
          id="testTypeLabel"
          style={{ marginBottom: 3, textAlign: "left" }}
        >
          Test Type
        </Typography>
        <ToggleButtonGroup
          value={props.testType}
          exclusive
          onChange={(e, value) => {
            if (value !== TestType.Manual) {
              props.setOverrideValue(undefined);
              props.setPauseTest(false);
            } else {
              props.setOverrideValue(SiteStatus.Online);
            }
            props.setTestType(value);
          }}
          aria-label="testType"
        >
          <ToggleButton value={TestType.Manual}>Manual</ToggleButton>
          <ToggleButton value={TestType.Ping}>Ping</ToggleButton>
          <ToggleButton value={TestType.Regex}>Regex</ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      {(props.testType === TestType.Ping ||
        props.testType === TestType.Regex) && (
        <>
          <Box
            style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
          >
            <FormControl style={{ minWidth: "100px", marginRight: "10px" }}>
              <InputLabel id="request-type-label">Request Type</InputLabel>
              <Select
                labelId="request-type-label"
                id="request-type"
                value={props.requestType}
                label="Request Type"
                onChange={(e) => {
                  props.setRequestType(e.target.value as RequestType);
                }}
              >
                <MenuItem value={RequestType.Get}>Get</MenuItem>
                <MenuItem value={RequestType.Post}>Post</MenuItem>
                <MenuItem value={RequestType.Put}>Put</MenuItem>
                <MenuItem value={RequestType.Delete}>Delete</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              id="url"
              label="Test URL"
              fullWidth
              variant="standard"
              placeholder="https://google.com"
              value={props.url}
              onChange={(e) => props.setUrl(e.target.value)}
              onBlur={() => props.setUrlError(!IsValidUrl(props.url))}
              error={props.urlError}
              helperText={props.urlError && "Please enter a valid test url"}
            />
          </Box>

          <HeaderTableAccordion
            headers={props.requestHeader}
            setHeaders={props.setRequestHeader}
          />
          <BodyAccordion
            requestBody={props.requestBody}
            setRequestBody={props.setRequestBody}
          />

          {props.testType === TestType.Regex && (
            <TextField
              margin="dense"
              id="regex"
              label="Regular Expression"
              fullWidth
              variant="standard"
              placeholder="([A-Z])\w+"
              value={props.regex}
              onChange={(e) => props.setRegex(e.target.value)}
              onBlur={() =>
                props.setRegexError(!IsRegexValid(props.regex, props.testType))
              }
              error={props.regexError}
              helperText={
                props.regexError && "Please enter a valid regular expression"
              }
            />
          )}
          {props.testType === TestType.Regex && (
            <>
              <LoadingButton
                loading={loadingTest}
                onClick={() => {
                  setLoadingTest(true);
                  PingTestHelper(
                    props.url,
                    props.testType === TestType.Regex && props.regex,
                    (statusCode, statusText, regexPass, testResponse) => {
                      setLoadingTest(false);
                      setTestStatusCode(statusCode);
                      setTestStatusText(statusText);
                      setRegexPass(regexPass);
                      setTestResponse(testResponse);
                    }
                  );
                }}
                style={{ justifySelf: "left" }}
              >
                Test Ping
              </LoadingButton>
              {testStatusCode && (
                <>
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    <Box style={{}}>
                      <StatusIcon
                        status={
                          IsResultUp(testStatusCode) && regexPass
                            ? SiteStatus.Online
                            : SiteStatus.Offline
                        }
                        pixelSize={8}
                      />
                    </Box>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        marginLeft: 5,
                        lineHeight: 1,
                        marginTop: 2,
                      }}
                    >
                      Status Code: {testStatusCode}
                      {testStatusText.replace(" ", "").length > 0 &&
                        " - " + testStatusText}
                    </Typography>
                  </Box>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography>Show Response</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TextField
                        multiline
                        fullWidth
                        value={testResponse}
                        maxRows={6}
                      />
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </>
          )}
        </>
      )}

      {props.testType === TestType.Manual && (
        <Box style={{ marginTop: "40px" }}>
          <Typography style={{ marginBottom: 3, textAlign: "left" }}>
            Set the result of the test
          </Typography>
          <StatusOverrideDropdown
            isStatusOverride={false}
            overrideValue={props.overrideValue}
            setOverrideValue={props.setOverrideValue}
            pauseTest={props.pauseTest}
            setPauseTest={props.setPauseTest}
          />
        </Box>
      )}
    </>
  );
};

export default PingTestModalForm;
