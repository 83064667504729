import React, { useContext } from "react";
import {
  StatusPageSiteClient,
  StatusPageSiteViewModel,
} from "src/types/auto/types";
import DeleteConfirmModal from "../ConfirmModal/ConfirmModal";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";
import { SetSnackbar } from "src/contexts/SnackbarContext";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  name: string;
  description: string;
  site: StatusPageSiteViewModel;
}

const DeleteSiteModal = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);

  return (
    <DeleteConfirmModal
      open={props.open}
      onClose={async (cancelled: boolean): Promise<undefined> => {
        if (cancelled) {
          props.onClose(true);
          return;
        }
        props.onClose(false);
        return await new StatusPageSiteClient(
          Configuration.ServerRoot,
          FetchOverride
        )
          .deleteSite(props.site)
          .then((x) => {
            if (x) {
              // The site was deleted
              setSnackbar({
                message: "The site was deleted",
                status: "success",
              });
            } else {
              setSnackbar({
                message: "The site could not be deleted. Please try again",
                status: "error",
              });
            }

            return undefined;
          })
          .catch(() => {
            setSnackbar({
              message: "The site could not be deleted. Please try again",
              status: "error",
            });
          });
      }}
      actionName="Delete"
      name={props.name}
      description={props.description}
      buttonColor="error"
    />
  );
};

export default DeleteSiteModal;
