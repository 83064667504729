export const cancellationPolicy = `
# Cancellation policy
*Last updated: November 22, 2022*

We want satisfied customers, not hostages. That’s why we make it easy for you to cancel your account directly in all of our apps — no phone calls required, no questions asked.

Account owners can cancel their subscription in app by
1. Logging into to the application.
2. Clicking Manage Subscription
3. Cancelling the subscription

Our legal responsibility is to account owners, which means we cannot cancel an account at the request of anyone else. If for whatever reason you no longer know who the account owner is, [contact us](mailto:support@statushive.net). We will gladly reach out to any current account owners at the email addresses we have on file.

## What happens when you cancel an account?
You won’t be able to access any of the features of the app after you cancel.

We’ll permanently delete the content in your account from our servers 30 days after account deletion, and from our backups within 60 days. Retrieving content for a single account from a backup isn’t possible, so if you change your mind you’ll need to do it within the first 30 days after cancellation. **Content can’t be recovered once it has been permanently deleted.**

We won’t bill you again once you cancel. We don’t automatically prorate any unused time you may have left.

## Status Hive Initiated Cancellations
We may cancel accounts if they have been inactive for an extended period:

We also retain the right to suspend or terminate accounts for any reason at any time, as outlined in our [Terms of Service](/terms). In practice, this generally means we will cancel your account without notice if we have evidence that you are using our products to engage in abusive behaviour.

Adapted from the [Basecamp open-source policies / CC BY 4.0](https://github.com/basecamp/policies)`;
