import React, { createContext, useContext, useEffect, useState } from "react";
import { Configuration } from "src/Constants";
import {
  StatusPageSiteClient,
  StatusPageSiteViewModel,
} from "src/types/auto/types";
import { FetchOverride } from "./Requests";
import { SetSnackbar } from "./SnackbarContext";
import { UserContext } from "./UserStorage";

interface Props {
  children?: JSX.Element;
}

const SitesContextWrapper = ({ children }: Props): React.ReactElement => {
  const [sites, setSites] = useState<StatusPageSiteViewModel[]>([]);
  const setSnackbar = useContext(SetSnackbar);
  const user = useContext(UserContext);

  const refreshSites = () => {
    if (
      user?.email === undefined ||
      user?.email === null ||
      user?.email === ""
    ) {
      return;
    }
    new StatusPageSiteClient(Configuration.ServerRoot, FetchOverride)
      .getSitesForOrganisation()
      .then(setSites)
      .catch(() =>
        setSnackbar({
          message:
            "Could not get status pages for your organisation. Please try again later",
          status: "error",
        })
      );
  };

  useEffect(refreshSites, []);

  return (
    <RefreshSitesContext.Provider value={refreshSites}>
      <SitesContext.Provider value={sites}>{children}</SitesContext.Provider>
    </RefreshSitesContext.Provider>
  );
};

export const RefreshSitesContext = createContext((() => 0) as () => void);
export const SitesContext = createContext({} as StatusPageSiteViewModel[]);

export default SitesContextWrapper;
