import { Add } from "@mui/icons-material";
import { Box, Typography, useTheme, Tooltip, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DeleteEventModal from "src/components/EventModal/DeleteEventModal";
import EventModal from "src/components/EventModal/EventModal";
import LoadingSpinner from "src/components/LoadingSpinner/LoadingSpinner";
import EventsList from "src/components/StatusPageComponents/EventCard/EventsList";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  EventClient,
  EventViewModel,
  StatusPageSiteViewModel,
  TestClient,
  TestViewModel,
} from "src/types/auto/types";

interface Props {
  site?: StatusPageSiteViewModel;
}

function EventsManager(props: Props) {
  const theme = useTheme();
  const setSnackbar = useContext(SetSnackbar);

  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<EventViewModel[]>([]);
  const [tests, setTests] = useState<TestViewModel[]>([]);
  const [eventToEdit, setEventToEdit] = useState<EventViewModel>();
  const [eventToDelete, setEventToDelete] = useState<EventViewModel>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reloadEvents, setReloadEvents] = useState(false);

  useEffect(() => {
    if (props.site) {
      setLoading(true);
      setEvents([]);
      new EventClient(Configuration.ServerRoot, FetchOverride)
        .getBySiteId(props.site.id)
        .then(setEvents)
        .catch(() =>
          setSnackbar({
            message:
              "Could not get events for the site. Please try again later",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    }
  }, [props.site, reloadEvents]);

  useEffect(() => {
    if (props.site) {
      new TestClient(Configuration.ServerRoot, FetchOverride)
        .getTestsForSite(props.site.id)
        .then(setTests)
        .catch(() =>
          setSnackbar({
            message: "Could not get tests for the site. Please try again later",
            status: "error",
          })
        )
        .finally(() => setLoading(false));
    }
  }, [props.site]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            pb: 3,
          }}
          style={{
            lineHeight: 0.3,
            marginLeft: 20,
          }}
          variant="h2"
        >
          Events
        </Typography>
        <Tooltip
          title="Add Event"
          arrow
          style={{ marginTop: -17, paddingTop: 0 }}
        >
          <IconButton
            sx={{
              "&:hover": {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
            onClick={() => setShowCreateEditModal(true)}
          >
            <Add fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>

      <LoadingSpinner loading={loading} />
      {!loading && (
        <EventsList
          events={events}
          onEditPressed={(event) => {
            setEventToEdit(event);
            setShowCreateEditModal(true);
          }}
          onDeletePressed={(event) => {
            setEventToDelete(event);
            setShowDeleteModal(true);
          }}
          tests={tests}
        />
      )}

      {props.site && (
        <>
          <EventModal
            open={showCreateEditModal}
            onClose={(cancelled) => {
              setShowCreateEditModal(false);

              if (!cancelled) {
                setReloadEvents((x) => !x);
              }

              setTimeout(() => {
                setEventToEdit(undefined);
                setEventToDelete(undefined);
              }, 1000);
            }}
            site={props.site}
            eventToEdit={eventToEdit}
          />

          <DeleteEventModal
            open={showDeleteModal}
            onClose={(cancelled) => {
              setShowDeleteModal(false);

              if (!cancelled) {
                setReloadEvents((x) => !x);
              }

              setTimeout(() => {
                setEventToDelete(undefined);
              }, 1000);
            }}
            name={eventToDelete?.title}
            description={
              "Are you sure that you want to delete " +
              eventToDelete?.title +
              " from your events?"
            }
            event={eventToDelete}
          />
        </>
      )}
    </>
  );
}

export default EventsManager;
