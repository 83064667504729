import { EventStatus, EventViewModel } from "src/types/auto/types";

export const calculateUptimePercentage = (dataPoints: DataByDate[]): number => {
  return (
    (dataPoints.filter((x) => x.status !== EventStatus.Offline).length /
      dataPoints.length) *
    100
  );
};

export const createStatusIconValues = (
  events: EventViewModel[],
  maxPoints: number
): DataByDate[] => {
  const data = [];

  // Get the most recent date value before the status icons start (use online if there are none)
  let lastValue = getMostRecentStatusPreceedingStatusIcons(events, maxPoints);

  // Iterate through the number of points we are allowed and add the relevant dates
  for (let i = 0; i < maxPoints; i++) {
    const date = new Date(Date.now());
    date.setDate(date.getDate() - (maxPoints - i - 1));

    const dateEvents = events.filter(
      // Get events from the same day
      (x) => x.created.toDateString() === date.toDateString()
    );

    let todayStatus = undefined;

    // If there has been an event, get the most recent one to show on the status page (excluding events)
    const testResultData = dateEvents.filter(
      (x) => x.id === "00000000-0000-0000-0000-000000000000"
    );
    if (testResultData.length > 0) {
      lastValue = testResultData[testResultData.length - 1].status;

      // If any of the events have been offline today, today's event should show as offline, same with in maintenance
      todayStatus = testResultData.some((x) => x.status === EventStatus.Offline)
        ? EventStatus.Offline
        : testResultData.some((x) => x.status === EventStatus.Maintenance)
        ? EventStatus.Maintenance
        : EventStatus.Online;
    }

    data.push({
      date: date,
      status: todayStatus !== undefined ? todayStatus : lastValue,
      events: dateEvents,
    } as DataByDate);
  }

  return data;
};

export interface DataByDate {
  date: Date;
  status: EventStatus;
  events: EventViewModel[];
}

const getMostRecentStatusPreceedingStatusIcons = (
  events: EventViewModel[],
  maxPoints: number
): EventStatus => {
  // Get the date of the last status icon
  const lastDate = new Date(Date.now());
  lastDate.setDate(lastDate.getDate() - maxPoints);

  // Sort the events to get the most recent event that proceeds the status icons
  const sortedEventsPreStatusIcons = events
    .filter((x) => x.created.getTime() < lastDate.getTime())
    .sort(
      (a, b) =>
        a.created.getTime() -
        lastDate.getTime() -
        (b.created.getTime() - lastDate.getTime())
    );

  // If there are values that proceed the status icons, get the status value
  if (sortedEventsPreStatusIcons.length > 0) {
    return sortedEventsPreStatusIcons[sortedEventsPreStatusIcons.length - 1]
      .status;
  }

  // Return online as default
  return EventStatus.Online;
};
