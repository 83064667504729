import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import StatusIcon from "src/components/UptimeHelpers/StatusIcon";
import {
  EventStatus,
  SiteStatus,
  StatusPageClient,
  StatusPageDataViewModel,
  StatusPageSubscriberViewModel,
} from "src/types/auto/types";
import StatusChipsContainer from "src/components/StatusPageComponents/StatusIcon/StatusChipsContainer";
import EventsDisplay from "src/view/pages/StatusPages/EventsDisplay";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";

export const ContentContainer = styled(Box)(
  () => `
       margin-left: auto;
       margin-right: auto;
       margin-top: 50px;
       width: 70%;
       max-width: 1000px;
      `
);
const TitleContainer = styled(Box)(
  () => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
    @media (min-width: 650px) {
        flex-direction: row;
        justify-content: space-between;
        
    }
        `
);
const Title = styled(Typography)(
  () => `
  overflow: hidden;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
    @media (min-width: 650px) {
      text-align: left;
        
    }
        `
);

interface Props {
  data: StatusPageDataViewModel;
}

const StatusPageDisplay = (props: Props): JSX.Element => {
  if (!props.data) {
    return <></>;
  }

  const [subscribeTooltipOpen, setSubscribeTooltipOpen] = useState(false);
  const setSnackbar = useContext(SetSnackbar);

  const StyledTooltip = styled(({ className, ...p }: TooltipProps) => (
    <Tooltip {...p} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      margin: 0,
      backgroundColor: props.data.settings.modalColour,
      color: props.data.settings.modalFontColour,
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
      fontSize: 11,
    },
  }));

  if (props.data?.settings === undefined) {
    return <></>;
  }

  return (
    <Box style={{ minHeight: "100vh", margin: 0, padding: 0, gap: 0 }}>
      {props.data?.settings?.htmlAboveHeader && (
        <div
          dangerouslySetInnerHTML={{
            __html: props.data?.settings?.htmlAboveHeader,
          }}
        />
      )}
      <Box style={{ flex: 1, margin: 0, padding: 0 }}>
        <Box
          style={{
            backgroundImage:
              "linear-gradient(" +
              props.data.settings.headerColourGradientDegrees.toString() +
              "deg," +
              props.data.settings.headerColour1 +
              " , " +
              props.data.settings.headerColour2 +
              ")",
            margin: 0,
            padding: 0,
            width: "100%",
            paddingBottom: "50px",
            paddingTop: "50px",
          }}
        >
          <ContentContainer>
            <TitleContainer>
              <Title
                style={{
                  color: props.data.settings.headerFontColour,
                  cursor: props.data.settings.landingPageUrl
                    ? "pointer"
                    : "text",
                }}
                onClick={() => {
                  if (props.data.settings.landingPageUrl) {
                    let url = props.data.settings.landingPageUrl;
                    if (!url.includes("://")) {
                      url = "https://" + url;
                    }
                    window.open(url, "_blank").focus();
                  }
                }}
              >
                {props.data.settings.logo ? (
                  <img
                    src={props.data.settings.logo}
                    alt={props.data.settings.name + " logo"}
                    style={{ maxWidth: "40%", maxHeight: "300px" }}
                  />
                ) : (
                  props.data.settings.name
                )}
              </Title>
              <StyledTooltip
                onClose={() => setSubscribeTooltipOpen(false)}
                open={subscribeTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <>
                    <TextField
                      id="emailTextField"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor:
                              props.data.settings.headerColour1 + " !important",
                          },
                        },
                        width: "100%",
                        color: props.data.settings.modalFontColour,
                      }}
                      placeholder="Email"
                    />
                    <Button
                      style={{
                        backgroundColor: props.data.settings.modalColour,
                        color: props.data.settings.modalFontColour,
                        width: "100%",
                        textAlign: "center",
                      }}
                      onClick={() =>
                        new StatusPageClient(
                          Configuration.ServerRoot,
                          FetchOverride
                        )
                          .addStatusPageSubscriber({
                            statusPageOptionsId: props.data.settings.id,
                            emailAddress: (
                              document.getElementById(
                                "emailTextField"
                              ) as HTMLInputElement
                            ).value,
                          } as StatusPageSubscriberViewModel)
                          .then((x) => {
                            if (x) {
                              setSubscribeTooltipOpen(false);
                              setSnackbar({
                                message:
                                  "You have been subscribed to this status page",
                                status: "success",
                              });
                            } else {
                              setSnackbar({
                                message:
                                  "Could not subscribe to the status page. Please make sure that the email address is correct and that you have not already subscribed",
                                status: "warning",
                              });
                            }
                          })
                          .catch(() =>
                            setSnackbar({
                              message:
                                "Could not subscribe to the status page. Please try again later",
                              status: "error",
                            })
                          )
                      }
                    >
                      Subscribe
                    </Button>
                  </>
                }
              >
                <Button
                  variant="contained"
                  style={{
                    color: props.data.settings.buttonFontColour,
                    backgroundColor: props.data.settings.buttonBackgroundColour,
                    fontSize: "15px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "auto",
                  }}
                  onClick={() => setSubscribeTooltipOpen((x) => !x)}
                >
                  {props.data.settings.subscribeText}
                </Button>
              </StyledTooltip>
            </TitleContainer>
            {props.data.settings.showTopStatusPane && (
              <Box
                style={{
                  marginTop: "30px",
                  width: "100%",
                  backgroundColor: props.data.settings.buttonBackgroundColour,
                  borderRadius: 20,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "normal",
                  paddingTop: "30px",
                  paddingLeft: "20px",
                  paddingBottom: "30px",
                  paddingRight: "20px",
                }}
              >
                <Box style={{ display: "inline-block", paddingTop: "5px" }}>
                  <StatusIcon
                    status={
                      props.data.siteData.every(
                        (x) => x.currentStatus === EventStatus.Online
                      )
                        ? SiteStatus.Online
                        : props.data.siteData.some(
                            (x) => x.currentStatus === EventStatus.Offline
                          )
                        ? SiteStatus.Offline
                        : SiteStatus.TestsPaused
                    }
                    pixelSize={20}
                    isSiteStatus
                    onlineColour={props.data.settings.onlineColour}
                    offlineColour={props.data.settings.downColour}
                    maintenanceColour={props.data.settings.maintenanceColour}
                    modalColour={props.data.settings.modalColour}
                    modalFontColour={props.data.settings.modalFontColour}
                  />
                </Box>
                <Typography
                  component="h3"
                  style={{
                    color: props.data.settings.buttonFontColour,
                    fontSize: "25px",
                    fontWeight: "bold",
                    marginLeft: "15px",
                  }}
                >
                  {props.data.siteData.every(
                    (x) => x.currentStatus === EventStatus.Online
                  )
                    ? props.data.settings.topStatusPaneOnlineText
                    : props.data.siteData.some(
                        (x) => x.currentStatus === EventStatus.Offline
                      )
                    ? props.data.settings.topStatusPaneOfflineText
                    : props.data.settings.topStatusPaneMaintenanceText}
                </Typography>
              </Box>
            )}
            {props.data?.settings?.htmlBelowHeader && (
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data?.settings?.htmlBelowHeader,
                }}
              />
            )}
          </ContentContainer>
        </Box>
        {props.data?.settings?.htmlAboveBody && (
          <div
            dangerouslySetInnerHTML={{
              __html: props.data?.settings?.htmlAboveBody,
            }}
          />
        )}
        <Box
          style={{
            backgroundColor: props.data.settings.pageBodyColour,
            margin: 0,
            padding: 0,
            width: "100%",
          }}
        >
          <ContentContainer>
            <Box>
              {props.data.siteData.map((x) => (
                <StatusChipsContainer
                  key={x.siteName}
                  data={x}
                  settings={props.data.settings}
                />
              ))}
            </Box>
          </ContentContainer>
        </Box>
        {props.data?.settings?.htmlBelowBody && (
          <div
            dangerouslySetInnerHTML={{
              __html: props.data?.settings?.htmlBelowBody,
            }}
          />
        )}
        <EventsDisplay data={props.data} />
      </Box>
      {props.data?.settings?.htmlBelowEvents && (
        <div
          dangerouslySetInnerHTML={{
            __html: props.data?.settings?.htmlBelowEvents,
          }}
        />
      )}

      <Box
        style={{
          minHeight: "50px",
          marginLeft: "auto",
          marginRight: "auto",
          width: "70%",
        }}
      >
        <a
          href={Configuration.landingPageUrl}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Typography
            style={{ width: "100%", textAlign: "right" }}
            color="GrayText"
          >
            Powered By <b>{Configuration.name}</b>
          </Typography>
        </a>
      </Box>
      {props.data?.settings?.htmlBelowFooter && (
        <div
          dangerouslySetInnerHTML={{
            __html: props.data?.settings?.htmlBelowFooter,
          }}
        />
      )}
    </Box>
  );
};

export default StatusPageDisplay;
