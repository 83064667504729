import { Configuration } from "src/Constants";
import { AuthenticationClient } from "src/types/auto/types";

export const getCookie = (cname: string, cookie: string): string => {
  const name = cname + "=";
  const ca = decodeURIComponent(cookie).split(";");
  for (let c of ca) {
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
};

export const FetchOverride = {
  fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    if (init !== undefined) {
      init.headers = {
        ...init.headers,
        credentials: "include",
        redirect: "manual",
      };
    }

    return fetch(url, init).then((x) => {
      // If we get a 401, we are unauthorized, so log out
      if (x.status === 401) {
        new AuthenticationClient(Configuration.ServerRoot, FetchOverride)
          .logout()
          .then(() => {
            location.href = "/login?e=timeout";
          })
          .catch(() => {
            location.href = "/login?e=timeout";
          });
      }

      return x;
    });
  },
};

interface FetchOverrideType {
  fetch(url: RequestInfo, init?: RequestInit): Promise<Response>;
}

export const FileUploadFetchOverride = (
  fileToUpload: string | ArrayBuffer | null
): FetchOverrideType => ({
  fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    if (init !== undefined) {
      init.headers = {
        ...init.headers,
        Accept: "application/json",
        "Accept-Charset": "cp1252",
      };
      init.body = fileToUpload;
      init.method = "POST";
    }
    return fetch(url, init);
  },
});
