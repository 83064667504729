import * as React from "react";
import {
  OrganisationViewModel,
  StatusPageClient,
  StatusPageDataViewModel,
} from "src/types/auto/types";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { UrlRegex } from "./CreateStatusPageButton";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";
import StatusPageUrlChecker from "./StatusPageUrlChecker";

interface Props {
  data: StatusPageDataViewModel;
  setData: React.Dispatch<React.SetStateAction<StatusPageDataViewModel>>;
  reRender: React.Dispatch<React.SetStateAction<number>>;
  organisation?: OrganisationViewModel;
}

const StatusPageUrlInput = (props: Props): JSX.Element => {
  const [siteUrlValue, setSiteUrlValue] = useState("");
  const [isUrlAvailable, setIsUrlAvailable] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [hasUrlChanged, setHasUrlChanged] = useState(false);
  const [useCustomDomain, setUseCustomDomain] = useState(
    props.organisation &&
      props.organisation?.canUseCustomDomain &&
      !props.data?.settings?.url?.includes("." + Configuration.statusHiveUrl)
  );
  const [dataUsed, setDataUsed] = useState(false);

  useEffect(() => {
    if (props.data) {
      setSiteUrlValue(props.data.settings.url);
      setIsValidUrl(props.data.settings.url !== "");
      setIsUrlAvailable(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (!dataUsed && props.organisation && props.data) {
      setUseCustomDomain(
        props.organisation &&
          props.organisation?.canUseCustomDomain &&
          !props.data?.settings?.url?.includes(
            "." + Configuration.statusHiveUrl
          )
      );
      setDataUsed(true);
    }
  }, [props.organisation, props.data, dataUsed]);

  return (
    <Box style={{ marginBottom: 10 }}>
      {props.organisation && props.organisation?.canUseCustomDomain && (
        <FormGroup
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={useCustomDomain || false}
                onChange={(_, checked) => setUseCustomDomain(checked)}
              />
            }
            label={"Use Custom Domain"}
            style={{ marginBottom: "10px" }}
          />
        </FormGroup>
      )}
      <TextField
        label={
          !props.organisation?.canUseCustomDomain
            ? "Page Url"
            : "Page Subdomain"
        }
        style={{ width: "100%" }}
        value={
          siteUrlValue?.replace("." + Configuration.statusHiveUrl, "") || ""
        }
        onChange={(e) => {
          setSiteUrlValue(e.target.value);
        }}
        onBlur={(e) => {
          if (e.target.value) {
            setHasUrlChanged(true);
            const url = e.target.value
              .replace("https://", "")
              .replace("www", "")
              .replace("http://", "");

            // Validate the url
            if (!useCustomDomain && !url.match(UrlRegex)) {
              setIsValidUrl(false);
              return;
            }

            let newUrl = url;
            if (!useCustomDomain) {
              newUrl = newUrl + "." + Configuration.statusHiveUrl;
            }

            setIsValidUrl(true);
            new StatusPageClient(Configuration.ServerRoot, FetchOverride)
              .isUrlAvailable(props.data.settings.statusPageSiteId, newUrl)
              .then((x) => {
                setIsUrlAvailable(x);
                setSiteUrlValue(url);

                if (x) {
                  props.setData((y) => {
                    y.settings.url = newUrl;
                    props.reRender((x) => x + 1);
                    return y;
                  });
                }
              });
          } else {
            setIsValidUrl(false);
            setIsUrlAvailable(true);

            setSiteUrlValue("");
            props.setData((y) => {
              y.settings.url = "";
              props.reRender((x) => x + 1);
              return y;
            });
          }
        }}
        InputProps={{
          endAdornment: !useCustomDomain ? (
            <Typography>.status.statushive.net</Typography>
          ) : undefined,
        }}
        helperText={
          !isValidUrl
            ? "Please enter a valid url"
            : !isUrlAvailable
            ? "The url specified is not available, please try another one"
            : ""
        }
        error={!isValidUrl || !isUrlAvailable}
      />
      <StatusPageUrlChecker
        isCustomDomain={useCustomDomain}
        hasBeenUpdated={hasUrlChanged}
        statusPageSiteId={props.data.settings.statusPageSiteId}
        domain={props.data.settings.url}
      />
    </Box>
  );
};

export default StatusPageUrlInput;
