import React, { useCallback, useContext, useEffect, useState } from "react";
import { TestClient, TestViewModel } from "src/types/auto/types";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  monitors: TestViewModel[];
}

const ReOrderUptimeMonitorsModal = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const setSnackbar = useContext(SetSnackbar);
  const [monitors, setMonitors] = useState<TestViewModel[]>([]);

  const moveListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = monitors[dragIndex];
      const hoverItem = monitors[hoverIndex];
      // Swap places of dragItem and hoverItem in the monitors array
      setMonitors((monitor) => {
        const updateMonitors = [...monitor];
        updateMonitors[dragIndex] = hoverItem;
        updateMonitors[hoverIndex] = dragItem;
        return updateMonitors;
      });
    },
    [monitors]
  );

  useEffect(() => {
    setMonitors(props.monitors);
  }, [props.monitors, props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose(true)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Uptime Monitor Order</DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            marginBottom: "30px",
          }}
        >
          Drag and Drop the monitors into the order that they should be
          displayed on the status page
        </DialogContentText>
        <DndProvider backend={HTML5Backend}>
          {monitors.map((monitor, index) => (
            <DraggableItem
              key={monitor.id}
              index={index}
              test={monitor}
              moveListItem={moveListItem}
            />
          ))}
        </DndProvider>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => props.onClose(true)}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            setLoading(true);

            new TestClient(Configuration.ServerRoot, FetchOverride)
              .setTestOrder(
                Object.assign({}, ...monitors.map((x, i) => ({ [x.id]: i }))),
                monitors.length > 0 ? monitors[0].statusPageSiteId : undefined
              )
              .then(() => {
                setSnackbar({
                  message: "Test order updated",
                  status: "success",
                });
                props.onClose(false);
              })
              .catch(() => {
                setSnackbar({
                  message:
                    "Could not update the order of the tests. Please try again or contact support",
                  status: "error",
                });
              })
              .finally(() => setLoading(false));
          }}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReOrderUptimeMonitorsModal;
