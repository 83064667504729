import React, { useContext } from "react";
import { EventClient, EventViewModel } from "src/types/auto/types";
import DeleteConfirmModal from "../ConfirmModal/ConfirmModal";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";
import { SetSnackbar } from "src/contexts/SnackbarContext";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  name: string;
  description: string;
  event: EventViewModel;
}

const DeleteEventModal = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);

  return (
    <DeleteConfirmModal
      open={props.open}
      onClose={async (cancelled: boolean): Promise<undefined> => {
        if (cancelled) {
          props.onClose(true);
          return;
        }
        return await new EventClient(Configuration.ServerRoot, FetchOverride)
          .deleteEvent(props.event)
          .then((x) => {
            if (x) {
              // The event was deleted
              setSnackbar({
                message: "The event was deleted",
                status: "success",
              });
            } else {
              setSnackbar({
                message: "The event could not be deleted. Please try again",
                status: "error",
              });
            }

            return undefined;
          })
          .catch(() => {
            setSnackbar({
              message: "The event could not be deleted. Please try again",
              status: "error",
            });
          })
          .finally(() => props.onClose(false));
      }}
      actionName="Delete"
      name={props.name}
      description={props.description}
      buttonColor="error"
    />
  );
};

export default DeleteEventModal;
