import * as React from "react";
import {
  DomainVerificationViewModel,
  StatusPageClient,
} from "src/types/auto/types";
import { Box, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Markdown from "markdown-to-jsx";

interface Props {
  isCustomDomain: boolean;
  hasBeenUpdated: boolean;
  statusPageSiteId: string;
  domain: string;
}

const StatusPageUrlChecker = (props: Props): JSX.Element => {
  const [verificationStatus, setVerificationStatus] =
    useState<DomainVerificationViewModel>();

  useEffect(() => {
    if (props.isCustomDomain && !props.hasBeenUpdated && props.domain) {
      new StatusPageClient(Configuration.ServerRoot, FetchOverride)
        .getStatusPageDomainStatus(props.statusPageSiteId, props.domain)
        .then(setVerificationStatus);
    }
  }, [props]);

  if (
    !props.isCustomDomain ||
    props.hasBeenUpdated ||
    verificationStatus === undefined
  ) {
    return <></>;
  }

  return (
    <Box
      style={{
        marginTop: "20px",
        marginBottom: 10,
        display: "flex",
        alignItems: "center",
        gap: 10,
        textAlign: "justify",
      }}
    >
      {verificationStatus.hasCnameRecord &&
      verificationStatus.isDomainRegistered &&
      verificationStatus.isVerified ? (
        <Tooltip title="Your domain name is setup">
          <CheckCircleOutlineIcon color="success" />
        </Tooltip>
      ) : verificationStatus.hasCnameRecord === null ? (
        <Tooltip title="Sorry, we could not verify your domain name right now. Please try again later or contact support">
          <ErrorOutlineOutlinedIcon color="warning" />
        </Tooltip>
      ) : (
        <Tooltip title="Your domain name has not been fully setup">
          <CancelOutlinedIcon color="error" />
        </Tooltip>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Markdown>
          {verificationStatus?.verificationAction !== undefined &&
          verificationStatus?.verificationAction !== null
            ? verificationStatus?.verificationAction?.replace("\n", "<br />")
            : ""}
        </Markdown>
      </div>
    </Box>
  );
};

export default StatusPageUrlChecker;
