import React from "react";
import { Box, CircularProgress } from "@mui/material";

interface Props {
  loading: boolean;
}

const LoadingSpinner = (props: Props): JSX.Element =>
  props.loading ? (
    <Box
      style={{
        width: "100%",
        display: "flex",
        marginTop: 50,
        justifyContent: "center",
        verticalAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <></>
  );

export default LoadingSpinner;
