import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { Role, UserClient, UserViewModel } from "src/types/auto/types";
import { OrganisationContext, UserContext } from "src/contexts/UserStorage";
import UserForm from "./UserForm";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  userToEdit?: UserViewModel;
}

const UserModal = (props: Props): JSX.Element => {
  const myUser = useContext(UserContext);
  const setSnackbar = useContext(SetSnackbar);
  const organisation = useContext(OrganisationContext);

  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [role, setRole] = useState(Role.User);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.userToEdit) {
      setUserName(props.userToEdit.name);
      setEmail(props.userToEdit.email);
      setRole(props.userToEdit.role);
    }
  }, [props.open, props.userToEdit]);

  const CloseModal = (cancelled: boolean) => {
    props.onClose(cancelled);

    // Reset the state after the modal has closed
    setTimeout(() => {
      setUserName("");
      setEmail("");
      setRole(Role.User);

      setUserNameError(false);
      setEmailError(false);
      setLoading(false);
    }, 1000);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => CloseModal(true)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{props.userToEdit ? "Edit" : "Create"} User</DialogTitle>
      <DialogContent>
        <UserForm
          name={userName}
          setName={setUserName}
          nameError={userNameError}
          setNameError={setUserNameError}
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          setEmailError={setEmailError}
          role={role}
          setRole={setRole}
          disableRoleSelector={props.userToEdit?.id === myUser.id}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => CloseModal(true)}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            if (props.userToEdit) {
              const user = props.userToEdit;
              user.name = userName;
              user.email = email;
              user.role = role;
              new UserClient(Configuration.ServerRoot, FetchOverride)
                .updateUser(user)
                .then(() => {
                  setSnackbar({
                    message: "User updated successfully",
                    status: "success",
                  });
                  CloseModal(false);
                })
                .catch(() => {
                  setSnackbar({
                    message:
                      "Could not update user. Please try a different email address for the user",
                    status: "error",
                  });
                });
            } else {
              const user = new UserViewModel();
              user.name = userName;
              user.email = email;
              user.role = role;
              user.organisationId = organisation.id;

              new UserClient(Configuration.ServerRoot, FetchOverride)
                .inviteUser(user)
                .then(() => {
                  setSnackbar({
                    message: "User created successfully",
                    status: "success",
                  });
                  CloseModal(false);
                })
                .catch(() => {
                  setSnackbar({
                    message:
                      "Could not create user. Please try a different email address for the user",
                    status: "error",
                  });
                });
            }
          }}
        >
          {props.userToEdit ? "Update" : "Create"} User
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserModal;
