import { TestType } from "src/types/auto/types";

export const IsTestNameValid = (name: string) =>
  name.replace(" ", "").length !== 0;

export const IsRegexValid = (regex: string, testType: TestType) => {
  if (testType !== TestType.Regex) {
    // If not a regex check, it's always valid
    return true;
  } else {
    // Ensure that a regex exists
    if (regex.replace(" ", "").length === 0) {
      return false;
    }

    // Make sure that it can be created
    try {
      new RegExp(regex);
      return true;
    } catch {
      return false;
    }
  }
};

export const IsValidUrl = (url: string) => {
  return new RegExp(
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i
  ).test(url);
};

export const ValidateAllPingTestFields = (
  testName: string,
  testType: TestType,
  url: string,
  regex: string,
  setTestNameError: React.Dispatch<React.SetStateAction<boolean>>,
  setUrlError: React.Dispatch<React.SetStateAction<boolean>>,
  setRegexError: React.Dispatch<React.SetStateAction<boolean>>
): boolean => {
  const testNameError = !IsTestNameValid(testName);
  const urlError =
    testType === TestType.Ping || testType === TestType.Regex
      ? !IsValidUrl(url)
      : false;
  const regexError = !IsRegexValid(regex, testType);

  setTestNameError(testNameError);
  setUrlError(urlError);
  setRegexError(regexError);

  return !(testNameError || urlError || regexError);
};
