import {
  MoreVert,
  Create,
  Pause,
  Delete,
  PlayArrow,
} from "@mui/icons-material";
import {
  Card,
  Box,
  Typography,
  useTheme,
  IconButton,
  CardHeader,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IsResultUp } from "src/components/UptimeHelpers/IsUp";
import StatusIcon from "src/components/UptimeHelpers/StatusIcon";
import { Configuration } from "src/Constants";
import { SiteStatus, TestType, TestViewModel } from "src/types/auto/types";
import UptimeGraph, { calculateUptimeGraph } from "./UptimeGraph";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DangerousIcon from "@mui/icons-material/Dangerous";

interface Props {
  test: TestViewModel;
  onEditPressed: () => void;
  onDeletePressed: () => void;
  onPauseResumePressed: () => void;
  onOverridePressed: () => void;
}

const TestStatusCard = (props: Props) => {
  const theme = useTheme();
  const [uptimeGraphData, setUptimeGraphData] = useState<ApexAxisChartSeries>(
    []
  );

  useEffect(() => {
    if (props.test) {
      setUptimeGraphData(calculateUptimeGraph(props.test));
    }
  }, [props.test]);

  const [anchorActionList, setAnchorActionList] = useState<null | HTMLElement>(
    null
  );

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        sx={{
          "& .MuiCardHeader-content": {
            maxWidth: "90%",
            wordWrap: "break-word",
          },
        }}
        action={
          <>
            <IconButton
              aria-label="options"
              onClick={(e) => setAnchorActionList(e.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              open={anchorActionList !== null}
              anchorEl={anchorActionList}
              onClose={() => setAnchorActionList(null)}
            >
              <MenuItem
                onClick={() => {
                  setAnchorActionList(null);
                  props.onEditPressed();
                }}
              >
                <ListItemIcon>
                  <Create fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
              {(props.test.testType === TestType.Ping ||
                props.test.testType === TestType.Regex) && [
                <MenuItem
                  key="testOverride"
                  onClick={() => {
                    setAnchorActionList(null);
                    props.onOverridePressed();
                  }}
                >
                  <ListItemIcon>
                    <DangerousIcon fontSize="small" color="info" />
                  </ListItemIcon>
                  <ListItemText style={{ color: theme.colors.info.dark }}>
                    Set Test Override
                  </ListItemText>
                </MenuItem>,
                <MenuItem
                  key="pauseresumetest"
                  onClick={() => {
                    setAnchorActionList(null);
                    props.onPauseResumePressed();
                  }}
                >
                  <ListItemIcon>
                    {props.test?.isInMaintenanceMode ? (
                      <PlayArrow fontSize="small" color="primary" />
                    ) : (
                      <Pause fontSize="small" color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText style={{ color: theme.colors.primary.main }}>
                    {props.test?.isInMaintenanceMode
                      ? "Resume Test"
                      : "Pause Test"}
                  </ListItemText>
                </MenuItem>,
              ]}

              <MenuItem
                style={{ color: "red" }}
                onClick={() => {
                  setAnchorActionList(null);
                  props.onDeletePressed();
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </Menu>
          </>
        }
        title={
          <Box>
            <Box style={{ display: "inline-block" }}>
              <StatusIcon
                status={
                  props.test.overriddenStatus !== undefined &&
                  props.test.overriddenStatus !== null
                    ? props.test.overriddenStatus
                    : props.test.isInMaintenanceMode
                    ? SiteStatus.TestsPaused
                    : IsResultUp(props.test.latestStatusCode)
                    ? SiteStatus.Online
                    : SiteStatus.Offline
                }
              />
            </Box>
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                marginBottom: 15,
                paddingBottom: 0,
              }}
              variant="h3"
            >
              {props.test.name}
            </Typography>
            <Tooltip
              title={
                props.test.showOnPublicStatusPage
                  ? "Test Visible On Status Page"
                  : "Test Hidden On Status Page"
              }
            >
              {props.test.showOnPublicStatusPage ? (
                <RemoveRedEyeOutlinedIcon
                  color="disabled"
                  style={{ paddingTop: "5px", marginLeft: "10px" }}
                />
              ) : (
                <VisibilityOffOutlinedIcon
                  color="disabled"
                  style={{ paddingTop: "5px", marginLeft: "10px" }}
                />
              )}
            </Tooltip>
          </Box>
        }
        subheader={
          <Box>
            {(props.test.testType === TestType.Ping ||
              props.test.testType === TestType.Regex) &&
              props.test.overriddenStatus !== undefined &&
              props.test.overriddenStatus !== null && (
                <Typography>
                  Test manually overridden to{" "}
                  {props.test.overriddenStatus === SiteStatus.TestsPaused
                    ? "in Maintenance"
                    : SiteStatus[props.test.overriddenStatus]}{" "}
                  {props.test.isInMaintenanceMode && "and monitor paused. "}
                  <a
                    style={{
                      color: "#f8a418",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={props.onOverridePressed}
                  >
                    Click here to update the status
                  </a>
                </Typography>
              )}
            {TestType[props.test.testType]} Test{" "}
            {props.test.testType === TestType.Ping ||
            props.test.testType === TestType.Regex ? (
              <>
                on{" "}
                <a
                  href={props.test.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ maxWidth: "80%" }}
                >
                  {props.test.url}
                </a>
              </>
            ) : (
              ""
            )}
          </Box>
        }
      />

      <Box style={{ marginLeft: 20, marginRight: 20 }}>
        <Typography
          sx={{
            pb: 3,
          }}
          style={{
            margin: 0,
            padding: 0,
            color: theme.palette.text.secondary,
          }}
          variant="h5"
        >
          Uptime (24 Hours):{" "}
          {uptimeGraphData.length > 0 &&
          (
            uptimeGraphData[0].data as {
              x: number;
              y: number;
            }[]
          ).filter((x) => x.y !== Configuration.pausedTestStatusCode).length > 0
            ? Math.round(
                ((
                  uptimeGraphData[0].data as {
                    x: number;
                    y: number;
                  }[]
                ).filter((x) => x.y === 1).length /
                  (
                    uptimeGraphData[0].data as {
                      x: number;
                      y: number;
                    }[]
                  ).filter((x) => x.y !== Configuration.pausedTestStatusCode)
                    .length) *
                  100
              ) + "%"
            : "Untested"}
        </Typography>
        <UptimeGraph data={uptimeGraphData} />
      </Box>
    </Card>
  );
};

export default TestStatusCard;
