import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "src/layouts/SidebarLayout/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import SitesOverviewDashboard from "src/view/dashboards/GlobalSites/SitesOverviewDashboard";
import IndividualSiteDashboard from "./view/dashboards/IndividualSiteView/IndividualSiteDashboard";
import Login from "./view/login/Login";
import SignUp from "./view/login/SignUp";
import AuthedRoute from "./contexts/AuthedRoute";
import ConfirmEmail from "./view/login/signup/ConfirmEmail";
import CreateTestsSignUp from "./view/login/signup/CreateTestsSignUp";
import ForgotPassword from "./view/login/ForgotPassword";
import PasswordReset from "./view/login/PasswordReset";
import OrganisationSettings from "./view/settings/OrganisationSettings";
import AccountManagementSettings from "./view/settings/AccountManagementSettings";
import AcceptInvitation from "./view/login/signup/AcceptInvitation";
import Payment from "./view/login/signup/Payment";
import TermsContainer, { TermsType } from "./view/legal/TermsContainer";
import StatusPage from "./view/pages/StatusPages/StatusPage";
import CreateStatusPage from "./view/login/signup/CreateStatusPage";
import AuthNavigationSwitch from "./view/AuthNavigationSwitch";
import Unsubscribe from "./view/login/Unsubscribe";
import Contact from "./view/settings/Contact/Contact";

// eslint-disable-next-line react/display-name
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Status

const Status404 = Loader(lazy(() => import("src/view/pages/Status/Status404")));
const Status500 = Loader(lazy(() => import("src/view/pages/Status/Status500")));
const StatusComingSoon = Loader(
  lazy(() => import("src/view/pages/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => import("src/view/pages/Status/Maintenance"))
);

// eslint-disable-next-line react/display-name
const routes: RouteObject[] = [
  {
    path: "",
    element: (
      <AuthNavigationSwitch
        DashboardComponent={
          <>
            <SidebarLayout>
              <SitesOverviewDashboard />
              <AuthedRoute />
            </SidebarLayout>
          </>
        }
        LoginComponent={<Login />}
        StatusPageComponent={(data) => <StatusPage statusPageData={data} />}
      />
    ),
  },
  {
    path: "statuspage",
    element: <SidebarLayout />,
    children: [
      {
        // AUTH
        path: "",
        element: <AuthedRoute />,
        children: [
          {
            path: "",
            element: <Navigate to="..//" />,
          },
          {
            path: "*",
            element: <IndividualSiteDashboard />,
          },
        ],
      },
    ],
  },
  {
    path: "contact",
    element: <SidebarLayout />,
    children: [
      {
        // AUTH
        path: "",
        element: <AuthedRoute />,
        children: [
          {
            path: "",
            element: <Contact />,
          },
        ],
      },
    ],
  },
  {
    path: "settings",
    element: <SidebarLayout />,
    children: [
      {
        // AUTH
        path: "",
        element: <AuthedRoute />,
        children: [
          {
            path: "accounts",
            element: <AccountManagementSettings />,
          },
          {
            path: "organisationSettings",
            element: <OrganisationSettings />,
          },
        ],
      },
    ],
  },
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgottenPassword",
        element: <ForgotPassword />,
      },
      {
        path: "resetPassword",
        element: <PasswordReset />,
      },
      {
        path: "signup",
        children: [
          { path: "", element: <SignUp /> },
          {
            path: "confirmEmail",
            element: <ConfirmEmail />,
          },
          {
            path: "acceptInvitation",
            element: <AcceptInvitation />,
          },
          {
            path: "payment",
            element: <Payment />,
          },
          {
            path: "createStatusPage",
            element: <CreateStatusPage />,
          },
          {
            path: "createTests",
            element: <CreateTestsSignUp />,
          },
        ],
      },
      {
        path: "createStatusPage",
        element: <CreateStatusPage />,
      },
      {
        path: "createTests",
        element: <CreateTestsSignUp />,
      },
      {
        path: "unsubscribe",
        element: <Unsubscribe />,
      },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: "404",
            element: <Status404 />,
          },
          {
            path: "500",
            element: <Status500 />,
          },
          {
            path: "maintenance",
            element: <StatusMaintenance />,
          },
          {
            path: "coming-soon",
            element: <StatusComingSoon />,
          },
        ],
      },

      {
        path: "terms",
        element: <TermsContainer termsType={TermsType.TermsOfService} />,
      },
      {
        path: "cancellationPolicy",
        element: <TermsContainer termsType={TermsType.CancellationPolicy} />,
      },
      {
        path: "copyright",
        element: <TermsContainer termsType={TermsType.Copyright} />,
      },
      {
        path: "privacy",
        element: <TermsContainer termsType={TermsType.PrivacyPolicy} />,
      },
      {
        path: "refunds",
        element: <TermsContainer termsType={TermsType.Refunds} />,
      },
      {
        path: "gdpr",
        element: <TermsContainer termsType={TermsType.GDPR} />,
      },
      {
        path: "cookies",
        element: <TermsContainer termsType={TermsType.CookiePolicy} />,
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
];

export default routes;
