import React, { useContext, useEffect, useState } from "react";
import {
  StatusPageSiteViewModel,
  StatusPageClient,
  StatusPageOptionsViewModel,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import {
  Card,
  CardHeader,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { LoadingButton } from "@mui/lab";
import MomentLocalisationTable from "./MomentLocalisationTable";
import DateDisplaysAs from "./DateDisplaysAs";

interface Props {
  site: StatusPageSiteViewModel;
}

export default function UpdateStatusPageLocalisation(props: Props) {
  const setSnackbar = useContext(SetSnackbar);
  const [data, setData] = useState<StatusPageOptionsViewModel>();
  const [loading, setLoading] = useState(false);
  const [chipModalDate, setChipModalDate] = useState("Do MMM YY");
  const [eventGroupDate, setEventGroupDate] = useState("Do MMMM YYYY");
  const [eventCardDate, setEventCardDate] = useState("HH:mm DD/MM/YYYY");

  useEffect(() => {
    new StatusPageClient(Configuration.ServerRoot, FetchOverride)
      .getStatusPageForSiteId(props.site.id)
      .then((x) => {
        setData(x);
        if (x.statusChipModalDateLocalisation) {
          setChipModalDate(x.statusChipModalDateLocalisation);
        }
        if (x.eventGroupDateLocalisation) {
          setEventGroupDate(x.eventGroupDateLocalisation);
        }
        if (x.eventCardDateLocalisation) {
          setEventCardDate(x.eventCardDateLocalisation);
        }
      })
      .catch(() => {
        setSnackbar({
          message:
            "Could not retrieve current status page. Please try again or contact support",
          status: "error",
        });
      });
  }, []);

  if (data === undefined) {
    return <CircularProgress />;
  }

  return (
    <>
      <Card style={{ width: "100%", padding: "10px", marginTop: 20 }}>
        <CardHeader
          title={
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                marginBottom: 15,
                paddingBottom: 0,
              }}
              variant="h3"
            >
              Localisation
            </Typography>
          }
          subheader={
            <Typography style={{ marginLeft: 10 }}>
              Update the localisation options for your status page
            </Typography>
          }
        />
        <Container
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "20px",
          }}
        >
          <TextField
            variant="standard"
            fullWidth
            label="Status Chip Modal Date"
            value={chipModalDate}
            onChange={(e) => setChipModalDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: "20px" }}
          />
          <DateDisplaysAs format={chipModalDate} />
          <TextField
            variant="standard"
            fullWidth
            label="Event Group Date"
            value={eventGroupDate}
            onChange={(e) => setEventGroupDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: "20px" }}
          />
          <DateDisplaysAs format={eventGroupDate} />
          <TextField
            variant="standard"
            fullWidth
            label="Event Card Date"
            value={eventCardDate}
            onChange={(e) => setEventCardDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: "20px" }}
          />
          <DateDisplaysAs format={eventCardDate} />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            loading={loading}
            onClick={() => {
              setLoading(true);

              const d = data;
              d.statusChipModalDateLocalisation = chipModalDate;
              d.eventGroupDateLocalisation = eventGroupDate;
              d.eventCardDateLocalisation = eventCardDate;

              new StatusPageClient(Configuration.ServerRoot, FetchOverride)
                .updateStatusPage(d)
                .then(setData)
                .catch(() =>
                  setSnackbar({
                    message:
                      "Could not update localisation options. Please try again or contact support",
                    status: "error",
                  })
                )
                .finally(() => setLoading(false));
            }}
          >
            Save
          </LoadingButton>
        </Container>
        <Container
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "20px",
          }}
        >
          <MomentLocalisationTable />
        </Container>
      </Card>
    </>
  );
}
