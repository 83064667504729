import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "src/components/Footer/Footer";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "src/contexts/UserStorage";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import { UserClient } from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import AnimatedWavingHand from "src/components/AnimatedWavingHand/AnimatedWavingHand";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { styled, Container } from "@mui/material";
import AccountSetupPagination from "src/components/AccountSetupPagination/AccountSetupPagination";

const validTokenLength = 6;

const Wrapper = styled(Container)(
  () => `
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    `
);

export default function ConfirmEmail() {
  const [searchParams] = useSearchParams();
  const user = useContext(UserContext);
  const setSnackbar = useContext(SetSnackbar);

  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationCodeComplete, setConfirmationCodeComplete] =
    useState(false);
  const [confirmationCodeError, setConfirmationCodeError] = useState(false);

  const [loading, setLoading] = useState(false);

  // On load, autofill the confirmation code if applicable
  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      setConfirmationCode(token);
      setConfirmationCodeComplete(token.length === validTokenLength);
    }
  }, [searchParams]);

  // When the confirmation code has been fully entered, ping the backend
  useEffect(() => {
    if (confirmationCodeComplete) {
      setLoading(true);
      new UserClient(Configuration.ServerRoot, FetchOverride)
        .confirmEmailAddress(parseInt(confirmationCode))
        .then(() => {
          setSnackbar({
            message: "Confirmed Email Address",
            status: "success",
          });

          // If this confirmation is an email change, don't continue with setup walk through
          const change = searchParams.get("change");
          if (change === "true") {
            location.replace("/");
            return;
          }

          // If the user is not logged in, return to login
          if (user === undefined || user === null || user.id === undefined) {
            location.replace("/");
          }
          // Else continue with user setup
          else {
            location.replace("/signup/payment");
          }
        })
        .catch(() => {
          setSnackbar({
            message:
              "Could not confirm email address. Are you sure that the status code is correct. Please contact support if you continue experiencing issues",
            status: "error",
          });
          setConfirmationCodeError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [confirmationCode, confirmationCodeComplete, user]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <AnimatedWavingHand />
        <Typography component="h1" variant="h2">
          Welcome to {Configuration.name}!
        </Typography>
        <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
          Please enter the code from your email address to continue.
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Wrapper>
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmationCode"
              label="Confirmation Code"
              name="confirmationCode"
              type="number"
              autoFocus
              value={confirmationCode}
              onChange={(e) => {
                setConfirmationCode(e.target.value);
                setConfirmationCodeComplete(
                  e.target.value.length === validTokenLength
                );
              }}
              error={confirmationCodeError}
              helperText={
                confirmationCodeError &&
                "This confirmation code was not recognised"
              }
            />
          </Wrapper>

          <LoadingButton
            fullWidth
            variant="contained"
            style={{ color: "white" }}
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
            onClick={() =>
              setConfirmationCodeComplete(
                confirmationCode.length === validTokenLength
              )
            }
          >
            Continue
          </LoadingButton>
        </Box>
      </Box>
      <AccountSetupPagination pageNumber={1} isFullAccountSetup />
      <Footer />
    </Container>
  );
}
