import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  OrganisationViewModel,
  StatusPageDataViewModel,
} from "src/types/auto/types";
import { Checkbox, FormControlLabel, styled, TextField } from "@mui/material";
import ColourPicker from "src/components/ColourPicker/ColourPicker";
import { useEffect, useState } from "react";
import CreateStatusPageButton from "./CreateStatusPageButton";
import ImageUpload from "../ImageUpload/ImageUpload";
import StatusPageUrlInput from "./StatusPageUrlInput";

interface Props {
  data: StatusPageDataViewModel;
  setData: React.Dispatch<React.SetStateAction<StatusPageDataViewModel>>;
  reRender: React.Dispatch<React.SetStateAction<number>>;
  update?: boolean;
  organisation?: OrganisationViewModel;
}

export const ConfigBox = styled(Box)(
  () => `
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 100%;
          `
);

export const ConfigTypography = styled(Typography)(
  () => `
    width: 100%;
    text-align: left;
    font-size: 20px;
    margin-top: 10;
          `
);

export const AdvancedWrapper = styled(Box)(
  () => `
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 60vh;
    padding-left: 20px;
    padding-right: 20px;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
          `
);

const AdvancedStatusPageConfig = (props: Props): JSX.Element => {
  const [siteNameValue, setSiteNameValue] = useState("");
  const [landingPageUrl, setLandingPageUrl] = useState("");
  const [subscribeText, setSubscribeText] = useState("");
  const [topStatusPaneOnlineText, setTopStatusPaneOnlineText] = useState("");
  const [topStatusPaneMaintenanceText, setTopStatusPaneMaintenanceText] =
    useState("");
  const [topStatusPaneOfflineText, setTopStatusPaneOfflineText] = useState("");

  useEffect(() => {
    if (props.data) {
      setSiteNameValue(props.data.settings.name);
      setLandingPageUrl(props.data.settings.landingPageUrl);
      setSubscribeText(props.data.settings.subscribeText);
      setTopStatusPaneOnlineText(props.data.settings.topStatusPaneOnlineText);
      setTopStatusPaneMaintenanceText(
        props.data.settings.topStatusPaneMaintenanceText
      );
      setTopStatusPaneOfflineText(props.data.settings.topStatusPaneOfflineText);
    }
  }, [props.data]);

  return (
    <>
      <AdvancedWrapper>
        <ConfigTypography variant="h3">General Settings</ConfigTypography>
        <ConfigBox style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            label="Page Name"
            style={{ marginTop: 10, width: "100%" }}
            value={siteNameValue}
            onChange={(e) => {
              setSiteNameValue(e.target.value);
            }}
            onBlur={(e) =>
              props.setData((y) => {
                y.settings.name = e.target.value;
                y.settings.title = e.target.value;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <TextField
            label="Landing Page URL"
            style={{ marginTop: 10, width: "100%" }}
            value={landingPageUrl}
            onChange={(e) => {
              setLandingPageUrl(e.target.value);
            }}
            onBlur={(e) =>
              props.setData((y) => {
                y.settings.landingPageUrl = e.target.value;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <StatusPageUrlInput
            data={props.data}
            setData={props.setData}
            reRender={props.reRender}
            organisation={props.organisation}
          />
        </ConfigBox>
        <ConfigBox>
          <ImageUpload
            label="Favicon (.ico file)"
            fileName={props.data.settings.iconFileName}
            accept="image/x-icon, image/vnd.microsoft.icon"
            onChange={(file) => {
              if (file === undefined) {
                props.setData((y) => {
                  y.settings.icon = undefined;
                  y.settings.iconFileName = undefined;
                  props.reRender((x) => x + 1);
                  return y;
                });
                return;
              }

              const reader = new FileReader();
              reader.onload = function (e) {
                const contents = e.target.result;

                props.setData((y) => {
                  y.settings.icon = contents as string;
                  y.settings.iconFileName = file.name;
                  props.reRender((x) => x + 1);
                  return y;
                });
              };
              reader.readAsDataURL(file);
            }}
          />
          <ImageUpload
            label="Logo"
            fileName={props.data.settings.logoFileName}
            accept="image/png, image/jpeg"
            onChange={(file) => {
              if (file === undefined) {
                props.setData((y) => {
                  y.settings.logo = undefined;
                  y.settings.logoFileName = undefined;
                  props.reRender((x) => x + 1);
                  return y;
                });
                return;
              }

              const reader = new FileReader();
              reader.onload = function (e) {
                const contents = e.target.result;

                props.setData((y) => {
                  y.settings.logo = contents as string;
                  y.settings.logoFileName = file.name;
                  props.reRender((x) => x + 1);
                  return y;
                });
              };
              reader.readAsDataURL(file);
            }}
          />
        </ConfigBox>
        <ConfigTypography variant="h3" style={{ marginTop: 30 }}>
          Header Background Colour
        </ConfigTypography>
        <ConfigBox>
          <ColourPicker
            label="Primary Background Colour"
            colour={props.data.settings.headerColour1}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.headerColour1 = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <ColourPicker
            label="Secondary Background Colour"
            colour={props.data.settings.headerColour2}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.headerColour2 = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigBox style={{ marginTop: 20 }}>
          <TextField
            type="number"
            label="Header Colour Gradient Angle"
            style={{ width: "100%" }}
            value={parseInt(props.data.settings.headerColourGradientDegrees)}
            onChange={(e) =>
              props.setData((y) => {
                y.settings.headerColourGradientDegrees =
                  e.target.value.toString();
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigTypography variant="h3" style={{ marginTop: 30 }}>
          Header Font Colour
        </ConfigTypography>
        <ConfigBox style={{ width: "50%", paddingRight: "10px" }}>
          <ColourPicker
            label="Font Colour"
            colour={props.data.settings.headerFontColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.headerFontColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigTypography variant="h3" style={{ marginTop: 30 }}>
          Header Button
        </ConfigTypography>
        <ConfigBox>
          <ColourPicker
            label="Background Colour"
            colour={props.data.settings.buttonBackgroundColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.buttonBackgroundColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <ColourPicker
            label="Font Colour"
            colour={props.data.settings.buttonFontColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.buttonFontColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigBox style={{ marginTop: 20 }}>
          <TextField
            label="Subscribe For Updates Text"
            value={subscribeText}
            style={{ width: "100%" }}
            onChange={(e) => setSubscribeText(e.target.value)}
            onBlur={() =>
              props.setData((y) => {
                y.settings.subscribeText = subscribeText;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>

        <ConfigTypography variant="h3" style={{ marginTop: 30 }}>
          Status Pane
        </ConfigTypography>
        <ConfigBox>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.data.settings.showTopStatusPane}
                onChange={(_, checked) =>
                  props.setData((y) => {
                    y.settings.showTopStatusPane = checked;
                    props.reRender((x) => x + 1);
                    return y;
                  })
                }
              />
            }
            label="Show Status Pane"
          />
        </ConfigBox>
        <ConfigBox style={{ marginTop: 20 }}>
          <TextField
            label="Top Pane Online Text"
            value={topStatusPaneOnlineText}
            style={{ width: "100%" }}
            onChange={(e) => setTopStatusPaneOnlineText(e.target.value)}
            onBlur={() =>
              props.setData((y) => {
                y.settings.topStatusPaneOnlineText = topStatusPaneOnlineText;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigBox style={{ marginTop: 20 }}>
          <TextField
            label="Top Pane Maintenance Text"
            value={topStatusPaneMaintenanceText}
            style={{ width: "100%" }}
            onChange={(e) => setTopStatusPaneMaintenanceText(e.target.value)}
            onBlur={() =>
              props.setData((y) => {
                y.settings.topStatusPaneMaintenanceText =
                  topStatusPaneMaintenanceText;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigBox style={{ marginTop: 20 }}>
          <TextField
            label="Top Pane Offline Text"
            value={topStatusPaneOfflineText}
            style={{ width: "100%" }}
            onChange={(e) => setTopStatusPaneOfflineText(e.target.value)}
            onBlur={() =>
              props.setData((y) => {
                y.settings.topStatusPaneOfflineText = topStatusPaneOfflineText;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigTypography variant="h3" style={{ marginTop: 30 }}>
          Status Colours
        </ConfigTypography>
        <ConfigBox>
          <ColourPicker
            label="Online Colour"
            colour={props.data.settings.onlineColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.onlineColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <ColourPicker
            label="Maint Colour"
            colour={props.data.settings.maintenanceColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.maintenanceColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigBox
          style={{ marginTop: 20, width: "50%", paddingRight: "10px" }}
        >
          <ColourPicker
            label="Offline Colour"
            colour={props.data.settings.downColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.downColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigTypography variant="h3" style={{ marginTop: 30 }}>
          Body Background
        </ConfigTypography>
        <ConfigBox style={{ width: "50%", paddingRight: "10px" }}>
          <ColourPicker
            label="Body Background"
            colour={props.data.settings.pageBodyColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.pageBodyColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigTypography variant="h3" style={{ marginTop: 30 }}>
          Body Font Colours
        </ConfigTypography>
        <ConfigBox>
          <ColourPicker
            label="Primary Font Colour"
            colour={props.data.settings.pageBodyPrimaryFontColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.pageBodyPrimaryFontColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <ColourPicker
            label="Secondary Font Colour"
            colour={props.data.settings.pageBodySecondaryFontColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.pageBodySecondaryFontColour = x as string;
                y.settings.modalFontColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigTypography variant="h3" style={{ marginTop: 30 }}>
          Modal Colours
        </ConfigTypography>
        <ConfigBox>
          <ColourPicker
            label="Background Colour"
            colour={props.data.settings.modalColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.modalColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <ColourPicker
            label="Font Colour"
            colour={props.data.settings.modalFontColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.modalFontColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigTypography variant="h3" style={{ marginTop: 30 }}>
          Event Colours
        </ConfigTypography>
        <ConfigBox>
          <ColourPicker
            label="Background Colour"
            colour={props.data.settings.eventCardBackgroundColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.eventCardBackgroundColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <ColourPicker
            label="Font Colour"
            colour={props.data.settings.eventCardFontColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.eventCardFontColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigBox>
          <ColourPicker
            label="Status Chip Background Colour"
            colour={props.data.settings.statusChipBackgroundColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.statusChipBackgroundColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <ColourPicker
            label="Status Chip Font Colour"
            colour={props.data.settings.statusChipFontColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.statusChipFontColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
        <ConfigBox>
          <ColourPicker
            label="Site Chip Background Colour"
            colour={props.data.settings.siteChipBackgroundColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.siteChipBackgroundColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
          <ColourPicker
            label="Site Chip Font Colour"
            colour={props.data.settings.siteChipFontColour}
            setColour={(x) =>
              props.setData((y) => {
                y.settings.siteChipFontColour = x as string;
                props.reRender((x) => x + 1);
                return y;
              })
            }
          />
        </ConfigBox>
      </AdvancedWrapper>
      <CreateStatusPageButton data={props.data} update={props.update} />
    </>
  );
};

export default AdvancedStatusPageConfig;
