import React, { useEffect, useState } from "react";
import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  EventStatus,
  EventViewModel,
  StatusPageOptionsViewModel,
} from "src/types/auto/types";
import StatusEventCard from "../EventCard/StatusEventCard";
import { DataByDate } from "../StatusDataCalculations";

interface Props {
  data: DataByDate;
  settings: StatusPageOptionsViewModel;
  events: EventViewModel[];
}

const SingleStatusIcon = styled(Box)(
  () => `
           width: 20px;
           height: 30px;
           transition: box-shadow 0.01s ease-in-out;
           border-radius: 2px;
           &:hover {
              box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
          }
          `
);

const StatusChip = (props: Props): JSX.Element => {
  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: props.settings.modalColour,
      color: props.settings.modalFontColour,
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
      fontSize: 11,
    },
  }));

  const [allEvents, setAllEvents] = useState<EventViewModel[]>([]);

  useEffect(() => {
    if (props.data.events) {
      const events = props.events
        ?.concat(props.data.events)
        ?.sort((a, b) => b.created.getTime() - a.created.getTime());
      setAllEvents(events || []);
    }
  }, [props.data, props.events]);

  return (
    <StyledTooltip
      enterTouchDelay={0}
      title={
        <>
          <Typography style={{ color: props.settings.modalFontColour }}>
            {moment(props.data.date).format(
              props.settings?.statusChipModalDateLocalisation || "Do MMM YY"
            )}
          </Typography>
          <Box style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            {allEvents.length > 0 ? (
              allEvents.map((x) => (
                <StatusEventCard
                  key={x.id + x.created.toString() + x.status}
                  event={x}
                  onClick={
                    undefined /* In the future, it would be nice to implement this so that users can click on a card to see more information #66*/
                  }
                  settings={props.settings}
                />
              ))
            ) : (
              <StatusEventCard
                event={
                  {
                    status: props.data.status,
                    title: EventStatus[props.data.status],
                  } as unknown as EventViewModel
                }
                settings={props.settings}
              />
            )}
          </Box>
        </>
      }
    >
      <SingleStatusIcon
        style={{
          backgroundColor:
            props.data.status === EventStatus.Online
              ? props.settings.onlineColour
              : props.data.status === EventStatus.Maintenance
              ? props.settings.maintenanceColour
              : props.settings.downColour,
        }}
      />
    </StyledTooltip>
  );
};

export default StatusChip;
