import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Footer from "src/components/Footer/Footer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useContext, useState } from "react";
import PasswordChecklist from "react-password-checklist";
import { IsEmailValid, IsOrgNameValid } from "./SignUpHelper";
import { UserDispatchContext } from "src/contexts/UserStorage";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  AuthenticationClient,
  Role,
  UserClient,
  UserViewModel,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";

export default function SignUp() {
  const setUserContext = useContext(UserDispatchContext);
  const [organisationName, setOrganisationName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [organisationNameValid, setOrganisationNameValid] = useState(true);
  const [userNameValid, setUserNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const setSnackbar = useContext(SetSnackbar);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Check for validation errors
    const orgValid = IsOrgNameValid(organisationName);
    const uNameValid = IsOrgNameValid(userName);
    const emailVal = IsEmailValid(email);

    if (!(orgValid && uNameValid && emailVal && passwordValid)) {
      setOrganisationNameValid(orgValid);
      setUserNameValid(uNameValid);
      setEmailValid(emailVal);
      return;
    }

    // Sign up
    new UserClient(Configuration.ServerRoot, FetchOverride)
      .signupUser(
        {
          role: Role.OrganisationAdmin,
          name: userName,
          email: email,
          password: password,
        } as UserViewModel,
        organisationName
      )
      .then((x) => {
        if (x) {
          x.password = password;
          new AuthenticationClient(Configuration.ServerRoot, FetchOverride)
            .login(x, false)
            .then((y) => {
              // Login the user and migrate to the email confirmation form
              setUserContext(y);
              location.replace("/signup/confirmemail");
            })
            .catch(() =>
              setSnackbar({
                message: "Please check your email for your signup email",
                status: "warning",
              })
            );
        } else {
          setSnackbar({
            message:
              "Could not create user. Please check your details and try again",
            status: "error",
          });
        }
      })
      .catch(() => {
        setSnackbar({
          message:
            "Could not create user. Please check your details and make sure that your email address isn't already signed up",
          status: "error",
        });
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="orgName"
            label="Organisation Name"
            name="orgName"
            autoFocus
            value={organisationName}
            onChange={(e) => {
              setOrganisationName(e.target.value);
            }}
            error={!organisationNameValid}
            helperText={
              !organisationNameValid && "Please enter a valid organisation name"
            }
            onBlur={() =>
              setOrganisationNameValid(IsOrgNameValid(organisationName))
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="userName"
            label="Your Name"
            name="userName"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            error={!userNameValid}
            helperText={!userNameValid && "Please enter a valid name"}
            onBlur={() => setUserNameValid(IsOrgNameValid(userName))}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!emailValid}
            helperText={!emailValid && "Please enter a valid email address"}
            onBlur={() => setEmailValid(IsEmailValid(email))}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmpassword"
            label="Confirm Password"
            type="password"
            id="confirmpassword"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={8}
            value={password}
            valueAgain={confirmPassword}
            onChange={setPasswordValid}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ color: "white" }}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item>
              <Link href="login" variant="body2">
                Return to Login
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
}
