import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { ValidateAllResults } from "./EventModalHelper";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  EventClient,
  EventStatus,
  EventViewModel,
  StatusPageSiteViewModel,
  StatusPageClient,
  TestViewModel,
  TestClient,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import EventModalForm from "./EventModalForm";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  site: StatusPageSiteViewModel;
  eventToEdit?: EventViewModel;
}

const EventModal = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);
  const [eventTitle, setEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventType, setEventType] = useState(EventStatus.Investigating);
  const [testsImpacted, setTestsImpacted] = useState<string[]>([]);
  const [testsOverridden, setTestsOverridden] = useState<TestViewModel[]>([]);
  const [disableOverrideOptions, setDisableOverrideOptions] = useState(false);

  const [eventTitleError, setEventTitleError] = useState(false);
  const [eventDescriptionError, setEventDescriptionError] = useState(false);
  const [testsImpactedError, setTestsImpactedError] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.eventToEdit) {
      setEventTitle(props.eventToEdit.title);
      setEventDescription(props.eventToEdit.description);
      setEventType(props.eventToEdit.status);
      setTestsImpacted(props.eventToEdit.testIds);
      setDisableOverrideOptions(true);
    }
  }, [props.open, props.eventToEdit]);

  const CloseModal = (cancelled: boolean) => {
    props.onClose(cancelled);

    // Reset the state after the modal has closed
    setTimeout(() => {
      console.log("clear");
      setEventTitle("");
      setEventDescription("");
      setEventType(EventStatus.Investigating);
      setTestsImpacted([]);
      setEventTitleError(false);
      setEventDescriptionError(false);
      setTestsImpactedError(false);
      setTestsOverridden([]);
      setDisableOverrideOptions(false);
      setLoading(false);
    }, 1000);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => CloseModal(true)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{props.eventToEdit ? "Edit" : "Create"} Event</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the type of event that you would like to{" "}
          {props.eventToEdit ? "edit" : "create"} along with the event details
        </DialogContentText>
        <EventModalForm
          siteId={props.site.id}
          focusName
          eventTitle={eventTitle}
          setEventTitle={setEventTitle}
          eventDescription={eventDescription}
          setEventDescription={setEventDescription}
          eventType={eventType}
          setEventType={setEventType}
          testsImpacted={testsImpacted}
          setTestsImpacted={setTestsImpacted}
          disableOverriddenOptions={disableOverrideOptions}
          testsOverridden={testsOverridden}
          setTestsOverridden={setTestsOverridden}
          eventTitleError={eventTitleError}
          setEventTitleError={setEventTitleError}
          eventDescriptionError={eventDescriptionError}
          setEventDescriptionError={setEventDescriptionError}
          testsImpactedError={testsImpactedError}
          setTestsImpactedError={setTestsImpactedError}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => CloseModal(true)}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            if (
              ValidateAllResults(
                eventTitle,
                eventDescription,
                testsImpacted,
                setEventTitleError,
                setEventDescriptionError,
                setTestsImpactedError
              )
            ) {
              setLoading(true);

              if (props.eventToEdit) {
                new EventClient(Configuration.ServerRoot, FetchOverride)
                  .updateEvent({
                    id: props.eventToEdit.id,
                    statusPageSiteId: props.site.id,
                    statusPageOptionsId: props.eventToEdit.statusPageOptionsId,
                    title: eventTitle,
                    status: eventType,
                    description: eventDescription,
                    testIds: testsImpacted,
                  } as EventViewModel)
                  .then((x) => {
                    if (x) {
                      setSnackbar({
                        message: "Event updated for site",
                        status: "success",
                      });

                      CloseModal(false);
                      return;
                    }
                    setSnackbar({
                      message:
                        "Event could not be updated. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                    setLoading(false);
                  })
                  .catch(() => {
                    setSnackbar({
                      message:
                        "Event could not be updated. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                    setLoading(false);
                  });
              } else {
                // Get the status page id
                new StatusPageClient(Configuration.ServerRoot, FetchOverride)
                  .getStatusPageForSiteId(props.site.id)
                  .then((statusPage) => {
                    new EventClient(Configuration.ServerRoot, FetchOverride)
                      .createEvent({
                        statusPageSiteId: props.site.id,
                        statusPageOptionsId: statusPage.id,
                        title: eventTitle,
                        description: eventDescription,
                        status: eventType,
                        testIds: testsImpacted,
                      } as EventViewModel)
                      .then((x) => {
                        if (x) {
                          // Update the monitor overrides
                          new TestClient(
                            Configuration.ServerRoot,
                            FetchOverride
                          )
                            .bulkSetTestOverrideStatus(testsOverridden)
                            .then(() =>
                              setSnackbar({
                                message: "Event created for site",
                                status: "success",
                              })
                            )
                            .catch(() =>
                              setSnackbar({
                                message:
                                  "Could not update the status of the uptime monitors. Please do this manually from the uptime monitors tab",
                                status: "error",
                              })
                            )
                            .finally(() => CloseModal(false));
                          return;
                        }
                        setSnackbar({
                          message:
                            "Event could not be created. Please ensure that your values are correct and try again",
                          status: "error",
                        });
                        setLoading(false);
                      })
                      .catch(() => {
                        setSnackbar({
                          message:
                            "Event could not be created. Please ensure that your values are correct and try again",
                          status: "error",
                        });
                        setLoading(false);
                      });
                  })
                  .catch(() => {
                    setSnackbar({
                      message:
                        "Event could not be created. Please refresh the page and try again",
                      status: "error",
                    });
                    setLoading(false);
                  });
              }
            }
          }}
        >
          {props.eventToEdit ? "Edit" : "Create"} Event
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EventModal;
