import React, { useContext, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import BrightnessLowTwoToneIcon from "@mui/icons-material/BrightnessLowTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import {
  OrganisationContext,
  UserContext,
  UserDispatchContext,
} from "src/contexts/UserStorage";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  AuthenticationClient,
  Role,
  UserViewModel,
} from "src/types/auto/types";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const userContext = useContext(UserContext);
  const organisationContext = useContext(OrganisationContext);
  const setUserContext = useContext(UserDispatchContext);
  const setSnackbar = useContext(SetSnackbar);

  // eslint-disable-next-line
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <AccountCircleRoundedIcon style={{ fontSize: 45 }} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{userContext.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {organisationContext.name}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <UserBoxText>
            <UserBoxLabel variant="body1">{userContext.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {organisationContext.name}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        {userContext.role === Role.OrganisationAdmin && (
          <>
            <List sx={{ p: 1 }} component="nav">
              <ListItem
                button
                to="/settings/accounts"
                component={NavLink}
                onClick={handleClose}
              >
                <AccountCircleTwoToneIcon fontSize="small" />
                <ListItemText primary="Accounts" />
              </ListItem>
              <ListItem
                button
                to="/settings/organisationSettings"
                component={NavLink}
                onClick={handleClose}
              >
                <BrightnessLowTwoToneIcon fontSize="small" />
                <ListItemText primary="Organisation Settings" />
              </ListItem>
            </List>
            <Divider />
          </>
        )}
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              new AuthenticationClient(Configuration.ServerRoot, FetchOverride)
                .logout()
                .then(() =>
                  setUserContext({ email: undefined } as UserViewModel)
                )
                .catch(() =>
                  setSnackbar({ message: "Could not log out", status: "error" })
                );
            }}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Log out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
