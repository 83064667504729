import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import {
  StatusPageClient,
  StatusPageSubscriberViewModel,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import LoadingSpinner from "src/components/LoadingSpinner/LoadingSpinner";
import { useSearchParams } from "react-router-dom";

export default function Unsubscribe() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const email = searchParams.get("email");
    const statusPageId = searchParams.get("id");

    if (email) {
      new StatusPageClient(Configuration.ServerRoot, FetchOverride)
        .removeStatusPageSubscriber({
          statusPageOptionsId: statusPageId,
          emailAddress: email,
        } as StatusPageSubscriberViewModel)
        .then((x) => {
          setLoading(false);
          if (!x) {
            setError(true);
          }
        })
        .catch(() => setError(true));
    } else {
      location.href = "/";
    }
  }, []);

  return (
    <Container
      style={{ width: "100%", textAlign: "center", marginTop: "45vh" }}
    >
      {loading && <LoadingSpinner loading />}
      {error && (
        <Typography>
          Sorry, we could not unsubscribe you from this status page. Please try
          again or contact support
        </Typography>
      )}
      {!loading && !error && (
        <Typography>
          You have successfully been subscribed from these status page
          notifications. You may receive emails for the next 24 hours
        </Typography>
      )}
    </Container>
  );
}
