export const refunds = `
# A fair refund policy.
Bad refund policies are infuriating. You feel like the company is just trying to rip you off. We never want our customers to feel that way, so our refund policy is simple: If you’re ever unhappy with our products* for any reason, just contact [support@statushive.net](mailto:support@statushive.net) and we'll take care of you.

## Examples of full refunds we’d grant.
* If you were just charged for your next month of service but you meant to cancel, we’re happy to refund that extra charge.
* If you tried one of our products for less than a month and you weren’t happy with it, you can have your money back.

## Examples of partial refunds or credits we’d grant.
* If you forgot to cancel your account a year ago, we’ll review your account usage and figure out a partial refund based on how many months you used it.
* If we had extended downtime (multiple hours in a day, or multiple days in a month) or you emailed customer service and it took multiple days to get back to you, we’d issue a partial credit to your account.

## Get in touch
At the end of the day, nearly everything on the edges comes down to a case-by-case basis. [Send us a note](mailto:support@statushive.net), tell us what's up, and we'll work with you to make sure you’re happy.

Adapted from the [Basecamp open-source policies / CC BY 4.0](https://github.com/basecamp/policies)`;
