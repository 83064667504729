import React from "react";
import {
  Card,
  Box,
  Typography,
  useTheme,
  CardHeader,
  Chip,
} from "@mui/material";
import moment from "moment";
import {
  EventStatus,
  EventViewModel,
  StatusPageOptionsViewModel,
} from "src/types/auto/types";

interface Props {
  event: EventViewModel;
  onClick?: () => void;
  settings?: StatusPageOptionsViewModel;
}

const StatusEventCard = (props: Props) => {
  const theme = useTheme();

  return (
    <Card
      style={{
        cursor: props.onClick ? "pointer" : "default",
        userSelect: props.onClick ? "none" : undefined,
        backgroundColor: props.settings?.eventCardBackgroundColour,
      }}
      onClick={props.onClick}
    >
      <CardHeader
        title={
          <Box
            style={{
              display: "grid",
              columnGap: 10,
              rowGap: 0,
              minHeight: 10,
              gridTemplateColumns: "min-content auto",
              gridTemplateAreas: `
          'chip title'
          'chip created'
         `,
            }}
          >
            <Chip
              label={EventStatus[props.event.status]}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                gridArea: "chip",
                fontSize: 10,
                padding: 3,
                color: props.settings?.siteChipFontColour,
                backgroundColor: props.settings?.siteChipBackgroundColour,
              }}
            />
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                paddingBottom: 0,
                gridArea: "title",
                fontSize: 17,
                color: props.settings?.eventCardFontColour,
              }}
              variant="h3"
            >
              {props.event.title}
            </Typography>
            {props.event.created && (
              <Typography
                sx={{
                  pb: 3,
                }}
                style={{
                  color:
                    props.settings?.pageBodySecondaryFontColour ||
                    theme.palette.text.secondary,
                  marginLeft: 2,
                  gridArea: "created",
                  fontSize: 10,
                  paddingBottom: 0,
                }}
                variant="h6"
                fontSize={13}
              >
                at {moment(props.event.created).format("HH:mm DD/MM/YYYY")}
              </Typography>
            )}
          </Box>
        }
        disableTypography
      />
    </Card>
  );
};

export default StatusEventCard;
