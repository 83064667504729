import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { ValidateAllPingTestFields } from "./PingTestModalHelper";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import {
  RequestType,
  SiteStatus,
  StatusPageSiteViewModel,
  TestClient,
  TestType,
  TestViewModel,
} from "src/types/auto/types";
import { Configuration } from "src/Constants";
import { FetchOverride } from "src/contexts/Requests";
import PingTestModalForm from "./PingTestModalForm";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  site: StatusPageSiteViewModel;
  testToEdit?: TestViewModel;
}

const PingTestModal = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);
  const [testName, setTestName] = useState("");
  const [showOnPublicStatusPage, setShowOnPublicStatusPage] = useState(true);
  const [testType, setTestType] = useState(TestType.Manual);
  const [requestType, setRequestType] = useState(RequestType.Get);
  const [overrideValue, setOverrideValue] = useState<SiteStatus | undefined>(
    SiteStatus.Online
  );
  const [pauseTest, setPauseTest] = useState(false);
  const [requestHeader, setRequestHeader] = useState("");
  const [requestBody, setRequestBody] = useState("");
  const [url, setUrl] = useState("");
  const [regex, setRegex] = useState("");

  const [testNameError, setTestNameError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [regexError, setRegexError] = useState(false);

  const [resetStatusTests, setResetStatusTests] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.testToEdit) {
      setTestName(props.testToEdit.name);
      setShowOnPublicStatusPage(props.testToEdit.showOnPublicStatusPage);
      setTestType(props.testToEdit.testType);
      setRequestType(props.testToEdit.requestType);
      setRequestHeader(props.testToEdit.requestHeaders);
      setRequestBody(props.testToEdit.requestBody);
      setUrl(props.testToEdit.url);
      setRegex(props.testToEdit.regex);
      setOverrideValue(props.testToEdit.overriddenStatus);
      setPauseTest(props.testToEdit.isInMaintenanceMode);
    }
  }, [props.open, props.testToEdit]);

  const CloseModal = (cancelled: boolean) => {
    props.onClose(cancelled);

    // Reset the state after the modal has closed
    setTimeout(() => {
      setTestName("");
      setShowOnPublicStatusPage(true);
      setTestType(TestType.Manual);
      setRequestType(RequestType.Get);
      setRequestHeader("");
      setRequestBody("");
      setUrl("");
      setRegex("");
      setTestNameError(false);
      setUrlError(false);
      setRegexError(false);
      setResetStatusTests((x) => !x);
      setLoading(false);
      setOverrideValue(SiteStatus.Online);
      setPauseTest(false);
    }, 1000);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => CloseModal(true)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {props.testToEdit ? "Edit" : "Create"} Uptime Monitor
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the type of test that you would like to run along with the url
          and other information and schedule your test.
        </DialogContentText>
        <PingTestModalForm
          focusName
          testName={testName}
          setTestName={setTestName}
          showOnPublicStatusPage={showOnPublicStatusPage}
          setShowOnPublicStatusPage={setShowOnPublicStatusPage}
          testType={testType}
          setTestType={setTestType}
          overrideValue={overrideValue}
          setOverrideValue={setOverrideValue}
          pauseTest={pauseTest}
          setPauseTest={setPauseTest}
          url={url}
          setUrl={setUrl}
          regex={regex}
          setRegex={setRegex}
          testNameError={testNameError}
          setTestNameError={setTestNameError}
          urlError={urlError}
          setUrlError={setUrlError}
          regexError={regexError}
          setRegexError={setRegexError}
          resetTests={resetStatusTests}
          requestType={requestType}
          setRequestType={setRequestType}
          requestHeader={requestHeader}
          setRequestHeader={setRequestHeader}
          requestBody={requestBody}
          setRequestBody={setRequestBody}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => CloseModal(true)}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            if (
              ValidateAllPingTestFields(
                testName,
                testType,
                url,
                regex,
                setTestNameError,
                setUrlError,
                setRegexError
              )
            ) {
              setLoading(true);

              if (props.testToEdit) {
                new TestClient(Configuration.ServerRoot, FetchOverride)
                  .updateTest({
                    id: props.testToEdit.id,
                    statusPageSiteId: props.site.id,
                    name: testName,
                    url: url,
                    testType: testType,
                    requestType: requestType,
                    requestHeaders: requestHeader,
                    requestBody: requestBody,
                    regex: regex,
                    isInMaintenanceMode: pauseTest,
                    overriddenStatus: overrideValue,
                    showOnPublicStatusPage: showOnPublicStatusPage,
                  } as TestViewModel)
                  .then((x) => {
                    if (x) {
                      setSnackbar({
                        message: "Test updated for site",
                        status: "success",
                      });
                      CloseModal(false);
                      return;
                    }
                    setSnackbar({
                      message:
                        "Test could not be updated. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                    setLoading(false);
                  })
                  .catch(() => {
                    setSnackbar({
                      message:
                        "Test could not be updated. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                    setLoading(false);
                  });
              } else {
                new TestClient(Configuration.ServerRoot, FetchOverride)
                  .createTest({
                    statusPageSiteId: props.site.id,
                    name: testName,
                    url: url,
                    testType: testType,
                    requestType: requestType,
                    requestHeaders: requestHeader,
                    requestBody: requestBody,
                    regex: regex,
                    isInMaintenanceMode: pauseTest,
                    overriddenStatus: overrideValue,
                    showOnPublicStatusPage: showOnPublicStatusPage,
                  } as TestViewModel)
                  .then((x) => {
                    if (x) {
                      setSnackbar({
                        message: "Test created for site",
                        status: "success",
                      });
                      CloseModal(false);
                      return;
                    }
                    setSnackbar({
                      message:
                        "Test could not be created. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                    setLoading(false);
                  })
                  .catch(() => {
                    setSnackbar({
                      message:
                        "Test could not be created. Please ensure that your values are correct and try again",
                      status: "error",
                    });
                    setLoading(false);
                  });
              }
            }
          }}
        >
          {props.testToEdit ? "Edit" : "Create"} Uptime Monitor
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PingTestModal;
