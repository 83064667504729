import React, { useContext } from "react";
import { TestClient, TestViewModel } from "src/types/auto/types";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";
import { SetSnackbar } from "src/contexts/SnackbarContext";

interface Props {
  open: boolean;
  onClose: (cancelled: boolean) => void;
  name: string;
  description: string;
  test: TestViewModel;
}

const PauseResumePingTestModal = (props: Props): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);

  return (
    <ConfirmModal
      open={props.open}
      onClose={async (cancelled: boolean): Promise<undefined> => {
        if (cancelled) {
          props.onClose(true);
          return;
        }
        props.onClose(false);
        return await new TestClient(Configuration.ServerRoot, FetchOverride)
          .pauseResumeTest(props.test.id, !props.test?.isInMaintenanceMode)
          .then((x) => {
            if (x) {
              // The test was deleted
              setSnackbar({
                message:
                  "The test was " +
                  (props.test?.isInMaintenanceMode ? "resumed" : "paused"),
                status: "success",
              });
            } else {
              setSnackbar({
                message:
                  "The test could not be " +
                  (props.test?.isInMaintenanceMode ? "resumed" : "paused") +
                  ". Please try again",
                status: "error",
              });
            }

            return undefined;
          })
          .catch(() => {
            setSnackbar({
              message:
                "The test could not be " +
                (props.test?.isInMaintenanceMode ? "resumed" : "paused") +
                ". Please try again",
              status: "error",
            });
          });
      }}
      actionName={props.test?.isInMaintenanceMode ? "Resume" : "Pause"}
      name={props.name}
      description={props.description}
      buttonColor="primary"
    />
  );
};

export default PauseResumePingTestModal;
