import React from "react";
import {
  Container,
  Pagination,
  PaginationItem,
  Tooltip,
  Typography,
} from "@mui/material";

interface Props {
  pageNumber: number;
  isFullAccountSetup: boolean;
}

const maxPageCount = 4;
const setupPageName = {
  1: "Confirm Email",
  2: "Choose Subscription",
  3: "Setup Status Page",
  4: "Create Uptime Monitors",
};

const partialCreationMaxPageCount = 2;
const partialCreationPageNames = {
  1: "Setup Status Page",
  2: "Create Uptime Monitors",
};

const AccountSetupPagination = (props: Props): JSX.Element => (
  <Container
    style={{
      marginTop: "50px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      flexWrap: "wrap",
    }}
  >
    <Typography style={{ width: "100%", textAlign: "center" }}>
      {props.isFullAccountSetup
        ? "Account Setup Progress"
        : "Status Page Setup Progress"}
    </Typography>
    <Pagination
      count={
        props.isFullAccountSetup ? maxPageCount : partialCreationMaxPageCount
      }
      page={props.isFullAccountSetup ? props.pageNumber : props.pageNumber - 2}
      color="primary"
      hidePrevButton
      hideNextButton
      onAnimationStart={(e) => e.preventDefault()}
      renderItem={(item) => (
        <Tooltip
          title={
            props.isFullAccountSetup
              ? setupPageName[item.page]
              : partialCreationPageNames[item.page]
          }
        >
          <PaginationItem {...item} style={{ cursor: "default" }} />
        </Tooltip>
      )}
    />
  </Container>
);

export default AccountSetupPagination;
