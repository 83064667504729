import React, { useContext, useState } from "react";
import { Box, Card, CardHeader, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Configuration } from "src/Constants";
import { ContactClient } from "src/types/auto/types";
import { OrganisationContext, UserContext } from "src/contexts/UserStorage";
import { LoadingButton } from "@mui/lab";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";

const Contact = (): JSX.Element => {
  const setSnackbar = useContext(SetSnackbar);
  const organisation = useContext(OrganisationContext);
  const user = useContext(UserContext);
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <>
      <Helmet>
        <title>Contact | {Configuration.name}</title>
      </Helmet>
      <Card style={{ width: "90%", marginLeft: "5%", marginTop: 20 }}>
        <CardHeader
          title={
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                marginBottom: 15,
                paddingBottom: 0,
              }}
              variant="h3"
            >
              Contact
            </Typography>
          }
          subheader={
            <Typography>
              {success
                ? "Your feedback or support request has been received. One of our team will get back to you within 1 working day"
                : "Please enter a support question or some feedback and one of our team will get back to you within 1 working day"}
            </Typography>
          }
        />
        <Box style={{ marginLeft: 20, marginRight: 20 }}>
          {!success && (
            <>
              <Typography style={{ marginBottom: 3 }}>
                <b>My Name: </b>
                {user.name}
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                <b>Organisation Name: </b>
                {organisation.name}
              </Typography>
              <TextField
                autoFocus
                multiline
                minRows={5}
                maxRows={15}
                margin="dense"
                id="name"
                label="Description"
                fullWidth
                variant="outlined"
                placeholder="My Support Request / Feedback"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                onBlur={() => setContentError(content === "")}
                error={contentError}
                helperText={
                  contentError &&
                  "Please enter a support request or some feedback"
                }
              />
              <LoadingButton
                style={{ marginTop: 10, marginBottom: 20 }}
                loading={loading}
                onClick={() => {
                  if (contentError) {
                    return;
                  }
                  setLoading(true);
                  new ContactClient(Configuration.ServerRoot, FetchOverride)
                    .postContactData(content)
                    .then((x) => {
                      if (x) {
                        setSuccess(true);
                      } else {
                        setSnackbar({
                          message:
                            "Could not submit feedback. Please try again",
                          status: "error",
                        });
                      }
                    })
                    .catch(() => {
                      setSnackbar({
                        message: "Could not submit feedback. Please try again",
                        status: "error",
                      });
                    })
                    .finally(() => setLoading(false));
                }}
              >
                Send
              </LoadingButton>
            </>
          )}
        </Box>
      </Card>
    </>
  );
};

export default Contact;
