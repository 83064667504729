import { Box, Container, Tooltip, Typography } from "@mui/material";
import React, { Ref, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { SiteStatus, TestViewModel } from "src/types/auto/types";
import { IsResultUp } from "../UptimeHelpers/IsUp";
import StatusIcon from "../UptimeHelpers/StatusIcon";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ReorderIcon from "@mui/icons-material/Reorder";

interface Props {
  test: TestViewModel;
  index: number;
  moveListItem: (dragIndex: number, hoverIndex: number) => void;
}

const DraggableItem = (props: Props) => {
  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index: props.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: "item",
    hover: (item: { index: number }, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = props.index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      props.moveListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  // Make items being dragged transparent, so it's easier to see where we drop them
  const opacity = isDragging ? 0 : 1;
  return (
    <Container
      ref={dragDropRef as unknown as Ref<HTMLDivElement>}
      style={{
        opacity,
        cursor: isDragging ? "grabbing" : "grab",
        display: "flex",
        verticalAlign: "center",
      }}
    >
      <ReorderIcon style={{ paddingTop: "4px" }} color="disabled" />
      <Box
        style={{
          display: "inline-block",
          marginTop: "5px",
          marginLeft: "20px",
        }}
      >
        <StatusIcon
          status={
            props.test.overriddenStatus !== undefined &&
            props.test.overriddenStatus !== null
              ? props.test.overriddenStatus
              : props.test.isInMaintenanceMode
              ? SiteStatus.TestsPaused
              : IsResultUp(props.test.latestStatusCode)
              ? SiteStatus.Online
              : SiteStatus.Offline
          }
          pixelSize={8}
        />
      </Box>
      <Typography
        sx={{
          pb: 3,
        }}
        style={{
          marginLeft: 10,
          display: "inline-block",
          marginBottom: 15,
          paddingBottom: 0,
          fontSize: "17px",
        }}
      >
        {props.test.name}
      </Typography>
      <Tooltip
        title={
          props.test.showOnPublicStatusPage
            ? "Test Visible On Status Page"
            : "Test Hidden On Status Page"
        }
      >
        {props.test.showOnPublicStatusPage ? (
          <RemoveRedEyeOutlinedIcon
            color="disabled"
            style={{ marginLeft: "10px", fontSize: "20px", paddingTop: "5px" }}
          />
        ) : (
          <VisibilityOffOutlinedIcon
            color="disabled"
            style={{ marginLeft: "10px", fontSize: "20px", paddingTop: "5px" }}
          />
        )}
      </Tooltip>
    </Container>
  );
};

export default DraggableItem;
