import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

interface Props {
  requestBody: string;
  setRequestBody: React.Dispatch<React.SetStateAction<string>>;
}

const BodyAccordion = (props: Props): JSX.Element => {
  return (
    <Accordion
      style={{ marginTop: "10px", backgroundColor: "#f0f0f0" }}
      disableGutters
    >
      <AccordionSummary expandIcon={<ExpandMore />} id="requestHeadersSummary">
        Request Body
      </AccordionSummary>
      <AccordionDetails style={{ paddingTop: 0 }}>
        <TextField
          value={props.requestBody}
          onChange={(e) => props.setRequestBody(e.target.value)}
          fullWidth
          multiline
          rows="4"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default BodyAccordion;
