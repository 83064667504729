import React, { useContext, useState } from "react";
import {
  Card,
  Box,
  Typography,
  IconButton,
  CardHeader,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { MoreVert, Create, Delete } from "@mui/icons-material";
import { Role, UserViewModel } from "src/types/auto/types";
import UserModal from "src/components/UserModal/UserModal";
import DeleteUserModal from "src/components/UserModal/DeleteUserModal";
import { UserContext } from "src/contexts/UserStorage";

interface Props {
  user: UserViewModel;
  onChange: () => void;
}

const UserStatusCard = (props: Props) => {
  const myUser = useContext(UserContext);
  const [editUser, setEditUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [anchorActionList, setAnchorActionList] = useState<null | HTMLElement>(
    null
  );

  return (
    <>
      <Card style={{ minHeight: 150, height: "100%" }}>
        <CardHeader
          sx={{
            "& .MuiCardHeader-content": {
              maxWidth: "95%",
              wordWrap: "break-word",
            },
          }}
          action={
            <>
              <IconButton
                aria-label="options"
                onClick={(e) => setAnchorActionList(e.currentTarget)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                open={anchorActionList !== null}
                anchorEl={anchorActionList}
                onClose={() => setAnchorActionList(null)}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorActionList(null);
                    setEditUser(true);
                  }}
                >
                  <ListItemIcon>
                    <Create fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit User</ListItemText>
                </MenuItem>
                <Tooltip
                  title={
                    myUser.id === props.user.id
                      ? "You Cannot Delete Your Own User"
                      : ""
                  }
                >
                  <div>
                    <MenuItem
                      disabled={myUser.id === props.user.id}
                      style={{ color: "red" }}
                      onClick={() => {
                        if (myUser.id !== props.user.id) {
                          setAnchorActionList(null);
                          setDeleteUser(true);
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Delete fontSize="small" color="error" />
                      </ListItemIcon>
                      <ListItemText>Delete User</ListItemText>
                    </MenuItem>
                  </div>
                </Tooltip>
              </Menu>
            </>
          }
          title={
            <Box p={4}>
              <Typography
                sx={{
                  pb: 3,
                }}
                style={{}}
                variant="h2"
              >
                {props.user.name}
              </Typography>
              <Typography
                sx={{
                  pb: 3,
                }}
                style={{}}
                variant="h5"
              >
                {Role[props.user.role]
                  .toString()
                  .replace(/[A-Z]/g, " $&")
                  .trim()}
              </Typography>
            </Box>
          }
        />
      </Card>
      {editUser && (
        <UserModal
          open={editUser}
          onClose={(cancelled) => {
            if (!cancelled) {
              props.onChange();
            }

            setEditUser(false);
          }}
          userToEdit={props.user}
        />
      )}
      {deleteUser && (
        <DeleteUserModal
          open={deleteUser}
          onClose={(cancelled) => {
            if (!cancelled) {
              props.onChange();
            }

            setDeleteUser(false);
          }}
          name={props.user.name}
          description={
            "Are you sure that you want to delete the user " +
            props.user.name +
            "? This action cannot be undone"
          }
          user={props.user}
        />
      )}
    </>
  );
};

export default UserStatusCard;
