import { AlertColor } from "@mui/material";
import React, { createContext, useState } from "react";
import Snackbar from "src/components/Snackbar/Snackbar";

interface Props {
  children?: JSX.Element;
}

export interface SnackbarContent {
  message: string;
  status: AlertColor;
}

const SnackbarContext = ({ children }: Props): React.ReactElement => {
  const [content, setContent] = useState<SnackbarContent>();

  return (
    <SetSnackbar.Provider value={setContent}>
      <>
        <Snackbar content={content} />
        {children}
      </>
    </SetSnackbar.Provider>
  );
};

/** Sets the snackbar */
export const SetSnackbar = createContext(
  (() => 0) as React.Dispatch<SnackbarContent>
);

export default SnackbarContext;
