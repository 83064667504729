import React, { useContext, useState } from "react";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import Footer from "src/components/Footer/Footer";
import SubscriptionOption, {
  SubscriptionOptions,
} from "src/components/SubscriptionOption/SubscriptionOption";
import Logo from "src/components/LogoSign";
import AccountSetupPagination from "src/components/AccountSetupPagination/AccountSetupPagination";
import { OrganisationContext } from "src/contexts/UserStorage";
import { PaymentStatus, Subscription } from "src/types/auto/types";
import { Configuration } from "src/Constants";

const Prices = [
  {
    name: "Free",
    monthPriceInPence: 0,
    yearPriceInPence: 0,
    features: ["Status Page", "3 Status Checks", "Unlimited Team Members"],
  },
  {
    name: "Advanced",
    monthId: Configuration.VERSION
      ? "price_1MPUYKKud8Rb0X85JWtO3riV"
      : "price_1M5UPPKud8Rb0X85rs0Kh6UU",
    monthPriceInPence: 1500,
    yearId: Configuration.VERSION
      ? "price_1MPUYKKud8Rb0X85TMKuz6lq"
      : "price_1M5UPPKud8Rb0X85ceXaKtS9",
    yearPriceInPence: 15000,
    yearSavingString: "Over 16% Saving!",
    features: [
      "Status Pages on Custom Domain",
      "10 Status Checks",
      "Unlimited Team Members",
      "Unlimited Subscriptions",
    ],
  },
  {
    name: "Ultimate",
    monthId: Configuration.VERSION
      ? "price_1MPUYFKud8Rb0X85u9ezgvhD"
      : "price_1M6rQvKud8Rb0X85mPsNE8r6",
    monthPriceInPence: 5000,
    yearId: Configuration.VERSION
      ? "price_1MPUYFKud8Rb0X85c81Dpzrh"
      : "price_1M6rQvKud8Rb0X85AaAwPW3C",
    yearPriceInPence: 50000,
    yearSavingString: "Over 16% Saving!",
    features: [
      "Status Pages on Custom Domains",
      "75 Status Checks",
      "Unlimited Team Members",
      "Unlimited Subscriptions",
    ],
  },
] as SubscriptionOptions[];

const Payment = (): JSX.Element => {
  const [useYearlyPricing, setUseYearlyPricing] = useState(true);
  const organisation = useContext(OrganisationContext);

  if (
    (organisation.subscriptionType === Subscription.Advanced ||
      organisation.subscriptionType === Subscription.Ultimate) &&
    organisation.paymentStatus === PaymentStatus.Successful
  ) {
    //location.href = "/";
  }
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Logo height={150} hideNavigation />
        <Typography
          component="h1"
          variant="h5"
          style={{ marginTop: 20, fontSize: 22 }}
        >
          Please choose your plan
        </Typography>
        <Box>
          <Typography
            component="h1"
            variant="h5"
            style={{ marginTop: 20, fontSize: 18 }}
          >
            {useYearlyPricing ? "Yearly Pricing" : "Monthly Pricing"}
          </Typography>
          <Switch
            defaultChecked
            size="medium"
            value={useYearlyPricing}
            onChange={(_, checked) => setUseYearlyPricing(checked)}
          />
        </Box>
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
            mt="0px"
            style={{ width: "100%" }}
          >
            {Prices.map((x) => (
              <Grid item xs={12} md={3} sm={6} key={"prices " + x.name}>
                <SubscriptionOption {...x} showMonth={!useYearlyPricing} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <AccountSetupPagination pageNumber={2} isFullAccountSetup />
      <Footer />
    </Container>
  );
};

export default Payment;
