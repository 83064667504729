import React from "react";
import { useTheme } from "@mui/material";
import Chart from "react-apexcharts";
import type { ApexOptions } from "apexcharts";
import { IsResultUp } from "src/components/UptimeHelpers/IsUp";
import moment from "moment";
import { TestViewModel } from "src/types/auto/types";
import { Configuration } from "src/Constants";

interface Props {
  data: ApexAxisChartSeries;
}

export const calculateUptimeGraph = (
  test: TestViewModel
): ApexAxisChartSeries => {
  return [
    {
      name: "Status",
      data: test.dayTestResults.map((x) => ({
        x: x.created.getTime(),
        y:
          x.statusCode === Configuration.pausedTestStatusCode
            ? Configuration.pausedTestStatusCode
            : IsResultUp(x.statusCode)
            ? 1
            : 0,
      })),
    },
  ];
};

function UptimeGraph(props: Props) {
  const theme = useTheme();

  const chartOptions: ApexOptions = {
    chart: {
      background: "transparent",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    colors: [
      theme.colors.primary.main,
      theme.colors.warning.main,
      theme.colors.error.main,
      theme.colors.info.main,
      theme.colors.success.main,
      theme.colors.secondary.main,
    ],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: theme.palette.mode,
    },
    stroke: {
      curve: "stepline",
      show: true,
      colors: [
        theme.colors.primary.main,
        theme.colors.warning.main,
        theme.colors.error.main,
        theme.colors.info.main,
        theme.colors.success.main,
        theme.colors.secondary.main,
      ],
      width: 3,
    },
    legend: {
      show: false,
    },
    xaxis: {
      tickPlacement: "on",
      labels: {
        show: false,
        rotate: -70,
        formatter: (val) => moment(new Date(val)).format("HH:mm"),
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 10,
    },
    yaxis: {
      show: false,
      min: 0,
      tickAmount: 1,
      labels: {
        style: {
          colors: "rgba(0, 0, 0, 0)",
        },
        formatter: (val) =>
          val === 0
            ? "Down"
            : val === Configuration.pausedTestStatusCode
            ? "Paused"
            : "Up",
      },
    },
    tooltip: { enabled: true },
  };

  return (
    <Chart
      options={chartOptions}
      series={props.data}
      type="area"
      height={130}
    />
  );
}

export default UptimeGraph;
