import React, { useContext, useState } from "react";
import {
  Card,
  CardHeader,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { Configuration } from "src/Constants";
import { OrganisationClient } from "src/types/auto/types";
import {
  OrganisationContext,
  OrganisationDispatchContext,
} from "src/contexts/UserStorage";
import { LoadingButton } from "@mui/lab";
import { FetchOverride } from "src/contexts/Requests";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import DeleteOrganisationModal from "src/view/settings/DeleteOrganisationModal";

const isOrgNameValid = (name: string): boolean =>
  name.replace(" ", "").length > 0;

const GenericSettings = (): JSX.Element => {
  const organisation = useContext(OrganisationContext);
  const setOrganisation = useContext(OrganisationDispatchContext);
  const setSnackbar = useContext(SetSnackbar);
  const [organisationName, setOrganisationName] = useState(organisation.name);
  const [organisationNameError, setOrganisationNameError] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <Card style={{ width: "90%", marginLeft: "5%", marginTop: 20 }}>
        <CardHeader
          title={
            <Typography
              sx={{
                pb: 3,
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                marginBottom: 15,
                paddingBottom: 0,
              }}
              variant="h3"
            >
              Organisation Settings
            </Typography>
          }
          subheader={
            <Typography style={{ marginLeft: 10 }}>
              Update the settings for your organisation here
            </Typography>
          }
        />

        <Container>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Organisation Name"
            fullWidth
            variant="standard"
            placeholder="My Organisation Name"
            value={organisationName}
            onChange={(e) => setOrganisationName(e.target.value)}
            onBlur={() =>
              setOrganisationNameError(!isOrgNameValid(organisationName))
            }
            error={organisationNameError}
            helperText={
              organisationNameError && "Please enter a valid organisation name"
            }
          />
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              color: "white",
              width: "100%",
            }}
            variant="contained"
            onClick={() => {
              // Ensure that the name is valid
              if (!isOrgNameValid(organisationName)) {
                setOrganisationNameError(true);
                return;
              }

              // Update the organisation on the backend
              const newOrg = organisation;
              newOrg.name = organisationName;
              new OrganisationClient(Configuration.ServerRoot, FetchOverride)
                .updateOrganisation(newOrg)
                .then((x) => {
                  if (x) {
                    setOrganisation(newOrg);
                    setSnackbar({
                      message: "Organisation was updated successfully",
                      status: "success",
                    });
                    window.location.reload();
                  } else {
                    setSnackbar({
                      message:
                        "Organisation could not be updated. Please try again or contact support",
                      status: "error",
                    });
                  }
                })
                .catch(() => {
                  setSnackbar({
                    message:
                      "Organisation could not be updated. Please try again or contact support",
                    status: "error",
                  });
                });
            }}
          >
            Update Organisation
          </LoadingButton>
          <Typography
            sx={{
              pb: 3,
            }}
            style={{
              display: "inline-block",
              marginBottom: 15,
              paddingBottom: 0,
            }}
            variant="h3"
          >
            Delete Organisation
          </Typography>
          <Typography>
            Warning this will delete all of the data relating to your
            organisation. This cannot be undone
          </Typography>
          <LoadingButton
            style={{
              marginTop: 10,
              marginBottom: 20,
              display: "block",
              width: "100%",
            }}
            color="error"
            variant="contained"
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            Delete Organisation
          </LoadingButton>
        </Container>
      </Card>
      <DeleteOrganisationModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default GenericSettings;
