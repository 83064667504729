import React, { createContext, useReducer } from "react";
import { OrganisationViewModel, UserViewModel } from "src/types/auto/types";

import { StorageAccess } from "./CookieStorage";

interface Props {
  children?: JSX.Element;
}

const UserStore = ({ children }: Props): React.ReactElement => {
  const CURRENTUSER_KEY = "currentUser";
  const CURRENTORG_KEY = "currentOrg";

  const getCurrentUser = (): UserViewModel => {
    const store = StorageAccess.userStorage;
    const user: string = store?.fetch(CURRENTUSER_KEY) ?? "";

    if (user.length > 0) {
      return JSON.parse(user) as UserViewModel;
    }

    return {} as UserViewModel;
  };

  const getCurrentOrganisation = (): OrganisationViewModel => {
    const store = StorageAccess.organisationStorage;
    const organisation: string = store?.fetch(CURRENTORG_KEY) ?? "";

    if (organisation.length > 0) {
      return JSON.parse(organisation) as OrganisationViewModel;
    }

    return {} as OrganisationViewModel;
  };

  const userReducer = (
    prevUser: UserViewModel,
    newUser: UserViewModel
  ): UserViewModel => {
    const store = StorageAccess.userStorage;
    store?.set(CURRENTUSER_KEY, JSON.stringify(newUser));

    return newUser;
  };

  const orgReducer = (
    prevOrg: OrganisationViewModel,
    currentOrg: OrganisationViewModel
  ): OrganisationViewModel => {
    const store = StorageAccess.userStorage;
    store?.set(CURRENTORG_KEY, JSON.stringify(currentOrg));

    return currentOrg;
  };

  const [user, dispatchUser] = useReducer(userReducer, getCurrentUser());
  const [organisation, dispatchOrganisation] = useReducer(
    orgReducer,
    getCurrentOrganisation()
  );

  return (
    <UserDispatchContext.Provider value={dispatchUser}>
      <UserContext.Provider value={user}>
        <OrganisationDispatchContext.Provider value={dispatchOrganisation}>
          <OrganisationContext.Provider value={organisation}>
            {children}
          </OrganisationContext.Provider>
        </OrganisationDispatchContext.Provider>
      </UserContext.Provider>
    </UserDispatchContext.Provider>
  );
};

/** Allows the User state to be updated. */
export const UserDispatchContext = createContext(
  (() => 0) as React.Dispatch<UserViewModel>
);

/** Returns the current user state. */
export const UserContext = createContext({} as UserViewModel);

/** Allows the organisation state to be updated. */
export const OrganisationDispatchContext = createContext(
  (() => 0) as React.Dispatch<OrganisationViewModel>
);

/** Returns the current organisation state. */
export const OrganisationContext = createContext({} as OrganisationViewModel);

export default UserStore;
